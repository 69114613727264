import React from 'react';

import { AdditionalLuxDetails } from 'components/lp_doc/diligence/jurisdiction_requirements/luxembourg/details';
import { jurisdictions } from '../../../constants';
import { AdditionalDetails } from '../../../jurisdiction_requirements/usa/details/index';
import { AdditionalCaymanDetails } from '../../../jurisdiction_requirements/cayman/details/index';
import { AdditionalUsaV2Details } from '../../../jurisdiction_requirements/usa_v2/details/index';
import { AdditionalSeiDetails } from '../../../jurisdiction_requirements/sei/details';

export function JurisdictionSpecificDetails({
  isRootNode,
  form,
  formErrors,
  handleChange,
  jurisdiction,
}) {
  if (jurisdiction === jurisdictions.USA) {
    return (
      <AdditionalDetails
        form={form}
        formErrors={formErrors}
        handleChange={handleChange}
      />
    );
  }
  if (jurisdiction === jurisdictions.CAYMAN) {
    return (
      <AdditionalCaymanDetails
        isRootNode={isRootNode}
        form={form}
        formErrors={formErrors}
        handleChange={handleChange}
      />
    );
  }

  if (jurisdiction === jurisdictions.LUXEMBOURG) {
    return (
      <AdditionalLuxDetails
        isRootNode={isRootNode}
        form={form}
        formErrors={formErrors}
        handleChange={handleChange}
      />
    );
  }

  if (jurisdiction === jurisdictions.USA_V2) {
    return (
      <AdditionalUsaV2Details
        form={form}
        formErrors={formErrors}
        handleChange={handleChange}
      />
    );
  }

  if (jurisdiction === jurisdictions.SEI) {
    return (
      <AdditionalSeiDetails
        isRootNode={isRootNode}
        form={form}
        formErrors={formErrors}
        handleChange={handleChange}
      />
    );
  }

  return null;
}
