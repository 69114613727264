import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import SignatureFreehand from 'mdi-material-ui/SignatureFreehand';
import { Button, Typography, SpotIcon, Icons } from '@passthrough/uikit';

import * as urls from 'services/urls';

import { useRegionalAction } from 'services/regional';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import { capitalizeFirstLetter } from 'services/utils';
import { LPStatusText } from 'components/status';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  action: {
    alignSelf: 'center',
  },
  task: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      rowGap: theme.spacing(1),
    },
  },
  content: {
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    '&:only-child': {
      padding: theme.spacing(2),
    },
  },
  extraPadding: {
    padding: theme.spacing(2, 2, 2, 3),
  },
  subtasks: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  subtask: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

const useSplitKYCStyles = makeStyles((theme) => ({
  subtasks: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  subtask: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

const Task = ({
  icon: Icon,
  fundName,
  taskName,
  actionLabel,
  action,
  iconVariant = 'info',
  extraContent = null,
  extraPadding = false,
}) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters>
      <Card className={classes.root} elevation={2}>
        <CardContent
          className={clsx(classes.content, {
            [classes.extraPadding]: extraPadding,
          })}
        >
          <div className={classes.task}>
            <div className={classes.subheader}>
              {Icon ? (
                <SpotIcon size="medium" variant={iconVariant}>
                  <Icon />
                </SpotIcon>
              ) : null}
              <div className={classes.description}>
                <Typography variant="card-heading" size="medium">
                  {taskName}
                </Typography>
                <Typography variant="body" size="small">
                  {fundName}
                </Typography>
              </div>
            </div>
            <div className={classes.action}>
              <Button
                onClick={action}
                variant="primary"
                htmlProps={{ 'data-test': 'task' }}
              >
                {actionLabel}
              </Button>
            </div>
          </div>
        </CardContent>
        {extraContent ? <Divider /> : null}
        {extraContent ? (
          <CardContent className={classes.content}>{extraContent}</CardContent>
        ) : null}
      </Card>
    </ListItem>
  );
};

const START_LABEL_STATUSES = ['UNVIEWED', 'VIEWED'];
const CONTINUE_LABEL_STATUSES = ['IN_PROGRESS', 'SIGNED'];
const REVIEW_LABEL_STATUSES = ['REQUESTED_CHANGES'];

function getLabel(status, wasSentForInvestorSigning) {
  if (START_LABEL_STATUSES.includes(status)) {
    return 'Start';
  }
  if (CONTINUE_LABEL_STATUSES.includes(status)) {
    if (wasSentForInvestorSigning) {
      return 'Sign';
    }
    return 'Continue';
  }
  if (REVIEW_LABEL_STATUSES.includes(status)) {
    return 'Review';
  }
  // due to filter on lpclosing statuses inside of the
  // task list view, this default should never be reached.
  // Nonetheless, we fallback to a value so this button is never empty
  return 'Start';
}

export const KYCOnlyTask = ({ task }) => {
  const { fundName, lpName, status, wasSentForInvestorSigning } = task;
  const url = urls.lpDocUrl({ lpDocumentId: task.id });
  const { baseUrl } = task;
  const action = useRegionalAction();

  return (
    <Task
      icon={Icons.FingerprintOutlined}
      fundName={`Diligence • ${fundName}`}
      taskName={lpName}
      actionLabel={getLabel(status, wasSentForInvestorSigning)}
      action={() => action(baseUrl, url)}
      iconVariant="warning"
    />
  );
};

export const SubDocTask = ({ task }) => {
  const { fundName, lpName, status, wasSentForInvestorSigning } = task;
  const url = urls.lpDocUrl({ lpDocumentId: task.id });
  const { baseUrl } = task;
  const action = useRegionalAction();

  return (
    <Task
      icon={Icons.DescriptionOutlined}
      fundName={`Questionnaire • ${fundName}`}
      taskName={lpName}
      actionLabel={getLabel(status, wasSentForInvestorSigning)}
      action={() => action(baseUrl, url)}
    />
  );
};

function getMultiStatusActionLabel(questionnaireStatus, diligenceStatus) {
  if (
    START_LABEL_STATUSES.includes(questionnaireStatus) &&
    START_LABEL_STATUSES.includes(diligenceStatus)
  ) {
    return 'Start';
  }
  return 'Continue';
}

export const SplitSubDocTask = ({ task }) => {
  const { fundName, lpName, wasSentForInvestorSigning } = task;
  const url = urls.lpDocUrl({ lpDocumentId: task.id });
  const { baseUrl } = task;
  const action = useRegionalAction();
  const classes = useSplitKYCStyles();

  return (
    <Task
      fundName={fundName}
      taskName={lpName}
      actionLabel={getMultiStatusActionLabel(
        task.questionnaireStatus,
        task.diligenceStatus,
        wasSentForInvestorSigning,
      )}
      action={() => action(baseUrl, url)}
      extraPadding
      extraContent={
        <div className={classes.subtasks}>
          <div className={classes.subtask}>
            <div className={classes.subheader}>
              <SpotIcon size="small" variant="info">
                <Icons.DescriptionOutlined fontSize="inherit" />
              </SpotIcon>
              <Typography variant="body">
                {capitalizeFirstLetter(task.documentDisplayType)}
              </Typography>
            </div>
            <Typography variant="body" size="small" color="text.secondary">
              <LPStatusText status={task.questionnaireStatus} task={task} />
            </Typography>
          </div>
          <div className={classes.subtask}>
            <div className={classes.subheader}>
              <SpotIcon size="small" variant="warning">
                <Icons.FingerprintOutlined fontSize="inherit" />
              </SpotIcon>
              <Typography variant="body">Diligence</Typography>
            </div>
            <Typography variant="body" size="small" color="text.secondary">
              <LPStatusText status={task.diligenceStatus} task={task} />
            </Typography>
          </div>
        </div>
      }
    />
  );
};

export const SecondSignerTask = ({ task }) => {
  const { fundName, lpClosingId, lpName } = task;
  const url = urls.secondSignerUrl({ lpClosingId });
  const { baseUrl } = task;
  const action = useRegionalAction();
  return (
    <Task
      icon={SignatureFreehand}
      fundName={fundName}
      taskName={lpName}
      actionLabel="Sign"
      action={() => action(baseUrl, url)}
    />
  );
};

export const CountersignTask = ({ task }) => {
  const { fundName, closingName, fundId, closingId } = task;
  const url = urls.countersignUrl({ fundId, closingId });
  const { baseUrl } = task;
  const action = useRegionalAction();

  return (
    <Task
      icon={SignatureFreehand}
      fundName={fundName}
      taskName={closingName}
      actionLabel="Sign"
      action={() => action(baseUrl, url)}
    />
  );
};

export const CustomApproveTask = ({ task }) => {
  const { fundName, lpName, fundId, closingId, lpClosingId } = task;
  const url = urls.reviewUrl({ fundId, closingId, lpClosingId });
  const { baseUrl } = task;
  const action = useRegionalAction();
  return (
    <Task
      icon={Icons.AccountTreeOutlined}
      fundName={fundName}
      taskName={lpName}
      actionLabel="Review"
      action={() => action(baseUrl, url)}
    />
  );
};
