import { NotProvidedChip } from 'components/text_chip';
import { MultifileDisplay } from './multi_file_display';
import { MultiselectDisplay } from './multi_select_display';
import { ContactDisplay } from './contact_display';
import { NameWithTitleDisplay } from './name_with_title_display';
import { NameTitleEmailDisplay } from './name_title_email_display';
import { BankDisplay } from './bank_display';
import { W9v3Display } from './w9_v3_display';
import { W9v4Display } from './w9_v4_display';
import { CrsTaxDetailsDisplay } from './crs_tax_details_display';
import { CrsTaxDetailsV2Display } from './crs_tax_details_v2_display';
import { SingleFileDisplay } from './single_file_display';
import { SensitiveData } from './sensitive_data';
import { YesOrNoDisplay } from './yes_or_no_display';
import { AffirmationDisplay } from './affirmation';
import { TrueOrFalseDisplay } from './true_or_false';
import { CountryDisplay } from './country';
import { AddressDisplay } from './address';
import { NameDisplay } from './name';
import { DollarsDisplay } from './dollars';
import { DiligenceAnswerDisplay } from './diligence';
import { GenericAnswerDisplay } from './generic_display';
import { DiligenceInvestorType } from './diligence_investor_type';

export function getAnswerDisplayComponent(answerType) {
  switch (answerType) {
    case 'AnswerType.yes_or_no': {
      return YesOrNoDisplay;
    }
    case 'AnswerType.affirmation': {
      return AffirmationDisplay;
    }
    case 'AnswerType.true_or_false': {
      return TrueOrFalseDisplay;
    }
    case 'AnswerType.country': {
      return CountryDisplay;
    }
    case 'AnswerType.address': {
      return AddressDisplay;
    }
    case 'AnswerType.name': {
      return NameDisplay;
    }
    case 'AnswerType.name_with_title':
    case 'AnswerType.name_required_title': {
      return NameWithTitleDisplay;
    }
    case 'AnswerType.name_title_email': {
      return NameTitleEmailDisplay;
    }
    case 'AnswerType.dollars': {
      return DollarsDisplay;
    }
    case 'AnswerType.contact':
    case 'AnswerType.contact_with_required_address':
    case 'AnswerType.contact_name_and_email':
    case 'AnswerType.contact_with_nationality': {
      return ContactDisplay;
    }
    case 'AnswerType.bank':
    case 'AnswerType.bank_with_address':
    case 'AnswerType.bank_v2':
      return BankDisplay;
    case 'AnswerType.multi_select': {
      return MultiselectDisplay;
    }
    case 'AnswerType.file_upload': {
      return SingleFileDisplay;
    }
    case 'AnswerType.multi_file_upload': {
      return MultifileDisplay;
    }
    case 'AnswerType.w9_v3': {
      return W9v3Display;
    }
    case 'AnswerType.w9_v4': {
      return W9v4Display;
    }
    case 'AnswerType.crs_tax_details': {
      return CrsTaxDetailsDisplay;
    }
    case 'AnswerType.crs_tax_details_v2': {
      return CrsTaxDetailsV2Display;
    }
    case 'AnswerType.ssn_with_escape':
    case 'AnswerType.ssn': {
      return SensitiveData;
    }
    case 'AnswerType.integer':
    case 'AnswerType.text':
    case 'AnswerType.text_long':
    case 'AnswerType.tax_id':
    case 'AnswerType.tax_id_with_escape':
    case 'AnswerType.email':
    case 'AnswerType.tel':
    case 'AnswerType.choice':
    case 'AnswerType.info':
    case 'AnswerType.aba':
    case 'AnswerType.percentage':
    case 'AnswerType.state':
    case 'AnswerType.date':
    case 'AnswerType.giin':
    case 'AnswerType.giin_with_escape':
    case 'AnswerType.date_without_year':
    case 'AnswerType.date_year':
    case 'AnswerType.blocker':
    case 'AnswerType.state_with_escape': {
      return GenericAnswerDisplay;
    }
    case 'DiligenceAnswerType.unknown':
    case 'DiligenceAnswerType.name_known': {
      return DiligenceAnswerDisplay;
    }
    case 'DiligenceAnswerType.tin': {
      return SensitiveData;
    }
    case 'DiligenceAnswerType.investor_type': {
      return DiligenceInvestorType;
    }
    case 'DiligenceAnswerType.not_provided': {
      return NotProvidedChip;
    }
    default:
      break;
  }
  throw new Error(`No display component for answer type: ${answerType}`);
}
