/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FileUploadOutline from 'mdi-material-ui/FileUploadOutline';
import { Spinner } from 'components/spinner';
import { Stepper } from 'components/stepper';
import { useFeatureFlags } from 'services/providers/feature_flag';

import { Document, Page } from 'react-pdf';
import { PDF_HEIGHT, PDF_WIDTH } from 'services/pdf_dimensions';
import { boxTypes, SIGNATURE_BOX_TYPES, SUBDOC_DOC_NAME } from './constants';
import { UploadDraftFileModal } from './modals/upload_draft_file_modal';
import { ManageFilesModal } from './modals/manage_files_modal';

const useStyles = makeStyles(() => ({
  topDialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    display: 'block',
  },
  stepper: {
    backgroundColor: 'inherit',
  },
  pdfDocument: {
    position: 'absolute',
  },
  drawCanvas: {
    position: 'absolute',
  },
  drawingEnabled: {
    cursor: 'crosshair',
  },
}));

const CHECKBOX_TYPES = ['output_checkbox'];
const GRID_SIZE = 15;

function roundToNearest(n, nearest) {
  const ret = Math.ceil((n + 1) / nearest) * nearest;
  if (ret === 0) return nearest;
  return ret;
}

const OTHER_BOX_BG = '#bdbdbd22';
const OTHER_BOX_LINE = '#000000';
const CURRENT_BOX_BG = '#2196f322';
const CURRENT_BOX_LINE = '#00308F';
const HIGHLIGHT_BOX_BG = '#2196f322';
const HIGHLIGHT_BOX_LINE = '#e60e0e';

const docusignImg = new Image();
docusignImg.src = window.static?.docusign_signature_png;

export function PdfEditor({
  fileUrl,
  dragEnabled,
  onDragEnd,
  onTranslate,
  page,
  setPage,
  selectedBox,
  boxes,
  questions,
  activeQuestion,
  handleClickItem,
  setHighlightBoxId,
  highlightBox,
  onDraftFileUploadSuccess,
  documents,
  selectDocument,
  selectedDocument,
}) {
  const { MULTIPLE_DOCUMENTS } = useFeatureFlags();
  const [pdf, setPdf] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const drawCanvasRef = useRef(null);
  const keyboardAreaRef = useRef(null);
  const classes = useStyles();
  const otherBoxes = MULTIPLE_DOCUMENTS
    ? questions
        .filter((q) => q.id !== activeQuestion?.id)
        .map((q) =>
          (q.hellosignBoxes || []).filter(
            (b) =>
              b.page === page &&
              (b.documentName || null) === selectedDocument?.name,
          ),
        )
        .flat()
    : questions
        .filter((q) => q.id !== activeQuestion?.id)
        .map((q) => (q.hellosignBoxes || []).filter((b) => b.page === page))
        .flat();

  const allBoxes = [...boxes, ...otherBoxes];

  function initDrawingCanvas() {
    const drawCanvas = drawCanvasRef.current;
    const ctx = drawCanvas.getContext('2d');
    const rect = {};
    let drag = false;

    function getMousePos(e) {
      const bound = drawCanvas.getBoundingClientRect();
      return {
        x: Math.round(
          ((e.clientX - bound.left) / (bound.right - bound.left)) *
            drawCanvas.width,
        ),
        y: Math.round(
          ((e.clientY - bound.top) / (bound.bottom - bound.top)) *
            drawCanvas.height,
        ),
      };
    }

    function findBoxForMousePos(e) {
      const mouse = getMousePos(e);
      return allBoxes.find((box) => {
        const path = new Path2D();
        path.rect(box.x, box.y, box.w, box.h);
        return ctx.isPointInPath(path, mouse.x, mouse.y);
      });
    }

    function mousemove(e) {
      const box = findBoxForMousePos(e);

      if (box) {
        drawCanvas.style.cursor = 'pointer';
      } else if (dragEnabled) {
        drawCanvas.style.cursor = 'crosshair';
      } else {
        drawCanvas.style.cursor = 'default';
      }
    }

    function click(e) {
      const box = findBoxForMousePos(e);

      if (box) {
        const question = questions.find((q) =>
          (q.hellosignBoxes || []).map((h) => h.id).includes(box.id),
        );

        if (question) {
          if (!activeQuestion || question.id !== activeQuestion.id) {
            // If we're not changing questions, no need to call this
            handleClickItem(question);
          }
          setHighlightBoxId(box.id);
        }
      }
    }

    function getTextForBox(b) {
      const qId = b.id.split('-')[0];
      const question = questions.find((q) => q.id === qId);
      if (question) {
        return question.text;
      }
      return '';
    }

    function drawBoxText(b) {
      if (SIGNATURE_BOX_TYPES.includes(b.type)) {
        ctx.fillText(b.type, b.x + 2, b.y + 8);
        ctx.drawImage(docusignImg, b.x, b.y - 18, 120, 50);
      } else if (CHECKBOX_TYPES.includes(b.type)) {
        ctx.fillText('✔', b.x + 2, b.y + 10);
      } else if (b.type === boxTypes.CUSTOMTEXTBOX) {
        ctx.fillText(getTextForBox(b), b.x + 2, b.y + 4);
      } else {
        ctx.fillText(b.type, b.x + 2, b.y + 4);
      }

      if (b.deps?.length > 0) {
        ctx.fillText('?', b.x + 2, b.y - 8);
      }
    }

    function drawBoxes() {
      ctx.font = '11px Helvetica';
      ctx.textBaseline = 'middle';
      boxes.forEach((b) => {
        if (page === b.page) {
          ctx.setLineDash([]);
          ctx.strokeStyle =
            highlightBox.id === b.id ? HIGHLIGHT_BOX_LINE : CURRENT_BOX_LINE;
          ctx.fillStyle =
            highlightBox.id === b.id ? HIGHLIGHT_BOX_BG : CURRENT_BOX_BG;
          ctx.fillRect(b.x, b.y, b.w, b.h);
          ctx.strokeRect(b.x, b.y, b.w, b.h);
          ctx.fillStyle =
            highlightBox.id === b.id ? HIGHLIGHT_BOX_LINE : CURRENT_BOX_LINE;
          drawBoxText(b);
        }
      });
      ctx.strokeStyle = OTHER_BOX_LINE;
      otherBoxes.forEach((b) => {
        if (page === b.page) {
          ctx.setLineDash([]);
          ctx.fillStyle = OTHER_BOX_BG;
          ctx.fillRect(b.x, b.y, b.w, b.h);
          ctx.strokeRect(b.x, b.y, b.w, b.h);
          ctx.fillStyle = OTHER_BOX_LINE;
          drawBoxText(b);
        }
      });
    }

    function draw() {
      ctx.strokeStyle = '#FF0000';
      ctx.fillStyle = '#FF000022';
      ctx.setLineDash([6]);
      ctx.fillRect(rect.startX, rect.startY, rect.w, rect.h);
      ctx.strokeRect(rect.startX, rect.startY, rect.w, rect.h);
      ctx.fillStyle = '#FF0000';

      const boxType = selectedBox?.type;
      if (CHECKBOX_TYPES.includes(boxType)) {
        ctx.fillText('✔', rect.startX + 2, rect.startY + 10);
      } else {
        ctx.fillText(boxType || '', rect.startX + 2, rect.startY + 4);
      }
    }

    function normalizeRect() {
      // Fix rectangles draw backwards or upside-down
      if (rect.w < 0) {
        rect.startX += rect.w;
        rect.w = Math.abs(rect.w);
      }
      if (rect.h < 0) {
        rect.startY += rect.h;
        rect.h = Math.abs(rect.h);
      }
    }

    function mouseDown(e) {
      if (!dragEnabled) {
        return;
      }
      const { x, y } = getMousePos(e);
      rect.startX = x;
      rect.startY = y;
      rect.w = GRID_SIZE;
      rect.h = GRID_SIZE;
      drag = true;
    }

    function mouseUp() {
      drag = false;
      ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      drawBoxes();
      normalizeRect();
      onDragEnd({ ...rect, page });
    }

    function mouseMove(e) {
      if (drag) {
        const { x, y } = getMousePos(e);
        rect.w = roundToNearest(x - rect.startX, GRID_SIZE);
        rect.h = roundToNearest(y - rect.startY, GRID_SIZE);
        ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
        draw();
        drawBoxes();
      }
    }

    drawBoxes();

    drawCanvas.addEventListener('click', click, true);
    drawCanvas.addEventListener('mousemove', mousemove, true);
    drawCanvas.addEventListener('mousedown', mouseDown, true);
    drawCanvas.addEventListener('mouseup', mouseUp, true);
    drawCanvas.addEventListener('mousemove', mouseMove, true);

    function cleanup() {
      ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      drawCanvas.removeEventListener('click', click, true);
      drawCanvas.removeEventListener('mousemove', mousemove, true);
      drawCanvas.removeEventListener('mousedown', mouseDown, true);
      drawCanvas.removeEventListener('mouseup', mouseUp, true);
      drawCanvas.removeEventListener('mousemove', mouseMove, true);
    }

    return cleanup;
  }

  useEffect(initDrawingCanvas, [
    dragEnabled,
    page,
    highlightBox.timestamp,
    allBoxes
      .map((b) => b.id + b.type + b.x + b.y + b.h + b.w + b.deps + b.text)
      .join('/'),
    questions.map((q) => q.text).join('/'),
  ]);

  function initKeyboard() {
    function keyDown(e) {
      switch (e.code) {
        case 'ArrowLeft':
          onTranslate({ x: -1, y: 0 });
          return;
        case 'ArrowRight':
          onTranslate({ x: 1, y: 0 });
          return;
        case 'ArrowUp':
          onTranslate({ x: 0, y: -1 });
          return;
        case 'ArrowDown':
          onTranslate({ x: 0, y: 1 });
          break;

        default:
      }
    }

    const keyboardArea = keyboardAreaRef.current;
    keyboardArea.addEventListener('keydown', keyDown, true);

    return () => {
      keyboardArea.removeEventListener('keydown', keyDown, true);
    };
  }

  useEffect(initKeyboard, [onTranslate]);

  return (
    <>
      {MULTIPLE_DOCUMENTS ? (
        <ManageFilesModal
          showModal={showFileUploadModal}
          onUpload={onDraftFileUploadSuccess}
          documents={documents}
          onClose={() => {
            setShowFileUploadModal(false);
          }}
        />
      ) : (
        <UploadDraftFileModal
          showModal={showFileUploadModal}
          onUpload={onDraftFileUploadSuccess}
          onClose={() => {
            setShowFileUploadModal(false);
          }}
        />
      )}
      <div>
        <div className={classes.dialogActions}>
          <IconButton
            onClick={() => {
              setZoom((z) => z + 0.1);
            }}
            aria-label="zoom in"
          >
            <ZoomInIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setZoom((z) => z - 0.1);
            }}
            aria-label="zoom out"
          >
            <ZoomOutIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setShowFileUploadModal(true);
            }}
          >
            <FileUploadOutline />
          </IconButton>

          {MULTIPLE_DOCUMENTS && documents?.length ? (
            <Select
              labelId="version-label"
              value={selectedDocument?.name || SUBDOC_DOC_NAME}
              label="Version"
              size="small"
              onChange={(e) =>
                selectDocument(
                  e.target.value === SUBDOC_DOC_NAME ? null : e.target.value,
                )
              }
            >
              {documents.map((doc) => (
                <MenuItem key={doc.name} value={doc.name || SUBDOC_DOC_NAME}>
                  {doc.name || SUBDOC_DOC_NAME}
                </MenuItem>
              ))}
            </Select>
          ) : null}

          {Boolean(pdf && pdf.numPages) && (
            <Stepper page={page} setPage={setPage} numPages={pdf.numPages} />
          )}
        </div>
        <div
          ref={keyboardAreaRef}
          tabIndex="0"
          style={{ transform: `scale(${zoom})` }}
        >
          <Document
            className={classes.pdfDocument}
            file={MULTIPLE_DOCUMENTS ? selectedDocument?.fileUrl : fileUrl}
            onLoadSuccess={(p) => {
              setPdf(p);
            }}
            loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
          >
            <Page
              pageNumber={page}
              loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
              height={PDF_HEIGHT}
              width={PDF_WIDTH}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
          <canvas
            className={clsx(classes.drawCanvas, {
              [classes.drawingEnabled]: dragEnabled,
            })}
            ref={drawCanvasRef}
            height={`${PDF_HEIGHT}px`}
            width={`${PDF_WIDTH}px`}
          />
        </div>
      </div>
    </>
  );
}
