import { dateCompare, strCompare } from 'services/shared_sort_comparisons';

function numCompare(a, b, getAttr, direction) {
  const valA = getAttr(a);
  const valB = getAttr(b);
  if (valA === valB) {
    // important for secondary sorting that we don't
    // break ties.
    return 0;
  }
  if (direction === 'asc') {
    return valA < valB ? 1 : -1;
  }
  return valA < valB ? -1 : 1;
}

function getSignedStatus(status, lpClosing) {
  // These adjustments are used to order the sub-statuses
  const beforeFullApproval =
    lpClosing.hasDiligence &&
    lpClosing.diligenceApproved &&
    lpClosing.questionnaireApproved;

  if (lpClosing.customState?.sortOrder) {
    return status + 0.5 + lpClosing.customState.sortOrder / 1000;
  }
  if (beforeFullApproval) {
    return status + 0.4;
  }
  if (lpClosing.questionnaireApproved) {
    return status + 0.3;
  }
  if (lpClosing.diligenceApproved) {
    return status + 0.2;
  }
  if (lpClosing.hasLpRequestedReview) {
    return status;
  }
  // Investor signed
  return status + 0.1;
}

export function statusCompare(a, b, dir) {
  const statuses = {
    DRAFT: 0,
    VIEWED: 1,
    UNVIEWED: 2,
    IN_PROGRESS: 3,
    PARTIALLY_SIGNED: 4,
    REQUESTED_CHANGES: 5,
    SIGNED: 6,
    APPROVED: 7,
    SENT_TO_COUNTERSIGNER: 10,
    COUNTERSIGNED: 11,
  };
  const getStatus = (r) => {
    const status = statuses[r.status];
    if (r.status === 'SIGNED') {
      return getSignedStatus(status, r);
    }
    return status;
  };

  if (dir === 'asc') {
    return getStatus(b) - getStatus(a);
  }
  return getStatus(a) - getStatus(b);
}

const SORT_DATE_BY_STATUS = {
  VIEWED: 'sentDate',
  UNVIEWED: 'sentDate',
  IN_PROGRESS: 'sentDate',
  REQUESTED_CHANGES: 'sentDate',
  SIGNED: 'completedDate',
};

function getDateToCompare(inv) {
  const attr = SORT_DATE_BY_STATUS[inv.status];
  return inv[attr];
}

export function sortInvestors(order, investors) {
  const [columnId, direction] = order;
  const sortedInvestors = [...investors];

  sortedInvestors.sort((a, b) => {
    // Secondary sorting dimensions
    const byNameDesc = strCompare(a, b, (inv) => inv.lpName, 'desc');
    const byStatusDesc = statusCompare(a, b, 'desc');

    switch (columnId) {
      case 'investor':
        /* eslint-disable-next-line no-case-declarations */
        const byName = strCompare(a, b, (inv) => inv.lpName, direction);
        return byName || byStatusDesc;
      case 'legalName': {
        const byLegalName = strCompare(a, b, (inv) => inv.legalName, direction);

        return byLegalName || byStatusDesc;
      }
      case 'status':
        /* eslint-disable-next-line no-case-declarations */
        const byStatus = statusCompare(a, b, direction);
        /* eslint-disable-next-line no-case-declarations */
        const byDate = dateCompare(a, b, getDateToCompare, direction);
        return byStatus || byDate || byNameDesc;
      case 'commitment':
        /* eslint-disable-next-line no-case-declarations */
        const byCommitment = numCompare(
          a,
          b,
          (inv) => Number(inv.commitment),
          direction,
        );
        return byCommitment || byNameDesc;
      case 'riskRating': {
        const byRiskRating = strCompare(
          a,
          b,
          (inv) => inv.riskRating,
          direction,
        );

        return byRiskRating || byNameDesc;
      }
      default:
        throw Error('unknown column');
    }
  });

  return sortedInvestors;
}
