import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';
import Collapse from '@material-ui/core/Collapse';

import { Button } from 'components/button';
import { getAnswerDisplayComponent } from 'components/answer_displays/get_answer_display';
import {
  QUESTIONNAIRE_EVENT_TYPES,
  DILIGENCE_EVENT_TYPES,
  automatedDiligenceEvents,
} from 'services/thread_utils/constants';
import { canTruncateDisplayComponent } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.spacing(2),
  },
  expandedAnswer: {
    marginTop: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    overflowWrap: 'anywhere',
  },
  eventTime: {
    minWidth: 'max-content',
  },
  fullWidth: {
    width: '100%',
  },
}));

function getDiligenceEventText(event) {
  const moreThanOneMatch = event.potentialMatchNames?.length > 1;
  const potentialMatchName = event.potentialMatchNames
    ? event.potentialMatchNames[0]
    : '';

  switch (event.type) {
    case DILIGENCE_EVENT_TYPES.ANSWER_SUBMITTED:
      if (event?.isAnswerUnchanged) {
        return 'submitted without changing their answer';
      }
      if (event?.answer?.idToReuse) {
        // as the original question/node may have been deleted, we cant assume that we
        // can always access/compute its name so simply avoid using it even if available
        return 'reused answers from another owner';
      }
      return 'answered:';

    case DILIGENCE_EVENT_TYPES.GP_ANSWER_SUBMITTED:
      return 'updated the answer to:';
    case DILIGENCE_EVENT_TYPES.GP_SENT_COMMENT:
      return 'sent a comment:';
    case DILIGENCE_EVENT_TYPES.MODIFIED_SUPPLEMENTARY_FILES:
      return 'added/removed additional documents:';
    case DILIGENCE_EVENT_TYPES.CHANGE_REQUEST_RESOLVED:
      return 'resolved the thread';
    case DILIGENCE_EVENT_TYPES.LP_SENT_COMMENT:
      return 'sent a comment:';
    case DILIGENCE_EVENT_TYPES.THREAD_REOPENED:
      return 'The comment thread was reopened.';
    case DILIGENCE_EVENT_TYPES.INTERNAL_NOTE_ADDED:
      return 'added an internal note:';
    case DILIGENCE_EVENT_TYPES.APPROVED:
      return 'marked this individual/entity as reviewed.';
    case DILIGENCE_EVENT_TYPES.UNAPPROVED:
      return 'undid review of this individual/entity';
    case DILIGENCE_EVENT_TYPES.ANSWER_CHANGE_UNAPPROVED:
      return 'This individual/entity is no longer marked as reviewed with updated submission.';
    case DILIGENCE_EVENT_TYPES.NEW_MATCH:
      if (moreThanOneMatch) {
        return 'New potential matches were added:';
      }
      return 'A new potential match was added:';
    case DILIGENCE_EVENT_TYPES.UPDATED_MATCH:
      return 'There was updated match data for:';
    case DILIGENCE_EVENT_TYPES.MARKED_NOT_TRUE_MATCH:
      return `marked ${potentialMatchName} as not a true match.`;
    case DILIGENCE_EVENT_TYPES.MARKED_TRUE_MATCH:
      return `marked ${potentialMatchName} as a true match.`;
    case DILIGENCE_EVENT_TYPES.MARKED_NOT_SURE:
      return `marked ${potentialMatchName} as an unsure match.`;
    case DILIGENCE_EVENT_TYPES.MARKED_NO_CHOICE:
      return `unmarked ${potentialMatchName}.`;
    case DILIGENCE_EVENT_TYPES.MANUAL_SEARCH:
      return 'started screening for this person/entity.';
    case DILIGENCE_EVENT_TYPES.TYPE_GP_CREATED_OWNER:
      return 'created this individual/entity.';
    case DILIGENCE_EVENT_TYPES.MARK_AS_REVIEWED:
      return `marked the match ${potentialMatchName} as reviewed.`;
    default:
      return '';
  }
}

function getQuestionnaireEventText(event) {
  switch (event.type) {
    case QUESTIONNAIRE_EVENT_TYPES.THREAD_RESOLVED:
      return 'resolved the comment thread.';
    case QUESTIONNAIRE_EVENT_TYPES.LP_SENT_COMMENT:
    case QUESTIONNAIRE_EVENT_TYPES.GP_SENT_COMMENT:
      return 'commented:';
    case QUESTIONNAIRE_EVENT_TYPES.THREAD_REOPENED:
      return 'The comment thread was reopened.';
    case QUESTIONNAIRE_EVENT_TYPES.ANSWER_PREFILLED:
      return 'prefilled answer:';
    case QUESTIONNAIRE_EVENT_TYPES.INTERNAL_NOTE_ADDED:
      return 'added an internal note:';
    case QUESTIONNAIRE_EVENT_TYPES.ANSWER_SUBMITTED: {
      if (event.answer === null) {
        return 'did not provide an answer.';
      }

      if (event?.isAnswerUnchanged) {
        return 'submitted without changing this answer.';
      }

      return 'answered:';
    }
    default:
      return '';
  }
}

function getLocalTime(time) {
  return time.split(',')[1];
}

function FlattenedAnswerDisplay({
  truncateFlattenedAnswer,
  jurisdiction,
  answer,
  answerType,
  handleClick,
}) {
  const classes = useStyles();

  const AnswerComponent = getAnswerDisplayComponent(answerType);
  const ignoreQuoteMarks = [
    'AnswerType.ssn',
    'AnswerType.ssn_with_escape',
  ].includes(answerType);
  const isFileQuestion =
    answerType === 'AnswerType.file_upload' ||
    answerType === 'AnswerType.multi_file_upload';
  const canTruncateFlattenedAnswer = canTruncateDisplayComponent(
    answerType,
    answer,
  );

  if (isFileQuestion) {
    return (
      <div className={classes.expandedAnswer}>
        <AnswerComponent answer={answer} />
      </div>
    );
  }

  return (
    <>
      {ignoreQuoteMarks ? '' : '"'}
      <AnswerComponent answer={answer} flatten jurisdiction={jurisdiction} />
      {ignoreQuoteMarks ? '' : '"'}{' '}
      {canTruncateFlattenedAnswer ? (
        <>
          <Button variant="text" onClick={handleClick}>
            {truncateFlattenedAnswer ? 'Hide full answer' : 'See full answer'}
          </Button>

          <Collapse in={truncateFlattenedAnswer}>
            <div className={classes.expandedAnswer}>
              <AnswerComponent answer={answer} jurisdiction={jurisdiction} />
            </div>
          </Collapse>
        </>
      ) : null}
    </>
  );
}

export function EventHeader({
  event,
  answerType,
  nameVal,
  time,
  jurisdiction,
  flattenEvent,
  className,
}) {
  const classes = useStyles();
  const [truncateFlattenedAnswer, setTruncateFlattenedAnswer] = useState(false);

  const isAutomatedDiligenceEvent =
    jurisdiction && automatedDiligenceEvents.includes(event.type);
  const isThreadReopenedEvent =
    event.type === QUESTIONNAIRE_EVENT_TYPES.THREAD_REOPENED ||
    event.type === DILIGENCE_EVENT_TYPES.THREAD_REOPENED;
  const shouldHideName = isAutomatedDiligenceEvent || isThreadReopenedEvent;
  const reusesDiligenceData = jurisdiction && event?.answer?.idToReuse;
  const localEventTime = getLocalTime(time);
  const headerText = jurisdiction
    ? getDiligenceEventText(event)
    : getQuestionnaireEventText(event);

  if (!headerText) {
    // should not be reached, but return null in case of new event types to
    // indicate that this file needs to be updated
    return null;
  }

  const showFlattenedAnswer =
    !reusesDiligenceData &&
    flattenEvent &&
    event.answer !== null &&
    !event?.isAnswerUnchanged;

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.fullWidth}>
        <Typography variant="body" size="small">
          {shouldHideName ? null : <b>{nameVal}</b>} {headerText}{' '}
          {showFlattenedAnswer ? (
            <FlattenedAnswerDisplay
              truncateFlattenedAnswer={truncateFlattenedAnswer}
              answerType={answerType}
              answer={event.answer}
              handleClick={() => {
                setTruncateFlattenedAnswer((tfa) => !tfa);
              }}
              jurisdiction={jurisdiction}
            />
          ) : null}
        </Typography>
      </div>

      <Typography variant="body" size="small" className={classes.eventTime}>
        {localEventTime}
      </Typography>
    </div>
  );
}
