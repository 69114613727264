import { NewInvestorClosingDialog } from './new_investor_closing_dialog';
import { SubscriptionDocumentArchivedDialog } from './subscription_document_archived_dialog';

export function AddInvestorDialog({
  open,
  externalSignupLink,
  enableCommitmentPrefill,
  subdocIsArchived,
  handleClose,
  onChange,
}) {
  if (!subdocIsArchived) {
    return NewInvestorClosingDialog({
      open,
      externalSignupLink,
      enableCommitmentPrefill,
      handleClose,
      onChange,
    });
  }
  return SubscriptionDocumentArchivedDialog({
    open,
    handleClose,
  });
}
