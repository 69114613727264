/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import UploadIcon from 'mdi-material-ui/Upload';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useQuestionnaire } from 'services/providers/questionnaire';

import { Button } from 'components/button';
import * as urls from 'services/urls';
import {
  constructErrorDisplay,
  validateUploadedFile,
} from './dnd_file_upload/utils';
import {
  MAX_LARGE_FILE_UPLOAD_SIZE,
  MAX_SMALL_FILE_UPLOAD_SIZE,
} from './dnd_file_upload/constants';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  windowDragging: {
    borderRadius: '4px',
    padding: theme.spacing(4, 8),
    backgroundColor: theme.palette.primary.main,
  },
  dragging: {
    backgroundColor: theme.palette.primary.main,
  },
  label: {
    overflowY: 'hidden',
  },
  boldLabel: {
    overflowY: 'hidden',
    fontWeight: 500,
  },
  labelHiddenTop: {
    transform: 'translate(0, -100px)',
    height: 0,
  },
  labelHiddenBottom: {
    transform: 'translate(0, 100px)',
    height: 0,
  },
  labelVisible: {
    transform: 'translate(0)',
  },
  dropzone: {
    transition: '0.2s',
    whiteSpace: 'nowrap',
  },
  icon: {
    fontSize: theme.typography.pxToRem(50),
  },
  text: {
    textAlign: 'center',
    flexGrow: 1,
  },
  fileUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function getEmptyFile() {
  return {
    fileId: null,
    fileUrl: null,
    fileName: null,
  };
}

function defaultUploadFile(file, uploadUrl) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return axios.put(uploadUrl, formData, config);
}

function AbstractFileUpload({
  file,
  onChange,
  url,
  accept,
  errorMsg,
  text,
  variant,
  loading,
  fullWidth,
  omitStartIcon,
  useBoldLabel,
  showAfterUpload,
  uploadFileData = (fileData, fileUrl) => defaultUploadFile(fileData, fileUrl),
  maxFileSize = null,
  ...extraInputProps
}) {
  const { fileUrl, fileName } = file;

  const classes = useStyles();
  const { useSupport } = useQuestionnaire();
  const { showSupport } = useSupport();

  const [windowDragging, setWindowDragging] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [localFile, setLocalFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const buttonText = text || 'Drag or browse';
  const btnVariant = variant || 'outlined';
  const labelClass = useBoldLabel ? classes.boldLabel : classes.label;

  function onDelete() {
    onChange(getEmptyFile());
  }

  const afterUploadElement = showAfterUpload || (
    <>
      <Button
        loading={loading}
        color="default"
        onClick={() => {
          window.open(fileUrl, '_blank');
        }}
        endIcon={<OpenInNewIcon />}
      >
        {fileName}
      </Button>
      {loading ? null : (
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );

  function onDropAccepted(files) {
    setError('');
    const fileToUpload = files[0];
    setLocalFile(fileToUpload);
  }

  function onDropRejected(rejectedFiles) {
    if (rejectedFiles.length > 0) {
      setError(constructErrorDisplay(rejectedFiles, showSupport));
      return;
    }
    setError(errorMsg);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
    preventDropOnDocument: true,
    onDragEnter: () => {
      setDragging(true);
    },
    onDragLeave: () => {
      setDragging(false);
    },
    onDrop: () => {
      setDragging(false);
    },
    onDropAccepted,
    onDropRejected,
    validator: validateUploadedFile(accept, maxFileSize),
    disabled: uploading,
  });

  function fileUpload() {
    if (localFile === null) {
      return;
    }

    setUploading(true);
    uploadFileData(localFile, url)
      .then((response) => {
        setUploading(false);
        const remoteFile = {
          fileId: response.data.id,
          fileUrl: response.data.file,
          fileName: response.data.name,
        };
        onChange(remoteFile);
      })
      .catch((ex) => {
        setUploading(false);
        if (ex.response && ex.response.data.file) {
          setError(ex.response.data.file);
          return;
        }
        setError('Error uploading file.');
      });
  }

  function windowStuff() {
    function dragEnter() {
      clearTimeout(window.dragTimeout);
      setWindowDragging(true);
    }

    function dragStop() {
      setWindowDragging(false);
    }

    function dragLeave() {
      clearTimeout(window.dragTimeout);
      window.dragTimeout = setTimeout(dragStop, 500);
    }

    window.addEventListener('dragenter', dragEnter);
    window.addEventListener('dragover', dragEnter);
    window.addEventListener('dragleave', dragLeave, false);
    window.addEventListener('drop', dragStop);

    return () => {
      window.removeEventListener('dragenter', dragEnter);
      window.removeEventListener('dragover', dragEnter);
      window.removeEventListener('dragleave', dragLeave);
      window.removeEventListener('drop', dragStop);
    };
  }

  useEffect(fileUpload, [localFile]);
  useEffect(windowStuff, []);

  const allInputProps = { ...getInputProps(), ...extraInputProps };

  return (
    <>
      {fileUrl && fileName ? (
        afterUploadElement
      ) : (
        <div className={classes.fileUploadContainer}>
          <Paper
            style={{ display: 'inline-block', width: fullWidth ? '100%' : '' }}
            elevation={windowDragging && !dragging ? 20 : 0}
          >
            <Button
              {...getRootProps({
                refKey: 'innerRef',
                className: clsx(classes.dropzone, {
                  [classes.windowDragging]: windowDragging,
                  [classes.dragging]: dragging,
                }),
              })}
              startIcon={
                omitStartIcon ? undefined : (
                  <UploadIcon className={classes.icon} />
                )
              }
              loading={uploading || loading}
              variant={windowDragging ? 'contained' : btnVariant}
              disabled={uploading}
              fullWidth={fullWidth}
            >
              <input {...allInputProps} />

              <div className={classes.text}>
                <Typography
                  className={clsx(labelClass, {
                    [classes.labelVisible]: !windowDragging,
                    [classes.labelHiddenTop]: windowDragging,
                  })}
                >
                  {uploading ? 'Uploading' : buttonText}
                </Typography>
                <Typography
                  className={clsx(labelClass, {
                    [classes.labelVisible]: windowDragging,
                    [classes.labelHiddenBottom]: !windowDragging,
                  })}
                >
                  Drop here
                </Typography>
              </div>
            </Button>
          </Paper>
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </div>
      )}
    </>
  );
}

const uploadLargeFile = (file, uploadUrl) => {
  const formData = new FormData();
  formData.append('file', file);

  function generateSignedUrl() {
    return axios.post(uploadUrl, { name: file.name });
  }

  function uploadToGoogle({ data }) {
    return axios
      .put(data.url, file, {
        withCredentials: false,
        headers: {
          'X-Goog-Content-Length-Range': `0,${MAX_LARGE_FILE_UPLOAD_SIZE}`,
          'content-type': 'multipart/form-data',
          'x-csrftoken': null,
        },
      })
      .then(() => axios.get(uploadUrl, { params: { file_id: data.file_id } }));
  }

  return generateSignedUrl().then(uploadToGoogle);
};

export function FileUpload({ file, onChange, ...extraInputProps }) {
  const fundId = urls.useFundId();
  const url = `/api/fund/${fundId}/file/upload/`;
  const accept = 'application/pdf';
  const errorMsg = 'Select a single file in pdf format.';
  return AbstractFileUpload({
    file,
    onChange,
    url,
    accept,
    errorMsg,
    maxFileSize: MAX_LARGE_FILE_UPLOAD_SIZE,
    uploadFileData: (fileData, fileUrl) => {
      if (fileData.size > MAX_SMALL_FILE_UPLOAD_SIZE) {
        return uploadLargeFile(fileData, `${url}large/`);
      }
      return defaultUploadFile(fileData, fileUrl);
    },
    ...extraInputProps,
  });
}

export function LPFileUpload({
  file,
  onChange,
  text,
  variant,
  loading,
  fullWidth,
  showAfterUpload,
  uploadSingleFile,
  ...extraInputProps
}) {
  const { lpDocumentId } = useParams();
  const url = `/api/lpclosing/${lpDocumentId}/file/upload/`;

  const accept = '';
  const errorMsg = 'Select a single file.';
  return AbstractFileUpload({
    file,
    onChange,
    url,
    accept,
    errorMsg,
    text,
    variant,
    loading,
    fullWidth,
    showAfterUpload,
    omitStartIcon: true,
    uploadFileData: uploadSingleFile,
    ...extraInputProps,
  });
}
