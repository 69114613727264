import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StateInput, getState as getUSState } from 'components/state';
import { TextField } from '@material-ui/core';
import { CountryInput, getCountry } from 'components/country';
import clsx from 'clsx';
import { objectEquals } from 'services/utils';
import { AddressLine1Input } from './address_line_1';

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  stateAndZip: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '&$usaVersion': {
      flexDirection: 'row',
    },
  },
  usaVersion: {},
}));

export function getDefaultEmptyAddress() {
  return {
    emptyAddress: {
      line1: {
        country: '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
        description: '',
      },
      line2: '',
    },
  };
}

const line1Fields = [
  'country',
  'street',
  'city',
  'state',
  'postalCode',
  'description',
];

export function Address({
  address: initialAddress,
  onChange,
  label,
  errors,
  ...props
}) {
  const { emptyAddress } = getDefaultEmptyAddress();

  const [address, setAddress] = React.useState(() => ({
    ...emptyAddress,
    ...(initialAddress || {}),
  }));
  const { line1, line2 } = address;
  const { city, state, country, postalCode } = line1 || {};

  const line2ErrorMsg = errors?.line2;
  const streetErrorMsg = errors?.line1?.street || errors?.[0] || '';
  const cityErrorMsg = errors?.line1?.city || '';
  const stateErrorMsg = errors?.line1?.state || '';
  const countryErrorMsg = errors?.line1?.country || '';
  const postalCodeErrorMsg = errors?.line1?.postalCode || '';

  const classes = useStyles({ country });

  function handleChange(field, value) {
    if (value === null) {
      setAddress(() => ({
        ...emptyAddress,
        line1: {
          ...emptyAddress.line1,
          country: field === 'country' ? '' : country,
        },
      }));
      return;
    }

    setAddress((prev) => {
      const newAddress = { line1: { ...prev.line1 }, line2: prev.line2 || '' };
      if (field === 'line1') {
        if (value === null || typeof value !== 'object') {
          newAddress.line1.street = value;
        } else {
          newAddress.line1 = {
            ...emptyAddress.line1,
            ...value.line1,
            country: value.line1.country,
            description: value.line1.description,
          };
        }
      } else if (field === 'line2') {
        newAddress.line2 = value;
      } else if (line1Fields.includes(field)) {
        newAddress.line1[field] = value;
      }
      return newAddress;
    });
  }

  React.useEffect(() => {
    // clean up state and country values
    const foundCountry = getCountry(country);
    const foundUSState = getUSState(state);

    let newState = null;
    let newCountry = null;
    if (foundCountry?.code === 'US' && !foundUSState) {
      newState = '';
    }
    if (foundCountry && foundCountry?.code !== country) {
      newCountry = foundCountry?.code;
    }
    if (foundUSState && foundUSState.label !== state) {
      newState = foundUSState.label;
    }
    if (newState !== null || newCountry !== null) {
      handleChange('line1', {
        ...address,
        line1: {
          ...line1,
          country: newCountry ?? country,
          state: newState ?? state,
        },
      });
    }
  }, [country, state]);

  React.useEffect(() => {
    if (onChange) {
      onChange(address);
    }
  }, [address]);

  return (
    <div {...props} className={clsx(classes.inputs, props.className)}>
      <CountryInput
        key="country"
        value={country || ''}
        handleChange={(e, v) => {
          handleChange('country', v && v.code ? v.code : null);
        }}
        frontendLabel="Country"
        variant="outlined"
        errorMsg={countryErrorMsg}
        enableAutofill
        hideLabels
      />
      <AddressLine1Input
        address={line1 || {}}
        onChange={(v) => {
          handleChange('line1', v);
        }}
        label={label || 'Address line 1'}
        errorMsg={streetErrorMsg}
        selectedCountry={country}
      />
      <TextField
        key="line2"
        label="Address line 2"
        variant="outlined"
        value={line2 || ''}
        onChange={(e) => {
          handleChange('line2', e.target.value);
        }}
        fullWidth
        inputProps={{
          autoComplete: line2 ? 'chrome-off' : 'address-line2',
        }}
        error={!!line2ErrorMsg}
        helperText={line2ErrorMsg}
      />
      <TextField
        value={city || ''}
        key="city"
        onChange={(e) => {
          handleChange('city', e.target.value);
        }}
        label="City or town"
        fullWidth
        variant="outlined"
        data-test="city"
        inputProps={{
          autoComplete: city ? 'chrome-off' : 'address-level2',
        }}
        error={!!cityErrorMsg}
        helperText={cityErrorMsg}
      />
      <div
        className={clsx(classes.stateAndZip, {
          [classes.usaVersion]: country === 'US',
        })}
      >
        {country === 'US' ? (
          <StateInput
            key="state"
            value={state || ''}
            handleChange={(e, v) => {
              handleChange('state', v && v.label ? v.label : '');
            }}
            frontendLabel="State"
            fullWidth
            errorMsg={stateErrorMsg}
            selectOnFocus={false}
            enableAutofill
          />
        ) : (
          <TextField
            key="state"
            value={state || ''}
            onChange={(e) => {
              handleChange('state', e.target.value);
            }}
            label="State, province, or region"
            fullWidth
            variant="outlined"
            data-test="state"
            inputProps={{
              autoComplete: state ? 'chrome-off' : 'address-level1',
            }}
            error={!!stateErrorMsg}
            helperText={stateErrorMsg}
          />
        )}
        <TextField
          key="postal"
          value={postalCode || ''}
          onChange={(e) => {
            handleChange('postalCode', e.target.value);
          }}
          fullWidth
          label="Zip or postal code"
          variant="outlined"
          data-test="postal"
          inputProps={{
            autoComplete: postalCode ? 'chrome-off' : 'postal-code',
          }}
          error={!!postalCodeErrorMsg}
          helperText={postalCodeErrorMsg}
        />
      </div>
    </div>
  );
}

export function isValidAddress(address) {
  const { street, city, state, country, postalCode } = address?.line1 || {};

  return (
    street &&
    (country === 'US' ? state && city && postalCode : state || city) &&
    country
  );
}
export function objectEqualsEmptyAddress(answer) {
  const { emptyAddress } = getDefaultEmptyAddress();

  // extract isManual from answer in case of
  // a deprecated answer format
  const { isManual, ...address } = answer;
  return objectEquals(emptyAddress, address);
}
