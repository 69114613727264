import React, { useState } from 'react';
import { Modal, Typography, useConfirm } from '@passthrough/uikit';
import { useParams } from 'react-router-dom';

import { objectEquals } from 'services/utils';
import { DiligenceDocumentInputs } from 'components/lp_doc/diligence/subsection_question_display/documents_subsection/index';

function getDataToSubmit(localFileAnswers) {
  const optionalFileIds = Object.keys(localFileAnswers).reduce(
    (acc, fileKey) => {
      acc[fileKey] = localFileAnswers[fileKey]?.fileId;

      return acc;
    },
    {},
  );

  const hasAllFiles = !Object.values(optionalFileIds).includes(null);
  // if a user opted out of providing documents but the GP provides
  // all of them, we need to drop ui warnings tied to the optOut key
  // that indicates missing data by setting it to false

  if (hasAllFiles) {
    return { ...optionalFileIds, optOut: false };
  }

  return optionalFileIds;
}

function filterFileIds(fileData) {
  if (!fileData) {
    return {};
  }

  return Object.keys(fileData).reduce((acc, fileKey) => {
    acc[fileKey] = fileData[fileKey].fileId;

    return acc;
  }, {});
}

export function haveDocumentsChanged(initialFileData, newFileData) {
  // google generates new signed urls every time that we
  // reload data for this page, only compare file ids
  const initialFileIds = filterFileIds(initialFileData);
  const newFileIds = filterFileIds(newFileData);

  return !objectEquals(initialFileIds, newFileIds);
}

export function EditDiligenceDocsModal({
  investorName,
  initialDocumentAnswers,
  docInputData,
  open,
  onClose,
  onSubmit,
}) {
  const { fundId } = useParams();
  const [localDocAnswers, setLocalDocAnswers] = useState(
    initialDocumentAnswers,
  );
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();

  const haveAnswersChanged = haveDocumentsChanged(
    initialDocumentAnswers,
    localDocAnswers,
  );

  function handleChange(key, newFileData) {
    setLocalDocAnswers((lda) => ({ ...lda, [key]: newFileData }));
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!haveAnswersChanged) {
          onClose();
          return;
        }

        confirm({
          title: 'Discard unsaved changes?',
          description: 'Your changes have not been saved.',
          destructive: 'true',
          confirmationText: 'Close',
          size: 'xs',
        })
          .then(() => {
            setLocalDocAnswers(initialDocumentAnswers);
            onClose();
          })
          .catch(() => {});
      }}
      headerLabel="Manage documents"
      showCancelButton
      primaryButtonText="Save changes"
      primaryButtonDisabled={!haveAnswersChanged}
      primaryButtonLoading={loading}
      onSubmit={() => {
        setLoading(true);

        // error handling not really necessary as we validate
        // files when they are initially uploaded
        onSubmit(getDataToSubmit(localDocAnswers)).finally(() => {
          setLoading(false);
        });
      }}
    >
      <DiligenceDocumentInputs
        docFields={docInputData}
        form={localDocAnswers}
        onChange={handleChange}
        fileUploadUrl={`/api/fund/${fundId}/file/upload/`}
        investorName={investorName}
        enableNameChecks
      />

      <Typography variant="body2">
        All uploaded documents are visible to the investor but they will not be
        notified.
      </Typography>
    </Modal>
  );
}
