// cspell:ignore linebreaks
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  linebreaks: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

// TODO: There's an argument to be made that this should be the default
// behavior for Typography. If we decide to go that route, we'll remove
// this component and add multiline to the uikit Typography, and add
// a prop to Typography that disables multiline.
export function TypographyWithLineBreaks({ children, ...typographyProps }) {
  const classes = useStyles();

  // Doing this with javascript instead of white-space: 'pre-line' so that
  // it works similarly to the { message|linebreaks } jinja template filter
  // we use for user inputted messages in emails. i.e. '\n' and '\n\n' both
  // put the same amount of space between lines.

  // TODO: If we make multiline part of Typography, we'll probably need to
  // do something more sophisticated with children.
  if (typeof children !== 'string') {
    return null;
  }

  const splitLines = children.split(/(?:\r\n|\n)+/);

  return (
    <div className={classes.linebreaks}>
      {splitLines.map((line, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={`${line}-${index}`} {...typographyProps}>
          {line}
        </Typography>
      ))}
    </div>
  );
}
