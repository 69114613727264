// @ts-check - for IDE only
import React from 'react';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDistanceStrict } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icons, UserDisplay } from '@passthrough/uikit';
import {
  PARTIALLY_SIGNED_STATUS,
  inProgressStatuses,
  notStartedStatuses,
} from './constants';

const useChipStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerInline: {
    whiteSpace: 'nowrap',
  },
  circle: {
    minWidth: '8px',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    marginRight: theme.spacing(1),
  },
  lineText: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  underline: {
    width: '100%',
    height: '3px',
    borderRadius: '4px',
  },
  infoIcon: {
    color: theme.palette.text.secondary,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  draft: {
    backgroundColor: theme.palette.investorClosingStatus.unsent,
  },
  notStarted: {
    backgroundColor: theme.palette.investorClosingStatus.notStarted,
  },
  waiting: {
    backgroundColor: theme.palette.investorClosingStatus.sent,
  },
  signed: {
    backgroundColor: theme.palette.investorClosingStatus.signed,
  },
  approved: {
    backgroundColor: theme.palette.investorClosingStatus.approved,
  },
  done: {
    backgroundColor: theme.palette.investorClosingStatus.executed,
  },
}));

const DRAFT = 0;
const NOT_STARTED = 1;
const WAITING = 2;
const SIGNED = 3;
const APPROVED = 5;
const DONE = 6;

function getSignedStatusText({
  hasSigning,
  hasLpRequestedReview,
  questionnaireApproved,
  diligenceApproved,
  isDiligenceOnly,
  hasSubApprovals,
  customStatusText,
}) {
  if (customStatusText) {
    return customStatusText;
  }

  if (hasLpRequestedReview) {
    return 'Investor requested review';
  }

  if (diligenceApproved && (questionnaireApproved || isDiligenceOnly)) {
    return 'Ready to approve investor';
  }

  if (questionnaireApproved && !hasSubApprovals) {
    return 'Questionnaire approved';
  }

  if (diligenceApproved && !hasSubApprovals) {
    return 'Diligence reviewed';
  }

  if (hasSigning) {
    return 'Investor signed';
  }

  return 'Investor completed';
}

function getStatuses({
  hasSigning,
  hasLpRequestedReview = false,
  questionnaireApproved = false,
  diligenceApproved = false,
  isDiligenceOnly = false,
  hasSubApprovals = false,
  waitingOnFirstSigner = false,
  customStatusText = null,
}) {
  const signedStatusText = getSignedStatusText({
    hasSigning,
    hasLpRequestedReview,
    questionnaireApproved,
    diligenceApproved,
    isDiligenceOnly,
    hasSubApprovals,
    customStatusText,
  });

  const partiallySignedStatusText = waitingOnFirstSigner
    ? 'Sent — Waiting on first signer'
    : 'Sent — Waiting on additional signer';

  return {
    DRAFT: [DRAFT, 'Unsent'],
    VIEWED: [NOT_STARTED, 'Not started'],
    UNVIEWED: [NOT_STARTED, 'Not started'],
    IN_PROGRESS: [WAITING, 'In progress'],
    REQUESTED_CHANGES: [WAITING, 'Requested changes'],
    PARTIALLY_SIGNED: [WAITING, partiallySignedStatusText],
    SIGNED: [SIGNED, signedStatusText],
    APPROVED_PENDING_PREPARATION: [APPROVED, 'Approved — Pending preparation'],
    APPROVED: [APPROVED, 'Approved'],
    SENT_TO_COUNTERSIGNER: [WAITING, 'Sent to countersigner'],
    COUNTERSIGNED: [DONE, 'Fully executed'],
    CLOSING_EMAIL_SENT: [DONE, 'Closing email sent'],
  };
}

export function StatusDot({ status, hasSigning }) {
  const classes = useChipStyles();

  const code = getStatuses({ hasSigning })[status][0];

  return (
    <div
      className={clsx(classes.circle, {
        [classes.draft]: code === DRAFT,
        [classes.notStarted]: code === NOT_STARTED,
        [classes.waiting]: code === WAITING,
        [classes.signed]: code === SIGNED,
        [classes.approved]: code === APPROVED,
        [classes.done]: code === DONE,
      })}
    />
  );
}

export function getStatusText(
  status,
  {
    hasSigning,
    hasLpRequestedReview = false,
    questionnaireApproved = false,
    diligenceApproved = false,
    isDiligenceOnly = false,
    hasSubApprovals = false,
    customStatusText = null,
  },
) {
  const statuses = getStatuses({
    hasSigning,
    hasLpRequestedReview,
    questionnaireApproved,
    diligenceApproved,
    isDiligenceOnly,
    hasSubApprovals,
    customStatusText,
  });
  return statuses[status][1];
}

function getTimeAgo(date) {
  const now = new Date();
  const then = new Date(date);
  now.setHours(0, 0, 0, 0);
  then.setHours(0, 0, 0, 0);

  let timeAgo = '';
  if (date && then >= now) {
    timeAgo = 'today';
  } else if (date && then < now) {
    timeAgo = formatDistanceStrict(then, now, { addSuffix: true });
  }
  return timeAgo;
}

export function StatusChip({
  status,
  sentDate,
  completedDate,
  hasSigning,
  lastSentEvent,
  hasSubApprovals,
  questionnaireApproved,
  diligenceApproved,
  isDiligenceOnly,
  hasLpRequestedReview,
  fullyExecutedEmailSent,
  waitingOnFirstSigner,
  customStatusText,
  size,
  pendingPreparation,
  forceInline = false,
}) {
  const classes = useChipStyles();

  const sentTimeAgo = getTimeAgo(sentDate);
  const label = getStatuses({
    hasSigning,
    hasLpRequestedReview,
    questionnaireApproved,
    diligenceApproved,
    isDiligenceOnly,
    hasSubApprovals,
    waitingOnFirstSigner,
    customStatusText,
  })[status][1];
  let amendedLabel = label;
  let tooltipText = null;

  if (['VIEWED', 'UNVIEWED', 'IN_PROGRESS'].includes(status)) {
    if (lastSentEvent === 'SENT_REMINDER_TO_INVESTOR') {
      amendedLabel = `${label} — Sent reminder ${sentTimeAgo}`;
    } else if (lastSentEvent === 'SENT_FOR_SIGNATURE') {
      amendedLabel = `Sent for investor signature ${sentTimeAgo}`;
    } else {
      amendedLabel = `${label} — Sent ${sentTimeAgo}`;
    }
  }

  if (['REQUESTED_CHANGES'].includes(status)) {
    if (lastSentEvent === 'SENT_REMINDER_TO_INVESTOR') {
      amendedLabel = `${label} — Sent reminder ${sentTimeAgo}`;
    } else {
      amendedLabel = `${label} ${sentTimeAgo}`;
    }
  }

  if (['SIGNED'].includes(status)) {
    const completedTimeAgo = getTimeAgo(completedDate);
    if (questionnaireApproved || diligenceApproved || customStatusText) {
      amendedLabel = `${label}`;
    } else {
      amendedLabel = `${label} ${completedTimeAgo}`;
    }
  }

  if (['APPROVED'].includes(status)) {
    if (pendingPreparation) {
      amendedLabel = `${label} — Pending preparation`;
      tooltipText =
        'Fields for the countersigner have not been placed in the uploaded document';
    }
  }

  if (['COUNTERSIGNED'].includes(status)) {
    if (fullyExecutedEmailSent) {
      const closingEmailTimeAgo = getTimeAgo(fullyExecutedEmailSent);
      amendedLabel = `Closing email sent ${closingEmailTimeAgo}`;
    }
  }

  return (
    <div
      className={clsx(classes.container, {
        [classes.containerInline]: forceInline,
      })}
    >
      {/* @ts-ignore */}
      <StatusDot status={status} hasSigning={hasSigning} />

      <div className={classes.labelContainer}>
        <Typography
          variant="body"
          data-test="lp_closing_status_text"
          size={size}
        >
          {amendedLabel}
        </Typography>

        {tooltipText ? (
          <Tooltip
            title={<Typography variant="label">{tooltipText}</Typography>}
          >
            <Icons.InfoOutlined className={classes.infoIcon} fontSize="small" />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
}

export function LPStatusText({ status, task }) {
  const { hasLpRequestedReview } = task;
  if (hasLpRequestedReview) {
    return <>Pending review</>;
  }
  if (notStartedStatuses.includes(status)) {
    return <>Not started</>;
  }
  if (inProgressStatuses.includes(status)) {
    return <>In progress</>;
  }
  if (status === PARTIALLY_SIGNED_STATUS) {
    const { signer } = task;
    return (
      <>
        Waiting on{' '}
        <UserDisplay name={signer.name} email={signer.email} variant="inline" />
      </>
    );
  }
  return <>Completed</>;
}
