import React from 'react';

import { Typography, Button, Icons } from '@passthrough/uikit';
import { Setting, SettingsGroup } from 'components/settings_group';
import { useToast } from 'services/toast';

import { InvestorAccessLabel } from './investor_access_label';
import { Message } from './message';
import * as constants from './constants';

function DefaultInvitationMessage({ defaultInvitationText }) {
  return (
    <Setting
      label="Default invitation message (optional)"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Add a message template when you send invitations to investors.
        </Typography>
      }
    >
      <Message
        message={defaultInvitationText}
        missingMessageText="No default message"
      />
    </Setting>
  );
}

function InvestorAccess({ externalSignupEnabled }) {
  return (
    <Setting label="Investor sign-up">
      <InvestorAccessLabel externalSignupEnabled={externalSignupEnabled} />
    </Setting>
  );
}

function CopyLinkButton({ link }) {
  const { toast } = useToast();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    toast('Link copied');
  };
  return (
    <Button
      onClick={copyToClipboard}
      endIcon={<Icons.FileCopyOutlined color="primary" />}
      variant="secondary"
      ellipsis
    >
      {link}
    </Button>
  );
}

function ExternalSignUpLink({ externalSignupLink }) {
  return (
    <Setting label="Sign-up link">
      <Typography>
        <CopyLinkButton link={externalSignupLink} />
      </Typography>
    </Setting>
  );
}

function CustomSignUpMessage({ externalSignupText }) {
  return (
    <Setting
      label="Custom sign-up message (optional)"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Include a message for investors who use the sign-up link.
        </Typography>
      }
    >
      <Message
        message={externalSignupText}
        missingMessageText="No custom message"
      />
    </Setting>
  );
}

export function InvitationSettings({
  defaultInvitationText,
  investorAccess,
  externalSignupLink,
  externalSignupText,
  onEdit,
  readOnly,
}) {
  return (
    <SettingsGroup header="Invitations" onEdit={onEdit} readOnly={readOnly}>
      <DefaultInvitationMessage defaultInvitationText={defaultInvitationText} />
      <InvestorAccess
        externalSignupEnabled={investorAccess === constants.SIGNUP_LINK}
      />

      {investorAccess === constants.SIGNUP_LINK ? (
        <>
          <ExternalSignUpLink externalSignupLink={externalSignupLink} />
          <CustomSignUpMessage externalSignupText={externalSignupText} />
        </>
      ) : null}
    </SettingsGroup>
  );
}
