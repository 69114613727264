export function strCompare(a, b, getAttr, dir) {
  const getStr = (r) => getAttr(r)?.toLowerCase();
  const valA = getStr(a);
  const valB = getStr(b);
  if (valA === valB) {
    // important for secondary sorting that we don't
    // break ties.
    return 0;
  }

  // if legalName is not set, we want to sort it to the bottom
  if (!valA) {
    return 1;
  }
  if (!valB) {
    return -1;
  }

  if (dir === 'asc') {
    return valA < valB ? 1 : -1;
  }
  return valA < valB ? -1 : 1;
}

export function dateCompare(a, b, getAttr, dir) {
  const valA = getAttr(a);
  const valB = getAttr(b);

  // for undefined dates and secondary sorting
  if (valA === valB) {
    return 0;
  }

  const d1 = new Date(valA);
  const d2 = new Date(valB);

  if (dir === 'asc') {
    return d1.getTime() < d2.getTime() ? 1 : -1;
  }
  return d1.getTime() < d2.getTime() ? -1 : 1;
}
