import React from 'react';
import { Typography, Alert } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

import {
  buildMinOwnerRequirementAlerts,
  hasOwnerErrors,
  ALL_OWNERS_KEY,
} from '../../utils';
import { NameList } from '../../components/name_list';
import { SubsectionDisplay } from '../subsection_display';

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  instructionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  minRequirementsList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
  allOwners: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    margin: theme.spacing(0),
  },
}));

export function OwnersSubsection({
  form,
  formErrors,
  diligenceQuestions,
  ownerFields,
  reuseDataChecked,
  handleChange,
  expanded,
  onClick,
  statusIcon,
  showVerticalBar,
  jurisdiction,
}) {
  const classes = useStyles();

  if (ownerFields.length === 0 || reuseDataChecked) {
    return null;
  }

  const minOwnerRequirements = buildMinOwnerRequirementAlerts(ownerFields);
  const showError = hasOwnerErrors(formErrors);
  const allOwners = ownerFields.reduce((acc, ownerField) => {
    const owners = form[ownerField.key] || [];

    return acc.concat(owners);
  }, []);
  const savedOwners = allOwners.filter((owner) =>
    diligenceQuestions.some((dq) => dq.label === owner.id),
  );

  return (
    <SubsectionDisplay
      label="Owners"
      expanded={expanded}
      onClick={onClick}
      statusIcon={statusIcon}
      showVerticalBar={showVerticalBar}
    >
      <div className={classes.contentRoot}>
        <div className={classes.instructionsContainer}>
          {showError ? (
            <Alert severity="error">
              You must provide the minimum required owners to complete this
              section.
            </Alert>
          ) : null}

          {Object.keys(minOwnerRequirements).length > 0 ? (
            <div className={classes.minRequirementsList}>
              {Object.entries(minOwnerRequirements).map(
                ([ownerKey, requirementStr]) => (
                  <Typography
                    key={ownerKey}
                    size="small"
                    color={
                      formErrors?.[ownerKey] ? 'error.text' : 'text.primary'
                    }
                  >
                    {requirementStr}
                  </Typography>
                ),
              )}
            </div>
          ) : (
            <Typography
              size="small"
              color={
                formErrors?.[ALL_OWNERS_KEY] ? 'error.text' : 'text.primary'
              }
            >
              You must provide all of the following for{' '}
              {form.name || 'this owner'}:
            </Typography>
          )}
        </div>

        <ol className={classes.allOwners}>
          {ownerFields.map((owner) => (
            <li key={owner.key} className={classes.nameList}>
              <NameList
                names={form[owner.key]}
                namesError={formErrors?.[owner.key]}
                diligenceQuestions={diligenceQuestions}
                onChange={(l) => {
                  handleChange(owner.key, l);
                }}
                jurisdiction={jurisdiction}
                formLabel={owner.pluralLabel}
                ownerType={owner.singularLabel}
                helpText={owner.helpText}
                namesToDisableEdit={savedOwners}
              />
            </li>
          ))}
        </ol>
      </div>
    </SubsectionDisplay>
  );
}
