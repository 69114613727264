import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Modal, Alert, Link } from '@passthrough/uikit';

import { EmptyState } from 'components/empty_v2';
import { Spinner } from 'components/spinner';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { ACCESS_LEVEL_REVIEW } from 'components/gp_selection_input/constants';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import * as urls from 'services/urls';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.text.secondary,
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  diligenceApproverSelection: {
    marginTop: theme.spacing(2),
  },
}));

export function MoveToClosingDialog({
  open,
  onChange,
  handleClose,
  investors,
  members,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { fundId, closingId } = useParams();
  const { successToast } = useToast();

  const [closings, setClosings] = useState(null);
  const [newClosingId, setNewClosingId] = useState(null);
  const [newClosingIdError, setNewClosingIdError] = useState(null);
  const [diligenceApproverId, setDiligenceApproverId] = useState(null);
  const [loading, setLoading] = useState(false);

  const moveableClosings = closings?.filter((c) => c.id !== closingId);

  const currentClosing = closings && closings.find((c) => c.id === closingId);
  const newClosing =
    moveableClosings && moveableClosings.find((c) => c.id === newClosingId);
  const willAddDiligence =
    newClosingId &&
    newClosing?.diligenceEnabled &&
    !currentClosing.diligenceEnabled;

  const shouldDisableMove =
    !newClosingId || (willAddDiligence && !diligenceApproverId);

  function onSetup() {
    api.closings({ fundId }).then((response) => {
      setClosings(response.data);
    });
  }

  useEffect(onSetup, [fundId]);

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const lpClosingIds = investors.map((i) => i.id);
    const count = lpClosingIds.length;
    const plural = count > 1 ? 's' : '';

    api
      .moveClosingDocs({
        fundId,
        closingId,
        lpClosingIds,
        newClosingId,
        diligenceApproverId,
      })
      .then(() => {
        successToast(`Moved ${count} investor${plural} to new closing`);
        onChange(newClosingId);
        handleClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setNewClosingIdError(error.response.data.newClosingId);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function dialogContent() {
    if (closings === null) {
      return <Spinner fullScreen />;
    }

    return (
      <>
        {moveableClosings.length > 0 ? (
          <>
            {willAddDiligence && (
              <Alert severity="info">
                Moving investors into {`${newClosing.name}`} will add diligence
                to their questionnaires.
              </Alert>
            )}

            <FormControl
              variant="outlined"
              fullWidth
              error={!!newClosingIdError}
            >
              <InputLabel id="closing-label">Closing</InputLabel>
              <Select
                label="Closing"
                labelId="closing-label"
                value={newClosingId}
                onChange={(e) => {
                  setNewClosingId(e.target.value);
                  setNewClosingIdError('');
                }}
              >
                {moveableClosings.map((closing) => (
                  <MenuItem key={closing.id} value={closing.id}>
                    {closing.name}
                  </MenuItem>
                ))}
              </Select>
              {newClosingIdError ? (
                <FormHelperText>{newClosingIdError}</FormHelperText>
              ) : null}

              <FormHelperText error={false}>
                Don't see the right closing here?{' '}
                <Link
                  variant="internal"
                  href={urls.closingsUrl({ fundId })}
                  inline
                >
                  Create a new closing ›
                </Link>
              </FormHelperText>

              {willAddDiligence ? (
                <GPSelectionInput
                  className={classes.diligenceApproverSelection}
                  label="Diligence reviewer"
                  value={diligenceApproverId}
                  setValue={setDiligenceApproverId}
                  members={members}
                  fundId={fundId}
                  fundPermissionLevel={ACCESS_LEVEL_REVIEW}
                />
              ) : null}
            </FormControl>
          </>
        ) : (
          <EmptyState
            title="No other closings"
            ctaText="Create a new closing"
            ctaOnClick={() => {
              history.push(urls.closingsUrl({ fundId }));
            }}
            ctaPermission
          />
        )}
      </>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      headerLabel="Move to new closing"
      subheaderLabel={`${investors.length} investor${
        investors.length > 1 ? 's' : ''
      } selected`}
      showCancelButton
      onExited={() => {
        setNewClosingId('');
        setNewClosingIdError('');
        setDiligenceApproverId(null);
      }}
      primaryButtonText="Move"
      primaryButtonLoading={loading}
      primaryButtonDisabled={shouldDisableMove}
      onSubmit={onSubmit}
    >
      {dialogContent()}
    </Modal>
  );
}
