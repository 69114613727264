import React, { useState } from 'react';
import { Button } from 'components/button';
import { makeStyles } from '@material-ui/core/styles';
import { TypographyWithLineBreaks } from 'components/typography_with_line_breaks';

const useStyles = makeStyles((theme) => ({
  interiorSpacing: {
    marginBottom: theme.spacing(1),
  },
}));

const NUM_CHARS_TO_DISPLAY_BEFORE_TRUNCATE = 150;
const NUM_LINES_TO_DISPLAY_BEFORE_TRUNCATE = 4;

function calculateNumCharsToDisplay(content) {
  const lines = content.split(/\n+/);
  const numCharsInFirstLines = lines
    .slice(0, NUM_LINES_TO_DISPLAY_BEFORE_TRUNCATE)
    .join('\n').length;
  return Math.min(numCharsInFirstLines, NUM_CHARS_TO_DISPLAY_BEFORE_TRUNCATE);
}

export function TruncatedTextDisplay({
  content,
  textColor = 'default',
  expandText = 'See full answer',
  contractText = 'See less',
  size = 'small',
  fontWeight,
}) {
  const numCharsToDisplay = calculateNumCharsToDisplay(content);
  const [showAll, setShowAll] = useState(false);
  const classes = useStyles();

  if (content.length <= numCharsToDisplay) {
    return (
      <TypographyWithLineBreaks
        variant="body"
        size={size}
        fontWeight={fontWeight}
      >
        {content}
      </TypographyWithLineBreaks>
    );
  }

  const truncatedText = `${content.substring(0, numCharsToDisplay)}...`;
  const textToDisplay = showAll ? content : truncatedText;
  const buttonText = showAll ? contractText : expandText;
  const buttonKey = showAll ? 'contract' : 'expand';

  return (
    <div>
      <div className={classes.interiorSpacing}>
        <TypographyWithLineBreaks
          variant="body"
          size={size}
          fontWeight={fontWeight}
        >
          {textToDisplay}
        </TypographyWithLineBreaks>
      </div>

      <Button
        data-testid="truncated_text_button"
        key={buttonKey}
        aria-label={buttonText}
        variant="text"
        color={textColor}
        onClick={(e) => {
          e.stopPropagation();
          setShowAll((prevShowState) => !prevShowState);
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
}
