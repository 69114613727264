import React from 'react';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  ripple: {
    display: 'inline-block',
    position: 'relative',
  },
}));

export const Ripple = ({ children, ripple }) => {
  const classes = useStyles();
  const rippleRef = React.useRef(null);

  React.useEffect(() => {
    if (ripple) {
      rippleRef.current.start();
      setTimeout(() => {
        rippleRef.current?.stop({});
      }, 100);
    }
  }, [ripple, rippleRef]);

  return (
    <div className={classes.ripple}>
      {children}
      <TouchRipple ref={rippleRef} center={false} />
    </div>
  );
};
