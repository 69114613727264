import React from 'react';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LockIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { Typography, useConfirm } from '@passthrough/uikit';
import { StepperThreadIcon } from 'components/stepper_thread_icon';

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: 'inherit',
    padding: theme.spacing(1, 2),
  },
  sectionLabel: {
    transition: '0.1s',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    color: theme.palette.primary.black,
  },
  selectedLabel: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  staticConnector: {
    flex: '0 0 auto',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    '$selectedLabel &': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

function getStepIcon(step) {
  if (step.blocked) {
    return <LockIcon />;
  }
  if (step.isConfirmed) {
    return <CheckCircleIcon />;
  }
  return <RadioButtonUncheckedIcon />;
}

export function StepNavigation({ nav, steps, showNavWarning, currentStep }) {
  const classes = useStyles();
  const confirm = useConfirm();
  const { useSupport } = useQuestionnaire();
  const { hasDraftComment } = useSupport();

  const navigate = (stepType, index) => {
    if (stepType === 'DILIGENCE') {
      nav.toDiligence(index);
    } else {
      nav.toSection(index);
    }
  };

  const { lpDoc, lpDiligence } = nav;

  return (
    <Stepper
      nonLinear
      activeStep={nav.outerStep}
      orientation="vertical"
      className={classes.stepper}
      connector={<StepConnector className={classes.staticConnector} />}
    >
      {lpDoc?.isCompleted ? (
        <Step key={lpDoc.id}>
          <StepButton
            icon={null}
            className={classes.sectionLabel}
            onClick={nav.showOverview}
            disabled
          >
            <div className={classes.labelContainer}>Subscription documents</div>
            <Typography variant="label">Completed</Typography>
          </StepButton>
        </Step>
      ) : null}
      {steps.map((step, index) => {
        const goToSection = () => {
          if (!nav.showOverview() && showNavWarning) {
            confirm({
              description:
                'You have not saved your changes. Are you sure you want to leave?',
              destructive: true,
            })
              .then(() => {
                navigate(step.type, index);
              })
              .catch(() => {});
          } else if (hasDraftComment) {
            confirm({
              description:
                'You have not saved your comment. Are you sure you want to leave?',
              destructive: true,
            })
              .then(() => {
                navigate(step.type, index);
              })
              .catch(() => {});
          } else {
            navigate(step.type, index);
          }
        };

        const isSelected = step === currentStep;
        const showThreadIcon = step.sectionHasComments;
        const showActiveThreadBadge = step.sectionHasActionableQuestion;

        return (
          <Step key={step.label}>
            <StepButton
              data-test="section"
              icon={getStepIcon(step)}
              className={clsx(classes.sectionLabel, {
                [classes.selectedLabel]: isSelected,
              })}
              completed={step.isConfirmed}
              onClick={goToSection}
            >
              <div className={classes.labelContainer}>
                {step.label}

                {showThreadIcon ? (
                  <StepperThreadIcon
                    isActiveThread={showActiveThreadBadge}
                    isSectionConfirmed={step.isConfirmed}
                    isSelected={isSelected}
                  />
                ) : null}
              </div>
            </StepButton>
          </Step>
        );
      })}
      {lpDiligence?.isCompleted ? (
        <Step key={lpDoc.id}>
          <StepButton
            icon={null}
            className={classes.sectionLabel}
            onClick={nav.showOverview}
            disabled
          >
            <div className={classes.labelContainer}>Diligence</div>
            <Typography variant="label">Completed</Typography>
          </StepButton>
        </Step>
      ) : null}
    </Stepper>
  );
}
