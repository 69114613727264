/* eslint-disable import/no-unused-modules */
export const DRAFT_STATUS = 'DRAFT';
export const UNVIEWED_STATUS = 'UNVIEWED';
export const VIEWED_STATUS = 'VIEWED';
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const REQUESTED_CHANGES_STATUS = 'REQUESTED_CHANGES';
export const PARTIALLY_SIGNED_STATUS = 'PARTIALLY_SIGNED';
export const SIGNED_STATUS = 'SIGNED';
export const APPROVED_PENDING_PREPARATION_STATUS =
  'APPROVED_PENDING_PREPARATION';
export const APPROVED_STATUS = 'APPROVED';
export const SENT_TO_COUNTERSIGNER_STATUS = 'SENT_TO_COUNTERSIGNER';
export const COUNTERSIGNED_STATUS = 'COUNTERSIGNED';
export const CLOSING_EMAIL_SENT_STATUS = 'CLOSING_EMAIL_SENT';
/* eslint-enable import/no-unused-modules */

export const canSendToInvestorStatuses = [DRAFT_STATUS];

export const multiDeletableStatuses = [
  DRAFT_STATUS,
  UNVIEWED_STATUS,
  VIEWED_STATUS,
  IN_PROGRESS_STATUS,
];

export const canRemindStatuses = [
  UNVIEWED_STATUS,
  VIEWED_STATUS,
  IN_PROGRESS_STATUS,
  PARTIALLY_SIGNED_STATUS,
  REQUESTED_CHANGES_STATUS,
];

export const canApproveStatus = [SIGNED_STATUS];

export const canUnapproveStatuses = [
  APPROVED_STATUS,
  SENT_TO_COUNTERSIGNER_STATUS,
  COUNTERSIGNED_STATUS,
  CLOSING_EMAIL_SENT_STATUS,
];

export const inProgressStatuses = [
  DRAFT_STATUS,
  UNVIEWED_STATUS,
  VIEWED_STATUS,
  IN_PROGRESS_STATUS,
  REQUESTED_CHANGES_STATUS,
];

export const notStartedStatuses = [
  DRAFT_STATUS,
  UNVIEWED_STATUS,
  VIEWED_STATUS,
];

export const signedStatuses = [SIGNED_STATUS, PARTIALLY_SIGNED_STATUS];

export const canSendToCountersignerStatuses = [APPROVED_STATUS];

export const canMarkAsFullyExecutedStatuses = [APPROVED_STATUS];

export const canResendClosingEmailStatuses = [COUNTERSIGNED_STATUS];

export const canUndoRequestChangesStatuses = [REQUESTED_CHANGES_STATUS];

export const canPrepareSignaturePagesStatuses = [
  SIGNED_STATUS,
  APPROVED_STATUS,
];

export const STANDARD_STATUSES = [
  'DRAFT',
  'VIEWED',
  'UNVIEWED',
  'IN_PROGRESS',
  'REQUESTED_CHANGES',
  'PARTIALLY_SIGNED',
  'SIGNED',
  'APPROVED_PENDING_PREPARATION',
  'APPROVED',
  'SENT_TO_COUNTERSIGNER',
  'COUNTERSIGNED',
  'CLOSING_EMAIL_SENT',
];
