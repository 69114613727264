import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { Modal, Typography } from '@passthrough/uikit';
import { ClosingDateField } from 'components/closing_date_field';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals, formatDate } from 'services/utils';

import { getClosingTypes } from '../utils';

const genFormattedDate = (rawDate) => {
  try {
    return formatDate(rawDate);
  } catch (error) {
    return '';
  }
};

export function GeneralSettingsModal({
  open,
  onClose,
  isDiligenceEnabled,
  hasSubdoc,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  const resetState = () => {
    setForm(initialState);
    setErrors({});
  };

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit general settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={resetState}
      onExited={resetState}
      onSubmit={() => updateSettings(form)}
    >
      <div>
        <Typography variant="body" size="small">
          Closing type
        </Typography>

        <Typography variant="body" size="medium">
          {getClosingTypes(isDiligenceEnabled, hasSubdoc).join(', ')}
        </Typography>
      </div>

      <TextField
        id="name"
        variant="outlined"
        label="Closing name"
        type="text"
        value={form.name}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
      />

      <ClosingDateField
        required={false}
        closingDate={form.omitClosingDate ? null : form.closingDate}
        setClosingDate={(closingDate) =>
          setForm({ ...form, closingDate: genFormattedDate(closingDate) })
        }
        closingDateError={errors.closingDate}
        omitClosingDate={form.omitClosingDate}
        setOmitClosingDate={(omitClosingDate) =>
          setForm({ ...form, omitClosingDate })
        }
      />

      <TextField
        id="completion_message"
        variant="outlined"
        label="Completion message (optional)"
        type="text"
        value={form.subdocCompleteText || ''}
        onChange={(e) =>
          setForm({ ...form, subdocCompleteText: e.target.value })
        }
        error={!!errors.subdocCompleteText}
        helperText={
          errors.subdocCompleteText ||
          'Include a message after investors complete the questionnaire.'
        }
        fullWidth
        multiline
        minRows={4}
      />
    </Modal>
  );
}
