import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';

import { PageContainer } from 'components/page_container';
import { useFundEdit, useFund, useMembers } from 'services/providers/fund';
import { useCurrency } from 'services/providers/currency';
import * as api from 'services/api';
import { ClosingList } from './closing_list';
import { NewClosingDialog } from './modals/new_closing';

export function ClosingsPage() {
  const { fundId, closingType } = useParams();
  const [closings, setClosings] = useState(null);
  const [fund, isLoading] = useFund();
  const [closingDialogOpen, setClosingDialogOpen] = useState(false);
  const [fundEdit] = useFundEdit();
  const [members, isLoadingMembers, refreshMembers] = useMembers();
  const currency = useCurrency();

  function getClosings() {
    api.closings({ fundId, closingType }).then((response) => {
      setClosings(response.data);
    });
  }

  useEffect(getClosings, [fundId, closingType]);
  useEffect(refreshMembers, []);

  if (closings === null || isLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <PageContainer maxWidth="md">
      {closings.length === 0 ? (
        <EmptyState
          title="No fund closings"
          ctaText="New fund closing"
          ctaOnClick={() => {
            setClosingDialogOpen(true);
          }}
          ctaPermission={fundEdit}
        />
      ) : (
        <ClosingList
          closings={closings}
          openNewDialog={() => {
            setClosingDialogOpen(true);
          }}
          currency={currency}
        />
      )}
      <NewClosingDialog
        managedDiligenceEnabled={fund?.managedDiligenceEnabled}
        fundId={fundId}
        open={closingDialogOpen}
        onClose={() => setClosingDialogOpen(false)}
        members={members}
        isLoadingMembers={isLoadingMembers}
      />
    </PageContainer>
  );
}
