import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  useConfirm,
  Button,
  Menu,
  MenuItem,
  Icons,
  Typography,
} from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';

import * as api from 'services/api';
import { useFundEdit } from 'services/providers/fund';

const useStyles = makeStyles(() => ({
  flexGrow: {
    flexGrow: 1,
  },
}));

export function DiligenceActionsMenu({
  isDiligenceOnly,
  investorName,
  canWaiveDiligence,
  diligenceId,
  onSkip,
  onWaive,
}) {
  const classes = useStyles();
  const { fundId, lpClosingId } = useParams();
  const [moreButtonEl, setMoreButtonEl] = React.useState(null);
  const confirm = useConfirm();
  const [fundEdit] = useFundEdit();

  function handleWaiveDiligence() {
    confirm({
      title: `Make diligence optional for ${investorName}?`,
      description:
        'This investor will be allowed to submit incomplete diligence information.',
      confirmationText: 'Make optional',
    }).then(() => {
      api.waiveDiligence({ fundId, lpClosingId, diligenceId }).then(() => {
        onWaive();
      });
    });
  }

  return (
    <>
      <div className={classes.flexGrow} />
      <Button
        variant="icon"
        onClick={(ev) =>
          setMoreButtonEl((currentEl) => (currentEl ? null : ev.currentTarget))
        }
        aria-label="More options"
      >
        <Icons.MoreVert />
      </Button>

      <Menu
        anchorEl={moreButtonEl}
        open={Boolean(moreButtonEl)}
        onClose={() => setMoreButtonEl(null)}
      >
        {canWaiveDiligence ? (
          <MenuItem
            text="Make diligence optional"
            subtext="Allow partial submission from investor"
            icon={<Icons.ClearAll color="primary" />}
            onClick={handleWaiveDiligence}
          />
        ) : null}

        {fundEdit && !isDiligenceOnly ? (
          <MenuItem
            text="Delete diligence"
            destructive
            icon={<Icons.DeleteOutline />}
            onClick={() => {
              confirm({
                title: `Delete diligence for ${investorName}`,
                description: (
                  <Typography>
                    The diligence section will be removed from the investor's
                    questionnaire and any information submitted by the investor
                    will be deleted.
                    <br />
                    <br />
                    This action cannot be undone.
                  </Typography>
                ),
                confirmationText: 'Delete diligence',
                destructive: true,
                primaryButtonProps: {
                  variant: 'primary',
                },
              })
                .then(() => {
                  api
                    .skipDiligence({ fundId, lpClosingId, diligenceId })
                    .then(() => {
                      onSkip();
                    });
                })
                .catch(() => {
                  // do nothing
                });
            }}
          />
        ) : null}
      </Menu>
    </>
  );
}
