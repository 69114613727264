import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useWhiteLabelConfig } from 'services/providers/theme';
import { DatePicker } from 'components/date';

export function ClosingDateField({
  closingDate,
  setClosingDate,
  closingDateError,
  omitClosingDate,
  setOmitClosingDate,
  required = true,
}) {
  const { productName } = useWhiteLabelConfig();
  const helperText =
    'We recommend keeping the closing date current for ' +
    `${productName} to better support your fundraise. This is not visible to ` +
    'investors.';

  return (
    <FormControl>
      <DatePicker
        fullWidth
        date={closingDate}
        required={!omitClosingDate && required}
        label="Closing date"
        setDate={setClosingDate}
        error={!!closingDateError}
        helperText={closingDateError || helperText}
        disabled={omitClosingDate}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={omitClosingDate}
            onChange={(e) => setOmitClosingDate(e.target.checked)}
            data-test="no-closing-date"
          />
        }
        label="No closing date"
      />
    </FormControl>
  );
}
