import React from 'react';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { Icons } from '@passthrough/uikit';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import { TypographyWithLineBreaks } from 'components/typography_with_line_breaks';
import { Button } from 'components/button';

import { ReviewInvestments } from './review_investments';

const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  message: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.neutral.fill,
  },
}));

export const ReviewCollaborator = ({
  form,
  profileInvestments,
  loading,
  onConfirm,
  isProfileOwner,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Typography paragraph>
        New collaborator will be notified by email.
      </Typography>
      <KeyValuePairs>
        <KeyValuePair icon={<PersonIcon />}>{form.name}</KeyValuePair>
        <KeyValuePair icon={<EmailIcon />}>{form.email}</KeyValuePair>
        <KeyValuePair icon={<MessageIcon />}>
          {form.message ? (
            <Paper elevation={0} className={classes.message}>
              <TypographyWithLineBreaks variant="body" size="small">
                {form.message}
              </TypographyWithLineBreaks>
            </Paper>
          ) : (
            'No message'
          )}
        </KeyValuePair>
        {isProfileOwner ? (
          <KeyValuePair icon={<Icons.ManageAccountsOutlined />}>
            {form.fullAccess ? (
              <>
                <Typography>Full access</Typography>
                <Typography variant="body2">
                  They can add and remove people from the profile, view and edit
                  profile data, manage all of this profile's investments.
                </Typography>
              </>
            ) : (
              <>
                <Typography>Only this investment</Typography>
                <Typography variant="body2">
                  They can add and remove people, view and edit information for
                  only this investment.
                </Typography>
              </>
            )}
          </KeyValuePair>
        ) : null}
      </KeyValuePairs>
      {form.fullAccess && profileInvestments?.length ? (
        <ReviewInvestments investments={profileInvestments} />
      ) : null}
      <div className={classes.action}>
        <Button
          type="submit"
          onClick={onConfirm}
          variant="contained"
          color="primary"
          fullWidth
          loading={loading}
        >
          Invite
        </Button>
      </div>
    </div>
  );
};
