import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { HiddenArrowButton } from 'components/hidden_arrow_button';
import { StatusChip } from 'components/status';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import { EmptyState } from 'components/empty_v2';
import { AdapterLink } from 'components/link';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { Typography } from '@passthrough/uikit';
import { People } from './people';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  closing: {
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  learnMore: {
    width: 'max-content',
  },
  alert: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  inlineCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    margin: theme.spacing(2, 0),
  },
  inline: {
    display: 'flex',
  },
}));

export function InvestorPage() {
  const classes = useStyles();
  const { fundId, investorId } = useParams();
  const [showEditName, setShowEditName] = useState(false);
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState('');
  const [investor, setInvestor] = useState(null);
  const [change, setChange] = useState(0);
  const { toast } = useToast();

  function getInvestor() {
    api.investor({ fundId, investorId }).then((response) => {
      setInvestor(response.data);
      setName(response.data.name);
    });
  }

  useEffect(getInvestor, [change]);

  if (investor === null) {
    return <Spinner fullScreen />;
  }

  function toUrl(lpClosing) {
    return urls.reviewUrl({
      fundId: lpClosing.fundId,
      closingId: lpClosing.closingId,
      lpClosingId: lpClosing.id,
    });
  }

  function onSubmitName(e) {
    e.preventDefault();

    api
      .investorSaveName({
        fundId: investor.fundId,
        lpId: investor.id,
        name,
      })
      .then(() => {
        setNameError('');
        setInvestor((i) => ({ ...i, name }));
        toast(`Renamed investor to ${name}`);
        setShowEditName(false);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setNameError(error.response.data.name);
        }
      });
  }

  return (
    <PageContainer maxWidth="md">
      <span className={classes.inlineCenter}>
        {showEditName ? (
          <form
            onSubmit={onSubmitName}
            autoComplete="off"
            className={classes.inlineCenter}
          >
            <TextField
              autoFocus
              required
              id="name"
              variant="outlined"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!nameError}
              helperText={nameError}
            />
            <IconButton aria-label="Save name" color="primary" type="submit">
              <DoneIcon />
            </IconButton>
            <IconButton
              aria-label="Cancel edit name"
              onClick={() => {
                setName(investor.name);
                setShowEditName(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </form>
        ) : (
          <>
            <Typography variant="section-heading">{investor.name}</Typography>
            <IconButton
              aria-label="Edit name"
              onClick={() => {
                setShowEditName(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        )}
      </span>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <Paper className={classes.paper} variant="outlined">
            <Typography variant="section-heading">Closings</Typography>
            {investor.closings.length === 0 ? (
              <EmptyState title="No closings" />
            ) : (
              <List component="nav">
                {investor.closings.map((lpClosing) => (
                  <HiddenArrowButton
                    square
                    variant="text"
                    color="primary"
                    className={classes.closing}
                    key={lpClosing.id}
                    component={AdapterLink}
                    to={toUrl(lpClosing)}
                  >
                    <ListItem>
                      <ListItemText
                        primary={`${lpClosing.fundName} ${lpClosing.closingName}`}
                        secondary={
                          <StatusChip
                            status={lpClosing.status}
                            lastSentEvent={lpClosing.lastSentEvent}
                            waitingOnFirstSigner={
                              lpClosing.waitingOnFirstSigner
                            }
                          />
                        }
                      />
                    </ListItem>
                  </HiddenArrowButton>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper className={classes.paper} variant="outlined">
            <People
              investor={investor}
              onChange={() => {
                setChange((c) => c + 1);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
