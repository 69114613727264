import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { Typography, Button, Icons } from '@passthrough/uikit';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NumberFormat from 'react-number-format';
import { useCurrency } from 'services/providers/currency';
import TextField from '@material-ui/core/TextField';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import cloneDeep from 'lodash/cloneDeep';

import * as api from 'services/api';
import { CurrencyTextField } from 'components/currency_text_field';
import { Sheet } from 'components/sheet';
import { useToast } from 'services/toast';
import { Spinner } from 'components/spinner';
import { BulkCountersignPdfReviewModal } from './bulk_countersign_pdf_review_modal';
import {
  COLUMN_MAPPING,
  COLUMN_NAME_OVERRIDE,
  SUBSCRIPTION_DOCUMENT,
} from './constants';
import { collectLpDocumentIdsFromSubdocs } from './helpers';

const ESCAPE_KEY = 27;
const MIN_WIDTH = 120;

/*
[
    {
        "id": "[UUID]",
        "gpFields": [
            {
                "uuid": str [UUID]
                "label": str [label id],
                "type": str (checkbox / text),
                "name": str [Optional question name],
                "lpdocPage": int,
                "envelopePage": null,
                "signer": str [Countersigner/Countersigner2/Countersigner3],
                "answer": str | boolean | null,
                "placedByUser": false,
                "x": int,
                "y": int,
                "width": int,
                "height": int,
                "readOnly": bool (false / true)
            },
            ...
        ]
    },
    ...
]
*/

const TRUE_VALUES = ['TRUE', true];
const FALSE_VALUES = ['FALSE', false];
const NULL_VALUES = ['', null];

const cleanInput = (value) => {
  const cleanedValue = value.replace(/[^\d.]/g, '');
  const firstDecimalIndex = cleanedValue.indexOf('.');
  if (firstDecimalIndex === -1) {
    return cleanedValue;
  }
  const beforeDecimal = cleanedValue.slice(0, firstDecimalIndex);
  const afterDecimal = cleanedValue
    .slice(firstDecimalIndex + 1)
    .replace(/\./g, '')
    .slice(0, 2);
  return `${beforeDecimal}.${afterDecimal}`;
};

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  rightActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  formControl: {
    display: 'inline',
  },
}));

const getWritableColumns = (closingSubDoc, investorGpData) => {
  const closingSubDocFields = closingSubDoc?.allGpFields || [];
  const allGpFields = (investorGpData || []).reduce(
    (acc, gpData) => {
      gpData.gpFields.forEach((gpField) => {
        if (!acc.find((allFields) => allFields.label === gpField.label)) {
          acc.push(gpField);
        }
      });
      acc.concat(gpData.gpFields);
      return acc;
    },
    [...closingSubDocFields],
  );
  const columns = allGpFields.map((gpField) => ({
    label: gpField.name || 'Accepted commitment',
    type: gpField.type,
    apiId: gpField.label,
    lpdocPage: gpField.lpdocPage,
    required: gpField.required,
  }));
  const index = columns.findIndex(
    (column) => column.type === 'accepted_commitment',
  );
  if (index !== -1) {
    return [
      columns[index],
      ...columns.slice(0, index),
      ...columns.slice(index + 1),
    ];
  }
  return columns;
};

const getLpDocFromInvestor = (investor, docIdToInvestorGpData) => {
  const subDocId = investor.docs.find(
    (d) => d.type === SUBSCRIPTION_DOCUMENT,
  ).id;
  const lpDoc = docIdToInvestorGpData[subDocId];
  return lpDoc;
};

function getInitialGrid(investors, docIdToInvestorGpData, otherColumns) {
  return investors.map((row) => {
    const readOnlyColumns = Object.values(COLUMN_MAPPING).map(
      (lpAttribute) => ({
        readOnly: true,
        bulkCountersignReadOnlyStyle: true,
        value: row[lpAttribute],
      }),
    );
    const lpDocWithGpFields = getLpDocFromInvestor(row, docIdToInvestorGpData);
    const writableColumns = otherColumns.map((column) => {
      const gpField = lpDocWithGpFields.gpFields.find(
        (g) => g.label === column.apiId,
      );
      return {
        error: '',
        value: gpField?.answer,
        readOnly: gpField ? gpField.readOnly : true,
        bulkCountersignReadOnlyStyle: gpField ? gpField.readOnly : true,
      };
    });
    return readOnlyColumns.concat(writableColumns);
  });
}

function makeDataEditor(columns, currency) {
  return ({ cell, value, onChange, onKeyDown, col }) => {
    // Pressing Esc while editing a cell in the data sheet stops editing the
    // cell and reverts it to its previous value.
    // Prevent Esc from also attempting to close the modal.
    const preventEscapeDefaultKeyDown = (e) => {
      onKeyDown(e);
      const keyCode = e.which || e.keyCode;
      if (keyCode === ESCAPE_KEY) {
        e.stopPropagation();
      }
    };
    const inputProps = {
      autoFocus: true,
      variant: 'standard',
      value,
      onKeyDown: preventEscapeDefaultKeyDown,
      onChange: (e) => onChange(e.target.value),
      error: !!cell.error,
      helperText: cell.error,
      fullWidth: true,
      multiline: true,
    };

    const column = columns[col];
    switch (column.type) {
      case 'accepted_commitment': {
        const { symbol } = currency;
        return (
          <CurrencyTextField
            {...inputProps}
            currencySymbol={symbol}
            type="text"
          />
        );
      }
      case 'checkbox': {
        const classes = useStyles();
        return (
          <FormControl
            className={classes.formControl}
            required
            error={!!cell.error}
          >
            <Checkbox
              autoFocus
              onKeyDown={preventEscapeDefaultKeyDown}
              checked={value}
              type="checkbox"
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
            <FormHelperText>{cell.error}</FormHelperText>
          </FormControl>
        );
      }
      default:
        return <TextField {...inputProps} type="text" />;
    }
  };
}

const useValueStyles = makeStyles(() => ({
  currency: {
    textAlign: 'right',
    '&::before': {
      content: (props) => `"${props.currencyDisplay}"`,
      float: 'left',
    },
  },
}));

function makeValueViewer(columns, grid, setGrid, currency, setDataEntryDirty) {
  const getComponent = (column, value, cell, row, col) => {
    let columnType = column.type;
    if (columnType === 'accepted_commitment' || col === 2) {
      columnType = 'commitment';
    }
    switch (columnType) {
      case 'commitment': {
        const { symbol } = currency;
        const classes = useValueStyles({ currencyDisplay: symbol });
        const numberHasDecimal =
          !Number.isNaN(Number(value)) && Number(value) % 1 !== 0;
        return value ? (
          <div className={classes.currency}>
            <Typography variant="body">
              <NumberFormat
                thousandsGroupStyle="thousand"
                decimalSeparator="."
                displayType="text"
                type="text"
                thousandSeparator
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={numberHasDecimal}
                value={value}
              />
            </Typography>
          </div>
        ) : (
          <Typography variant="body">{value}</Typography>
        );
      }
      case 'checkbox':
        return (
          <Checkbox
            disabled={cell.readOnly}
            checked={value === '' ? false : value}
            onChange={(e) => {
              const newGrid = grid.map((r) => [...r]);
              newGrid[row][col] = {
                ...newGrid[row][col],
                value: e.target.checked,
                error: '',
              };
              setGrid(newGrid);
              setDataEntryDirty(true);
            }}
          />
        );
      default:
        return <Typography variant="body">{value}</Typography>;
    }
  };
  return ({ value, row, col, cell }) => {
    const column = columns[col];
    const component = getComponent(column, value, cell, row, col);
    const classes = useStyles();
    return (
      <FormControl
        className={classes.formControl}
        required
        error={!!cell.error}
        fullWidth={column.type === 'acceptedCommitment'}
      >
        {component}
        <FormHelperText>{cell.error}</FormHelperText>
      </FormControl>
    );
  };
}

function getReadOnlyColumnForSheet(currency) {
  function inner(columnKey) {
    let label = columnKey;
    if (columnKey === 'Original commitment') {
      const { code } = currency;
      label = `Original commitment (${code})`;
    }
    return {
      label,
      cellStyle: { minWidth: MIN_WIDTH },
    };
  }
  return inner;
}

function getWritableColumnForSheet(
  currency,
  closingSubDoc,
  setReviewPage,
  setReviewApiId,
  setIsReviewModalOpen,
) {
  function inner(column) {
    const isCurrentColumn = closingSubDoc?.allGpFields?.find(
      (gpField) => gpField.label === column.apiId,
    );
    const onClick = isCurrentColumn
      ? () => {
          setReviewPage(column.lpdocPage);
          setReviewApiId(column.apiId);
          setIsReviewModalOpen(true);
        }
      : () => {};
    const ariaLabel = isCurrentColumn
      ? 'Preview in document'
      : 'Not in current document';
    const cellAction = {
      button: {
        color: 'primary',
        'aria-label': ariaLabel,
        onClick,
        disabled: !isCurrentColumn,
      },
      icon: <FindInPageOutlinedIcon />,
    };
    const optionalText = column.required ? '' : ' (Optional)';
    switch (column.type) {
      case 'accepted_commitment': {
        const { code } = currency;
        return {
          label: `${column.label} (${code})${optionalText}`,
          type: column.type,
          cellStyle: { minWidth: MIN_WIDTH },
          cellAction,
        };
      }
      default: {
        const label = COLUMN_NAME_OVERRIDE[column.label] || column.label;
        return {
          label: `${label}${optionalText}`,
          type: column.type,
          cellStyle: { minWidth: MIN_WIDTH },
          cellAction,
        };
      }
    }
  }
  return inner;
}

function constructUpdateGpFieldsRequest(
  grid,
  investors,
  docIdToInvestorGpData,
  writableColumns,
) {
  const updateLpDocsGpFieldsRequest = [];
  for (let row = 0; row < grid.length; row += 1) {
    const gridRow = grid[row];
    const columnApiIdToAnswer = {};
    gridRow.slice(Object.keys(COLUMN_MAPPING).length).forEach((cell, index) => {
      columnApiIdToAnswer[writableColumns[index].apiId] = cell.value;
    });
    const lpDoc = getLpDocFromInvestor(investors[row], docIdToInvestorGpData);
    const gpFields = lpDoc.gpFields
      .filter((gpField) => !gpField.readOnly)
      .map(({ readOnly, ...gpField }) => ({
        ...gpField,
        answer: columnApiIdToAnswer[gpField.label],
      }));
    const lpDocGpFields = {
      id: lpDoc.id,
      gpFields,
    };
    updateLpDocsGpFieldsRequest.push(lpDocGpFields);
  }
  return updateLpDocsGpFieldsRequest;
}

export function DataEntryStep({
  investors,
  onContinue,
  onCloseWithoutConfirmation,
  setDataEntryUpdated,
  setDataEntryDirty,
  closingSubDoc,
  onBack,
  sectionHeading,
  setErrorMsgs,
}) {
  const classes = useStyles();

  const { toast } = useToast();
  const { fundId, closingId } = useParams();
  const currency = useCurrency();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewPage, setReviewPage] = useState(0);
  const [reviewApiId, setReviewApiId] = useState(null);
  const [dataEntryGrid, setDataEntryGrid] = useState(null);
  const [investorGpData, setInvestorGpData] = useState(null);

  useEffect(() => {
    const lpDocumentIds = collectLpDocumentIdsFromSubdocs(investors);
    api
      .bulkCountersignGpFieldsList({ fundId, closingId, lpDocumentIds })
      .then((response) => {
        setInvestorGpData(response.data);
      }); // TODO: Catch error
  }, []);

  const writableColumns = getWritableColumns(closingSubDoc, investorGpData);

  const docIdToInvestorGpData = {};
  investorGpData?.forEach((gpData) => {
    docIdToInvestorGpData[gpData.id] = gpData;
  });

  useEffect(() => {
    if (investorGpData && closingSubDoc && !dataEntryGrid) {
      setDataEntryGrid(
        getInitialGrid(investors, docIdToInvestorGpData, writableColumns),
      );
    }
  }, [investorGpData, closingSubDoc]);

  const sheetColumns = Object.keys(COLUMN_MAPPING)
    .map(getReadOnlyColumnForSheet(currency))
    .concat(
      writableColumns.map(
        getWritableColumnForSheet(
          currency,
          closingSubDoc,
          setReviewPage,
          setReviewApiId,
          setIsReviewModalOpen,
        ),
      ),
    );

  function getCellValue({ col, value }) {
    const column = sheetColumns[col];
    switch (column.type) {
      case 'checkbox':
        if (TRUE_VALUES.includes(value)) {
          return { value: true };
        }
        if (FALSE_VALUES.includes(value)) {
          return { value: false };
        }
        if (NULL_VALUES.includes(value)) {
          return { value: null };
        }
        return { value: null, error: `Invalid value: ${value}` };
      // TODO: Numbers are weird, what's the best way to do this?
      // Also fix the new_investor_closing_dialog to behave the same way eventually
      case 'accepted_commitment': {
        return { value: cleanInput(value) };
      }
      default:
        return { value };
    }
  }

  function handleSave({ skipValidation, onSuccess }) {
    setIsSubmitting(true);
    const lpDocuments = constructUpdateGpFieldsRequest(
      dataEntryGrid,
      investors,
      docIdToInvestorGpData,
      writableColumns,
    );
    api
      .bulkCountersignGpFieldsUpdate({
        fundId,
        closingId,
        skipValidation,
        lpDocuments,
      })
      .then(() => {
        setErrorMsgs('');
        setDataEntryDirty(false);
        setDataEntryUpdated(true);
        toast('Document variables saved.');
        onSuccess();
      })
      .catch((error) => {
        const allNonFieldErrors = [];
        if (error.response?.status === 400) {
          const errors = error.response.data;
          const newGrid = cloneDeep(dataEntryGrid);
          for (let row = 0; row < newGrid.length; row += 1) {
            const { nonFieldErrors, ...rowErrors } = errors[row];
            if (Object.keys(rowErrors).length > 0) {
              for (let col = 3; col < newGrid[0].length; col += 1) {
                const { apiId } = writableColumns[col - 3];
                const cell = newGrid[row][col];
                cell.error = rowErrors[apiId];
              }
            }
            if (nonFieldErrors) {
              allNonFieldErrors.push(...nonFieldErrors);
            }
          }
          setDataEntryGrid(newGrid);
          setErrorMsgs(allNonFieldErrors);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const dataEditor = makeDataEditor(sheetColumns, currency);
  const valueViewer = makeValueViewer(
    sheetColumns,
    dataEntryGrid,
    setDataEntryGrid,
    currency,
    setDataEntryDirty,
  );

  const initialDataLoading = !investorGpData || !dataEntryGrid;
  return (
    <>
      {initialDataLoading ? (
        <Spinner height={200} />
      ) : (
        <>
          {sectionHeading}
          <Sheet
            grid={dataEntryGrid}
            setGrid={setDataEntryGrid}
            columns={sheetColumns}
            dataEditor={dataEditor}
            valueViewer={valueViewer}
            fixedRowSize
            getCellValue={getCellValue}
            stickyTable
            onCellsChangedCallback={() => setDataEntryDirty(true)}
            noTopMargin
          />
          <div className={classes.actionContainer}>
            <Button
              variant="text"
              size="large"
              onClick={onBack}
              startIcon={<Icons.KeyboardArrowLeft />}
            >
              Back
            </Button>
            <div className={classes.rightActions}>
              <Button
                loading={isSubmitting}
                variant="secondary"
                onClick={() =>
                  handleSave({
                    skipValidation: true,
                    onSuccess: onCloseWithoutConfirmation,
                  })
                }
                size="large"
                data-test="save-and-close"
              >
                Save and close
              </Button>
              <Button
                loading={isSubmitting}
                variant="primary"
                onClick={() =>
                  handleSave({
                    onSuccess: onContinue,
                  })
                }
                size="large"
                data-test="data-entry-continue"
              >
                Save and continue
              </Button>
            </div>
          </div>
          <BulkCountersignPdfReviewModal
            open={isReviewModalOpen}
            handleClose={() => setIsReviewModalOpen(false)}
            closingSubDoc={closingSubDoc}
            page={reviewPage}
            apiId={reviewApiId}
          />
        </>
      )}
    </>
  );
}
