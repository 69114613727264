import React, { useState } from 'react';
import { Button } from 'components/button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ProfileSwitchRadioForm } from './profile_switch_radio_form';
import { DialogStep } from '../dialog_step';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
  continueButton: {
    margin: theme.spacing(2, 0),
  },
}));

export function StepSwitchProfile({
  activeProfile,
  selectedProfile,
  isNewInvestor,
  setSetupProfileId,
  setActiveProfile,
  setRestarting,
  setStep,
  onClose,
}) {
  const classes = useStyles();
  const [existingAnswersChoice, setExistingAnswersChoice] = useState('');

  const currentName = activeProfile.displayName;
  const updatedName = isNewInvestor
    ? 'New profile'
    : selectedProfile.displayName;

  const goBack = () => {
    setStep(0);
  };

  const onSubmit = () => {
    if (selectedProfile === 'new') {
      setSetupProfileId('new');
    } else {
      setSetupProfileId(selectedProfile.id);
    }
    setActiveProfile(null);
    setRestarting(existingAnswersChoice === 'restart');
  };

  return (
    <DialogStep
      title={
        <div>
          <Typography variant="h5" className={classes.title}>
            Change profile
          </Typography>
          <Typography variant="subtitle2">
            {`Change from ${currentName} to ${updatedName}`}
          </Typography>
        </div>
      }
      onBack={goBack}
      onClose={onClose}
    >
      <ProfileSwitchRadioForm
        profileDisplayName={isNewInvestor ? null : selectedProfile.displayName}
        existingAnswersChoice={existingAnswersChoice}
        setExistingAnswersChoice={setExistingAnswersChoice}
      />
      <Button
        type="submit"
        disabled={!existingAnswersChoice}
        onClick={onSubmit}
        variant="contained"
        color="primary"
        className={classes.continueButton}
        fullWidth
      >
        Continue
      </Button>
    </DialogStep>
  );
}
