import React, { useState } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Typography, Modal, useConfirm, Alert } from '@passthrough/uikit';

import { objectEquals } from 'services/utils';

import { DiligenceDetailInputs } from 'components/lp_doc/diligence/subsection_question_display/details_subsection';
import {
  hasAdditionalDataBeenProvided,
  buildInitialForm,
  getTypeDetailKeysToDisplay,
  filterEmptyDataFields,
} from 'components/lp_doc/diligence/utils';
import { jurisdictions } from 'components/lp_doc/diligence/constants';
import { ProcessedDiligenceAnswerDisplay } from '../owner_data_approval/submitted_data_display';
import { getChildrenSubTree } from './utils';

const EDIT_STAGE = 0;
const REVIEW_STAGE = 1;

function ReviewStageContent({
  label,
  hasMatches,
  structuredNodesRoot,
  jurisdiction,
  isRootNode,
  newAnswers,
  initialAnswers,
}) {
  const nonemptyOldAnswers = filterEmptyDataFields(initialAnswers);

  // if type was changed we should filter out default empty values
  // for new fields so that we can properly display the
  // 'not provided' chip for missing values
  const nonemptyNewAnswers = filterEmptyDataFields(newAnswers);

  const detailKeys = getTypeDetailKeysToDisplay(
    newAnswers.type,
    jurisdiction,
    isRootNode,
  );

  const wasNameChanged = newAnswers.name !== initialAnswers.name;
  const wasTypeChanged = newAnswers.type !== initialAnswers.type;
  const wasDOBChanged = newAnswers?.dob !== initialAnswers?.dob;

  const changesWillTriggerSearch =
    wasNameChanged || wasTypeChanged || wasDOBChanged;

  const childrenData = getChildrenSubTree(label, structuredNodesRoot);

  return (
    <>
      {wasTypeChanged && childrenData?.length > 0 ? (
        <Alert severity="warning" skipTypography>
          Changing the owner type will remove the following owner(s):
          <ul>
            {childrenData.map((childData) => (
              <li key={childData.id}>
                {childData.name} ({childData.type} of {childData.parentName})
              </li>
            ))}
          </ul>
        </Alert>
      ) : null}

      {changesWillTriggerSearch ? (
        <>
          {hasMatches ? (
            <Alert severity="warning">
              You will lose your review progress for the current list of
              potential matches. This action cannot be undone.
            </Alert>
          ) : null}

          <Typography variant="body">
            Owner details will be resubmitted to generate a new list of
            potential matches.
          </Typography>
        </>
      ) : null}

      {detailKeys.map((key) => (
        <ProcessedDiligenceAnswerDisplay
          key={key}
          answerKey={key}
          jurisdiction={jurisdiction}
          showDiff={!wasTypeChanged}
          answerData={nonemptyNewAnswers}
          lastSubmittedAnswer={nonemptyOldAnswers}
          hideNameChangeBtn
          handleOpenGpEditsModal={() => {}}
        />
      ))}

      <Typography variant="body">
        Your changes are visible to the investor but they will not be notified.
      </Typography>
    </>
  );
}

export function EditDiligenceDetailsModal({
  label,
  open,
  structuredNodesRoot,
  initialAnswers,
  jurisdiction,
  isRootNode,
  onClose,
  hasMatches,
  onValidateData,
  onSubmit,
}) {
  const [stage, setStage] = useState(EDIT_STAGE);
  const [localAnswers, setLocalAnswers] = useState(initialAnswers);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const confirm = useConfirm();

  const haveAnswersChanged = !objectEquals(initialAnswers, localAnswers);

  const shouldDisableNextBtn =
    stage === EDIT_STAGE &&
    (!haveAnswersChanged || !localAnswers.name || !localAnswers.type);

  const wasNameChanged = localAnswers.name !== initialAnswers.name;
  const wasTypeChanged = localAnswers.type !== initialAnswers.type;
  const wasDOBChanged = localAnswers?.dob !== initialAnswers?.dob;

  const changesWillTriggerSearch =
    wasNameChanged || wasTypeChanged || wasDOBChanged;

  function getBtnText() {
    if (stage === EDIT_STAGE) {
      return 'Next';
    }

    return changesWillTriggerSearch ? 'Save and resubmit' : 'Save';
  }

  function handleTypeChange(e) {
    const answerKeysToIgnore = ['name', 'type', 'useSsn'];
    const shouldDisplayTypeChangeConfirmation = hasAdditionalDataBeenProvided(
      localAnswers,
      answerKeysToIgnore,
    );

    const newType = e.target.value;
    const emptyForm = buildInitialForm({}, jurisdiction);

    function getNewFormOnTypeChange(oldAnswers) {
      const dataToRetain = {
        name: oldAnswers.name,
      };

      if (
        [
          jurisdictions.CAYMAN,
          jurisdictions.LUXEMBOURG,
          jurisdictions.SEI,
        ].includes(jurisdiction)
      ) {
        dataToRetain.sourceOfWealth = oldAnswers.sourceOfWealth;
        dataToRetain.sourceOfWealthDetails = oldAnswers.sourceOfWealthDetails;
        dataToRetain.proofSourceOfFunds = oldAnswers.proofSourceOfFunds;
      }

      return { ...emptyForm, type: newType, ...dataToRetain };
    }

    if (shouldDisplayTypeChangeConfirmation) {
      confirm({
        title: 'Change type?',
        description:
          'Changing the type will discard all existing information, documents, and owners for this individual or entity.',
        destructive: true,
        confirmationText: 'Change type',
      })
        .then(() => {
          setLocalAnswers(getNewFormOnTypeChange);
        })
        .catch(() => {});
    } else {
      setLocalAnswers(getNewFormOnTypeChange);
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!haveAnswersChanged) {
          onClose();
          return;
        }

        confirm({
          title: 'Discard unsaved changes?',
          description: 'Your changes have not been saved.',
          destructive: true,
          confirmationText: 'Close',
          size: 'xs',
        })
          .then(() => {
            setLocalAnswers(initialAnswers);
            onClose();
          })
          .catch(() => {});
      }}
      onExited={() => {
        setStage(EDIT_STAGE);
        setErrors({});
      }}
      headerLabel="Edit details"
      primaryButtonLoading={isLoading}
      primaryButtonText={getBtnText()}
      primaryButtonEndIcon={stage === EDIT_STAGE ? <ArrowForwardIcon /> : null}
      primaryButtonDisabled={shouldDisableNextBtn}
      showCancelButton
      onBack={
        stage === REVIEW_STAGE
          ? () => {
              setStage(EDIT_STAGE);
            }
          : null
      }
      onSubmit={
        stage === EDIT_STAGE
          ? () => {
              onValidateData(localAnswers).then((resp) => {
                const validationErrors = resp.data?.[label];

                setErrors(validationErrors);
                if (Object.keys(validationErrors).length > 0) {
                  return;
                }

                setStage(REVIEW_STAGE);
              });
            }
          : () => {
              setIsLoading(true);

              onSubmit(localAnswers, wasTypeChanged, changesWillTriggerSearch)
                .catch((e) => {
                  setStage(EDIT_STAGE);
                  setErrors(e.response.data?.answer);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
      }
    >
      {stage === EDIT_STAGE ? (
        <DiligenceDetailInputs
          jurisdiction={jurisdiction}
          form={localAnswers}
          formErrors={errors}
          handleChange={(key, newVal) => {
            setLocalAnswers((lda) => ({ ...lda, [key]: newVal }));
          }}
          changeType={handleTypeChange}
          isRootNode={isRootNode}
        />
      ) : (
        <ReviewStageContent
          label={label}
          hasMatches={hasMatches}
          structuredNodesRoot={structuredNodesRoot}
          jurisdiction={jurisdiction}
          isRootNode={isRootNode}
          newAnswers={localAnswers}
          initialAnswers={initialAnswers}
        />
      )}
    </Modal>
  );
}
