import React from 'react';
import { DisplayLink } from 'components/link';
import {
  MAX_SMALL_FILE_UPLOAD_SIZE,
  FILE_TOO_LARGE_VALIDATION_ERROR_CODE,
  INVALID_FILE_TYPE_VALIDATION_ERROR_CODE,
  KNOWN_ERROR_TYPES,
} from './constants';

export function windowHandlerInitialization() {
  function dragEnter(e) {
    e.dataTransfer.effectAllowed = 'copy';
    e.dataTransfer.dropEffect = 'copy';
  }

  function dragOver(e) {
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
  }

  window.addEventListener('dragenter', dragEnter);
  window.addEventListener('dragover', dragOver);

  return () => {
    window.removeEventListener('dragenter', dragEnter);
    window.removeEventListener('dragover', dragOver);
  };
}

function getAllErrorObjects(rejectedFiles) {
  const errorObjects = rejectedFiles.flatMap((file) => file.errors);
  const uniqueErrorObjects = [...new Set(errorObjects)];

  return uniqueErrorObjects;
}

function getFileTooLargeError(errorMessage, showSupport) {
  return (
    <p>
      {errorMessage}{' '}
      <DisplayLink onClick={showSupport}>Contact support</DisplayLink> to upload
      this file.
    </p>
  );
}

export function constructErrorDisplay(rejectedFiles, showSupport) {
  const errorObjects = getAllErrorObjects(rejectedFiles);

  return errorObjects
    .filter((error) => KNOWN_ERROR_TYPES.includes(error.code))
    .map((error) => {
      if (error.code === FILE_TOO_LARGE_VALIDATION_ERROR_CODE) {
        return getFileTooLargeError(error.message, showSupport);
      }
      return <p>{error.message}</p>;
    });
}

function genFileTooLargeValidationError(fileName, maxUploadSize) {
  const maxSizeMB = maxUploadSize / 1024 / 1024;
  return {
    code: FILE_TOO_LARGE_VALIDATION_ERROR_CODE,
    message: `The file "${fileName}" is larger than ${maxSizeMB}MB.`,
  };
}

// prevent html and svg files from being uploaded
const INVALID_FILE_TYPES = ['text/html', 'image/svg+xml'];

const TYPE_TO_NAME = {
  'text/html': 'HTML',
  'image/svg+xml': 'SVG',
};

export const validateUploadedFile = (accept, maxSize) => (fileToUpload) => {
  const maxUploadSize = maxSize || MAX_SMALL_FILE_UPLOAD_SIZE;
  if (fileToUpload.size >= maxUploadSize) {
    return genFileTooLargeValidationError(fileToUpload.name, maxUploadSize);
  }
  if (accept) {
    if (fileToUpload.type && accept.includes(fileToUpload.type)) {
      return null;
    }
    const extraMessage = accept.includes('application/pdf')
      ? 'Upload a PDF file instead.'
      : '';
    return {
      code: INVALID_FILE_TYPE_VALIDATION_ERROR_CODE,
      message: `The file "${fileToUpload.name}" is not a valid file type. ${extraMessage}`,
    };
  }
  if (INVALID_FILE_TYPES.includes(fileToUpload.type)) {
    const extraMessage = TYPE_TO_NAME[fileToUpload.type]
      ? `${TYPE_TO_NAME[fileToUpload.type]} files are not supported.`
      : '';
    return {
      code: INVALID_FILE_TYPE_VALIDATION_ERROR_CODE,
      message: `The file "${fileToUpload.name}" is not a valid file type. ${extraMessage}`,
    };
  }
  return null;
};
