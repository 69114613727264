import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Prompt } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Button, Typography, useConfirm } from '@passthrough/uikit';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import { Alert } from 'components/alert';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { objectEquals, getSingleErrorFromResponse } from 'services/utils';
import { CustomApprovalModals } from './modals';
import { Section } from './section';
import { EMPTY_GRAPH } from './constants';
import { JSONSection } from './json_section';
import { useCamelState } from './hooks';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  customApprovals: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: '20px',
  },
}));

export function CustomApprovalPage() {
  const { fundId } = useParams();
  const confirm = useConfirm();
  const classes = useStyles();

  const [customApprovalJson, setCustomApprovalJson] = useState(EMPTY_GRAPH);
  const [originalCustomApprovalJson, setOriginalCustomApprovalJson] =
    useState(EMPTY_GRAPH);
  const [actionModalObj, setActionModalObj] = useCamelState(null);
  const [stateModalObj, setStateModalObj] = useCamelState(null);
  const [groupModalObj, setGroupModalObj] = useCamelState(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [error, setError] = useState(null);
  const { toast } = useToast();

  function setApprovalData(response) {
    const graph = JSON.parse(response.data.graph);

    setCustomApprovalJson(graph);
    setOriginalCustomApprovalJson(graph);
  }

  function initializeData() {
    setInitialLoading(true);

    api
      .getCustomApprovalData({ fundId })
      .then(setApprovalData)
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }

  function publishCustomApproval() {
    setUpdateLoading(true);
    setError(null);

    api
      .publishCustomApprovalData({
        fundId,
        data: JSON.stringify(customApprovalJson),
      })
      .then((response) => {
        setApprovalData(response);
        toast('Saved changes');
      })
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  }

  function deleteCustomApproval() {
    setUpdateLoading(true);
    setError(null);

    api
      .deleteCustomApprovalData({ fundId })
      .then(() => {
        setOriginalCustomApprovalJson(EMPTY_GRAPH);
        toast('Deleted custom approvals');
      })
      .catch((e) => {
        setError(getSingleErrorFromResponse(e.response));
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  }

  function sortStates(statesToSort) {
    const key = 'sort_order';
    statesToSort.sort((a, b) => a[key] - b[key]);
  }

  useEffect(initializeData, []);
  const haveUnpublishedChanges = useMemo(
    () => !objectEquals(customApprovalJson, originalCustomApprovalJson),
    [customApprovalJson, originalCustomApprovalJson],
  );

  if (initialLoading) {
    return <Spinner fullScreen />;
  }

  const actions = customApprovalJson?.actions;
  const groups = customApprovalJson?.groups;
  const states = customApprovalJson?.states;
  // Mutates the state array; reorder states in the state list and in
  // the json itself
  sortStates(states);

  const confirmPublish = () => {
    if (objectEquals(customApprovalJson, EMPTY_GRAPH)) {
      confirm({
        title: 'Delete custom approvals?',
        description: (
          <>
            <Typography>
              This fund will no longer have custom approvals.
            </Typography>
            <List dense disablePadding>
              <ListItem className={classes.listItem}>
                <Typography>
                  Custom statuses will no longer be displayed.
                </Typography>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Typography>
                  Existing fund admins will keep access level REVIEW. This could
                  cause unexpected behavior.
                </Typography>
              </ListItem>
            </List>
          </>
        ),
        disableTypography: true,
        confirmationText: 'Delete',
        destructive: true,
        size: 'sm',
      })
        .then(() => {
          deleteCustomApproval();
        })
        .catch(() => {});
    } else {
      confirm({
        title: 'Publish custom approval?',
        description:
          'Once published, all investors in this fund will receive the new custom workflow.',
        confirmationText: 'Publish',
        size: 'sm',
      })
        .then(() => {
          publishCustomApproval();
        })
        .catch(() => {});
    }
  };

  const confirmRevert = () =>
    confirm({
      title: 'Revert changes?',
      description: 'Undo all unpublished changes.',
      confirmationText: 'Revert',
      destructive: true,
      size: 'sm',
    })
      .then(() => {
        setError(null);
        setCustomApprovalJson(originalCustomApprovalJson);
        toast('Reverted changes');
      })
      .catch(() => {});

  return (
    <PageContainer maxWidth="lg">
      <Prompt
        when={haveUnpublishedChanges}
        message="You have not published your updates. Are you sure you want to leave?"
      />
      {error ? (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      ) : null}

      <div className={classes.customApprovals}>
        <div className={classes.header}>
          <Typography variant="page-heading">Custom approvals</Typography>
          <div className={classes.actions}>
            <Button
              variant="secondary"
              onClick={confirmRevert}
              disabled={!haveUnpublishedChanges || updateLoading}
              tooltip={haveUnpublishedChanges ? null : 'No changes to revert'}
            >
              Revert
            </Button>
            <Button
              variant="primary"
              onClick={confirmPublish}
              disabled={!haveUnpublishedChanges}
              tooltip={haveUnpublishedChanges ? null : 'No changes to publish'}
              loading={updateLoading}
            >
              Publish
            </Button>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Section
              sectionHeader="States"
              addButtonText="Add state"
              onAdd={() => {
                setStateModalObj({
                  id: null,
                  customStatusText: '',
                  isInitial: false,
                  isFinal: false,
                  sortOrder: '',
                });
              }}
              itemSubheaderGetter={(item) => (
                <Typography variant="card-subheading" size="small">
                  ID: {item.id}
                </Typography>
              )}
              items={states}
              onEdit={(state) => {
                setStateModalObj(state);
              }}
              itemHeaderAttr="custom_status_text"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Section
              sectionHeader="Actions"
              addButtonText="Add action"
              onAdd={() => {
                setActionModalObj({
                  id: null,
                  isTask: false,
                  customActionText: '',
                  fromState: null,
                  toState: null,
                });
              }}
              items={actions}
              onEdit={(action) => {
                setActionModalObj(action);
              }}
              itemHeaderAttr="custom_action_text"
              itemSubheaderGetter={(item) => (
                <>
                  <Typography variant="card-subheading" size="small">
                    From: {item.fromState}
                  </Typography>
                  <Typography variant="card-subheading" size="small">
                    To: {item.toState}
                  </Typography>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Section
              sectionHeader="Groups"
              addButtonText="Add group"
              onAdd={() => {
                setGroupModalObj({
                  id: null,
                  name: '',
                  actions: [],
                  notifications: [],
                });
              }}
              itemSubheaderGetter={(item) => (
                <Typography variant="card-subheading" size="small">
                  ID: {item.id}
                </Typography>
              )}
              items={groups}
              onEdit={(group) => {
                setGroupModalObj(group);
              }}
              itemHeaderAttr="name"
            />
          </Grid>
        </Grid>
      </div>
      <JSONSection
        data={customApprovalJson}
        setData={setCustomApprovalJson}
        setError={setError}
        haveUnpublishedChanges={haveUnpublishedChanges}
      />
      <CustomApprovalModals
        actions={actions}
        states={states}
        groups={groups}
        actionModalObj={actionModalObj}
        setActionModalObj={setActionModalObj}
        stateModalObj={stateModalObj}
        setStateModalObj={setStateModalObj}
        groupModalObj={groupModalObj}
        setGroupModalObj={setGroupModalObj}
        customApprovalJson={customApprovalJson}
        setCustomApprovalJson={setCustomApprovalJson}
        setError={setError}
      />
    </PageContainer>
  );
}
