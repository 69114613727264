import React from 'react';
import { Typography } from '@passthrough/uikit';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { ClosingDateField } from 'components/closing_date_field';

const useStyles = makeStyles(() => ({
  closingTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
}));

export function SharedSettings({
  closingTypes,
  name,
  nameError,
  setName,
  closingDate,
  setClosingDate,
  closingDateError,
  omitClosingDate,
  setOmitClosingDate,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.closingTypeContainer}>
        <Typography variant="body" size="small">
          Closing type{' '}
        </Typography>

        <Typography variant="body" size="medium">
          {closingTypes.join(', ')}
        </Typography>
      </div>

      <TextField
        required
        id="name"
        variant="outlined"
        label="Name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        error={!!nameError}
        helperText={nameError}
        fullWidth
      />

      <ClosingDateField
        closingDate={closingDate}
        setClosingDate={setClosingDate}
        closingDateError={closingDateError}
        omitClosingDate={omitClosingDate}
        setOmitClosingDate={setOmitClosingDate}
      />
    </>
  );
}
