import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Modal, Alert, Typography } from '@passthrough/uikit';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';

import { INVESTOR_ACCESS_LABELS } from '../investor_access_label';
import * as constants from '../constants';

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: theme.spacing(1),
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    marginLeft: '16px',
  },
  collapseEntered: {
    minHeight: 'auto !important',
  },
  collapseHidden: {
    display: 'none',
  },
}));

function getMissingDefaults(form, errors) {
  const missingDefaults = [];
  if (form.investorAccess === constants.SIGNUP_LINK) {
    if (errors.defaultCountersignerId) {
      missingDefaults.push('Default countersigner');
    }
    if (errors.defaultCountersigner2Id) {
      missingDefaults.push('Default second countersigner');
    }
    if (errors.defaultCountersigner3Id) {
      missingDefaults.push('Default third countersigner');
    }
    if (errors.defaultDiligenceApproverId) {
      missingDefaults.push('Default diligence approver');
    }
  }
  return missingDefaults;
}

export function InvitationSettingsModal({
  open,
  onClose,
  fundName,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const { productName } = useWhiteLabelConfig();
  const classes = useStyles();

  const missingDefaults = getMissingDefaults(form, errors);
  const showMissingDefaultError = missingDefaults.length > 0;

  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation:
      // Don't confirm when showing the missing default error since they'll
      // have to close the modal no matter what.
      open && !objectEquals(form, initialState) && !showMissingDefaultError,
  });

  const externalSignupTextHelperText =
    'Include a message for investors who use the sign-up link. ' +
    'The default message will show ' +
    `"${fundName} is using ${productName} to manage their subscription ` +
    "documents and investor questionnaire. You'll be asked a series of " +
    'questions to collect investor information and determine eligibility."';

  const showSignupLinkWarning =
    initialState.investorAccess === constants.SIGNUP_LINK &&
    form.investorAccess === constants.INVITE_ONLY;

  const resetState = () => {
    setForm(initialState);
    setErrors({});
  };

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit invitation settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={resetState}
      onExited={resetState}
      onSubmit={() => updateSettings(form)}
    >
      {showMissingDefaultError ? (
        <Alert severity="error" skipTypography>
          <Typography variant="body" size="small">
            A sign-up link requires the following to be assigned:
          </Typography>
          <List disablePadding className={classes.list}>
            {missingDefaults.map((text) => (
              <ListItem dense key={text} className={classes.listItem}>
                <Typography variant="body" size="small">
                  {text}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Alert>
      ) : null}

      <TextField
        id="default_invitation_message"
        variant="outlined"
        label="Default invitation message (optional)"
        type="text"
        value={form.defaultInvitationText || ''}
        onChange={(e) =>
          setForm({ ...form, defaultInvitationText: e.target.value })
        }
        error={!!errors.defaultInvitationText}
        helperText={
          errors.defaultInvitationText ||
          'Add a message template when you send invitations to investors.'
        }
        fullWidth
        multiline
        minRows={4}
      />

      <FormControl variant="outlined" error={!!errors.investorAccess}>
        <InputLabel id="external_signup_enabled_label">
          Investor sign-up
        </InputLabel>
        <Select
          id="external_signup_enabled"
          label="Investor sign-up"
          labelId="external_signup_enabled_label"
          value={form.investorAccess}
          variant="outlined"
          onChange={(e) => setForm({ ...form, investorAccess: e.target.value })}
        >
          {INVESTOR_ACCESS_LABELS.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {errors.investorAccess ? (
          <FormHelperText>{errors.investorAccess}</FormHelperText>
        ) : null}
      </FormControl>

      {showSignupLinkWarning ? (
        <Alert severity="warning">
          Saving this setting will disable the current sign-up link. A brand new
          URL will be created next time you choose to use a sign-up link.
        </Alert>
      ) : null}

      <Collapse
        in={form.investorAccess === constants.SIGNUP_LINK}
        classes={{
          entered: classes.collapseEntered,
          hidden: classes.collapseHidden,
        }}
      >
        <TextField
          id="custom_signup_message"
          variant="outlined"
          label="Custom sign-up message (optional)"
          type="text"
          value={form.externalSignupText || ''}
          onChange={(e) =>
            setForm({ ...form, externalSignupText: e.target.value })
          }
          error={!!errors.externalSignupText}
          helperText={errors.externalSignupText || externalSignupTextHelperText}
          fullWidth
          multiline
          minRows={4}
        />
      </Collapse>
    </Modal>
  );
}
