import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@passthrough/uikit';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert } from 'components/alert';
import UndoIcon from 'mdi-material-ui/Undo';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from 'mdi-material-ui/Check';
import Checkbox from '@material-ui/core/Checkbox';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { LinkInput } from './inputs/link_input';
import { AnswerMeaningsInput } from './inputs/answer_meanings_input';
import { InputDiv } from './common/input_div';
import { QueryBuilder } from './query_builder';
import { NoteHistoryDisplay } from './note_history';
import { QuestionFieldInput } from './inputs/question_field_input';
import { CheckboxNotesInput } from './inputs/checkbox_notes_input';
import { PersonEntityFilter } from './common/person_entity_filter';
import { IsRequiredInput } from './common/is_required_input';
import { FrontendLabelInput } from './common/frontend_label_input';
import { FileTypeInput } from './common/file_type_input';
import { OutputBoxes } from './common/output_boxes';
import { DraggableChoices } from './common/draggable_choices';
import { DependenciesList } from './common/dependencies_list';
import { Paper } from './common/paper';
import {
  blockNotRequired,
  FIELDS_BY_ANSWER_TYPE,
  REVIEW_STATE,
  ANSWER_MEANINGS,
  NAME_TYPES,
  EMAIL_TYPES,
  PHONE_TYPES,
  EMAIL_ANSWER_MEANINGS,
  NAME_ANSWER_MEANINGS,
  PHONE_ANSWER_MEANINGS,
} from './constants';
import { InteropSuggestion } from './common/interop_suggestion';

const useStyles = makeStyles((theme) => ({
  leftInput: {
    marginRight: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  restrictedThreadDisplay: {
    margin: 'auto',
  },
}));

export const EditQuestion = ({
  id,
  answerType,
  setAnswerType,
  answerMeanings,
  setAnswerMeanings,
  question,
  setQuestion,
  links,
  replaceLink,
  deleteLink,
  handleAddLink,
  isAsked,
  setIsAsked,
  isRequired,
  setIsRequired,
  notInteroperable,
  setNotInteroperable,
  frontendLabel,
  setFrontendLabel,
  fileType,
  setFileType,
  hellosignBoxes,
  replaceHellosignBox,
  deleteHellosignBox,
  setDragSelected,
  setLastSelectedBoxId,
  setPage,
  handleAddHellosignBox,
  choices,
  setChoices,
  replaceChoice,
  handleAddChoice,
  deleteChoice,
  copyChoice,
  allowSelectAll,
  setAllowSelectAll,
  deps,
  setDeps,
  questions,
  hasCustomLogic,
  setHasCustomLogic,
  newNotes,
  onDragEnd,
  handleSave,
  hasUnsavedChanges,
  isValid,
  isSaving,
  highlightBox,
  setHighlightBoxId,
  dependencies,
  handleClickItem,
  handleReplace,
  loadingReplace,
  suggestions,
  questionReuse,
  createQuestionNote,
  updateQuestionNote,
  deleteQuestionNote,
  onboardingLogs,
  handleReview,
  currentState,
  reviewedQuestionIds,
  setReviewedQuestionIds,
  selectedIds,
  handleAddChoiceLink,
  selectDocument,
}) => {
  const classes = useStyles();
  const applySuggestion = (suggestedQuestion) => {
    setQuestion(suggestedQuestion);
  };

  const getPossibleAnswerMeanings = () => {
    let possibleAnswerMeanings = [...ANSWER_MEANINGS];

    if (!EMAIL_TYPES.includes(answerType)) {
      // Remove all the EMAIL_ANSWER_MEANINGS from the list
      possibleAnswerMeanings = possibleAnswerMeanings.filter(
        (answerMeaning) => !EMAIL_ANSWER_MEANINGS.includes(answerMeaning.value),
      );
    }

    if (!NAME_TYPES.includes(answerType)) {
      // Remove all the NAME_ANSWER_MEANINGS from the list
      possibleAnswerMeanings = possibleAnswerMeanings.filter(
        (answerMeaning) => !NAME_ANSWER_MEANINGS.includes(answerMeaning.value),
      );
    }

    if (!PHONE_TYPES.includes(answerType)) {
      // Remove all the PHONE_ANSWER_MEANINGS from the list
      possibleAnswerMeanings = possibleAnswerMeanings.filter(
        (answerMeaning) => !PHONE_ANSWER_MEANINGS.includes(answerMeaning.value),
      );
    }

    return possibleAnswerMeanings;
  };

  const choiceLinks = hellosignBoxes
    .filter((box) => !!box.choice)
    .map((box) => box.linkId)
    .filter(Boolean);
  const topLevelLinks = links.filter((link) => !choiceLinks.includes(link.id));

  return (
    <DragDropContext onDragEnd={onDragEnd} key={id}>
      <Paper>
        {answerMeanings.includes('investor_type') ? (
          <div className={classes.alert}>
            <Alert severity="warning">
              <strong>
                The 1st choice must represent a NATURAL PERSON and the 2nd
                choice must represent an ENTITY.
              </strong>
            </Alert>
          </div>
        ) : null}

        {answerMeanings.includes('w9_v3') ||
        answerMeanings.includes('w9_v4') ? (
          <div className={classes.alert}>
            <Alert severity="warning">
              <strong>
                The 1st choice always means "Complete a W-9 electronically."
              </strong>
            </Alert>
          </div>
        ) : null}

        {answerMeanings.includes('w8') ? (
          <>
            <div className={classes.alert}>
              <Alert severity="warning">
                <strong>
                  The 1st choice always means "Complete a W-8 BEN
                  electronically."
                </strong>
              </Alert>
            </div>
            <div className={classes.alert}>
              <Alert severity="warning">
                <strong>
                  The 2nd choice always means "Complete a W-8 BEN-E
                  electronically."
                </strong>
              </Alert>
            </div>
          </>
        ) : null}

        {answerMeanings.includes('uk_addendum') ? (
          <>
            <div className={classes.alert}>
              <Alert severity="warning">
                <strong>
                  The 1st choice always means "UK/EEA Addendum Professional
                  Client"
                </strong>
              </Alert>
            </div>
            <div className={classes.alert}>
              <Alert severity="warning">
                <strong>
                  The 2nd choice always means "UK/EU Addendum Elected
                  Professional Client"
                </strong>
              </Alert>
            </div>
          </>
        ) : null}

        <NoteHistoryDisplay
          notes={newNotes[id] || []}
          className={classes.restrictedThreadDisplay}
          createQuestionNote={createQuestionNote}
          updateQuestionNote={updateQuestionNote}
          deleteQuestionNote={deleteQuestionNote}
          questionID={id}
          currentLog={onboardingLogs[0]}
          handleReview={handleReview}
          reviewedQuestionIds={reviewedQuestionIds}
          setReviewedQuestionIds={setReviewedQuestionIds}
          selectedIds={selectedIds}
        />
        <DependenciesList
          dependencies={dependencies}
          handleClickItem={handleClickItem}
        />

        <InputDiv>
          <TextField
            value={id}
            className={classes.leftInput}
            disabled
            variant="outlined"
            label="Question ID"
          />

          <Button
            variant="icon"
            size="large"
            onClick={() => {
              handleReplace();
            }}
            loading={loadingReplace}
            aria-label="Replace question"
          >
            <AutorenewIcon />
          </Button>

          <FormControl
            variant="outlined"
            className={classes.leftInput}
            required
            fullWidth
          >
            <InputLabel id="answer-type">Answer type</InputLabel>
            <Select
              label="Answer type"
              labelId="answer-type"
              value={answerType || ''}
              onChange={(e) => setAnswerType(e.target.value)}
            >
              <MenuItem value="aba">aba</MenuItem>
              <MenuItem value="address">address</MenuItem>
              <MenuItem value="affirmation">affirmation</MenuItem>
              <MenuItem value="bank">bank</MenuItem>
              <MenuItem value="bank_with_address">bank_with_address</MenuItem>
              <MenuItem value="bank_v2">bank_v2</MenuItem>
              <MenuItem value="blocker">blocker</MenuItem>
              <MenuItem value="choice">choice</MenuItem>
              <MenuItem value="contact">contact</MenuItem>
              <MenuItem value="contact_with_required_address">
                contact_with_required_address
              </MenuItem>
              <MenuItem value="contact_name_and_email">
                contact_name_and_email
              </MenuItem>
              <MenuItem value="contact_with_nationality">
                contact_with_nationality
              </MenuItem>
              <MenuItem value="country">country</MenuItem>
              <MenuItem value="crs_tax_details">crs_tax_details</MenuItem>
              <MenuItem value="crs_tax_details_v2">crs_tax_details_v2</MenuItem>
              <MenuItem value="date">date</MenuItem>
              <MenuItem value="date_year">date_year</MenuItem>
              <MenuItem value="date_without_year">date_without_year</MenuItem>
              <MenuItem value="dollars">dollars</MenuItem>
              <MenuItem value="email">email</MenuItem>
              <MenuItem value="file_upload">file_upload</MenuItem>
              <MenuItem value="info">info</MenuItem>
              <MenuItem value="integer">integer</MenuItem>
              <MenuItem value="multi_file_upload">multi_file_upload</MenuItem>
              <MenuItem value="multi_select">multi_select</MenuItem>
              <MenuItem value="name">name</MenuItem>
              <MenuItem value="name_with_title">name_with_title</MenuItem>
              <MenuItem value="name_required_title">
                name_required_title
              </MenuItem>
              <MenuItem value="name_title_email">name_title_email</MenuItem>
              <MenuItem value="percentage">percentage</MenuItem>
              <MenuItem value="ssn">ssn</MenuItem>
              <MenuItem value="ssn_with_escape">ssn_with_escape</MenuItem>
              <MenuItem value="giin">giin</MenuItem>
              <MenuItem value="giin_with_escape">giin_with_escape</MenuItem>
              <MenuItem value="state">state</MenuItem>
              <MenuItem value="state_with_escape">state_with_escape</MenuItem>
              <MenuItem value="tax_id">tax_id</MenuItem>
              <MenuItem value="tax_id_with_escape">tax_id_with_escape</MenuItem>
              <MenuItem value="tel">tel</MenuItem>
              <MenuItem value="text">text</MenuItem>
              <MenuItem value="text_long">text_long</MenuItem>
              <MenuItem value="true_or_false">true_or_false</MenuItem>
              <MenuItem value="yes_or_no">yes_or_no</MenuItem>
            </Select>
          </FormControl>

          <AnswerMeaningsInput
            answerMeanings={answerMeanings}
            setAnswerMeanings={setAnswerMeanings}
            possibleAnswerMeanings={getPossibleAnswerMeanings()}
          />

          {currentState === REVIEW_STATE ? (
            <Button
              variant="icon"
              size="large"
              onClick={() => {
                handleReview(!reviewedQuestionIds.includes(id));
              }}
              aria-label={
                reviewedQuestionIds.includes(id)
                  ? 'Mark as unreviewed'
                  : 'Mark as reviewed'
              }
            >
              {reviewedQuestionIds.includes(id) ? <UndoIcon /> : <CheckIcon />}
            </Button>
          ) : null}
        </InputDiv>

        <InputDiv>
          <TextField
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            variant="outlined"
            multiline
            rows={6}
            fullWidth
            required
            label="Question text"
            inputProps={{ style: { resize: 'revert' } }}
          />
        </InputDiv>

        <InteropSuggestion
          onApply={applySuggestion}
          questionReuse={questionReuse}
          suggestions={suggestions}
          question={question}
        />

        <FileTypeInput
          answerType={answerType}
          fileType={fileType}
          setFileType={setFileType}
        />

        {topLevelLinks.map((link) => (
          <InputDiv key={link.id}>
            <LinkInput
              id={link.id}
              text={link.text}
              page={link.page}
              url={link.url}
              setPage={setPage}
              onChange={replaceLink}
              onDelete={() => {
                deleteLink(link.id);
              }}
              highlightBox={highlightBox}
              setHighlightBoxId={setHighlightBoxId}
              hellosignBoxes={hellosignBoxes.filter((box) =>
                Boolean(box.linkId === link.id),
              )}
              handleAddHellosignBox={handleAddHellosignBox}
              replaceHellosignBox={replaceHellosignBox}
              deleteHellosignBox={deleteHellosignBox}
              setDragSelected={setDragSelected}
              selectDocument={selectDocument}
            />
          </InputDiv>
        ))}

        <InputDiv>
          <Button
            variant="secondary"
            startIcon={<AddIcon />}
            onClick={handleAddLink}
          >
            Add link
          </Button>
        </InputDiv>

        <InputDiv>
          <PersonEntityFilter isAsked={isAsked} setIsAsked={setIsAsked} />

          <IsRequiredInput
            isRequired={isRequired}
            setIsRequired={setIsRequired}
            blockNotRequired={blockNotRequired}
            answerType={answerType}
          />
        </InputDiv>

        {answerType === 'multi_select' ? (
          <FormControl>
            <FormControlLabel
              key="selectAll"
              className={classes.label}
              control={
                <Checkbox
                  checked={allowSelectAll}
                  onChange={(e) => {
                    setAllowSelectAll(e.target.checked);
                  }}
                  name="selectAll"
                />
              }
              label="Allow investors to select all"
            />
          </FormControl>
        ) : null}

        <FrontendLabelInput
          frontendLabel={frontendLabel}
          setFrontendLabel={setFrontendLabel}
          answerType={answerType}
        />

        <OutputBoxes
          highlightBox={highlightBox}
          setHighlightBoxId={setHighlightBoxId}
          replaceHellosignBox={replaceHellosignBox}
          deleteHellosignBox={deleteHellosignBox}
          setDragSelected={setDragSelected}
          setPage={setPage}
          questions={questions}
          handleClickItem={handleClickItem}
          answerType={answerType}
          hellosignBoxes={hellosignBoxes}
          handleAddHellosignBox={handleAddHellosignBox}
          selectDocument={selectDocument}
        />

        <DraggableChoices
          choices={choices}
          replaceChoice={replaceChoice}
          deleteChoice={deleteChoice}
          copyChoice={copyChoice}
          setChoices={setChoices}
          setPage={setPage}
          hellosignBoxes={hellosignBoxes}
          replaceHellosignBox={replaceHellosignBox}
          deleteHellosignBox={deleteHellosignBox}
          setDragSelected={setDragSelected}
          setLastSelectedBoxId={setLastSelectedBoxId}
          handleAddHellosignBox={handleAddHellosignBox}
          answerType={answerType}
          highlightBox={highlightBox}
          setHighlightBoxId={setHighlightBoxId}
          questions={questions}
          handleClickItem={handleClickItem}
          handleAddChoice={handleAddChoice}
          questionId={id}
          handleAddChoiceLink={handleAddChoiceLink}
          links={links}
          replaceLink={replaceLink}
          deleteLink={deleteLink}
          selectDocument={selectDocument}
        />

        {(FIELDS_BY_ANSWER_TYPE[answerType] || []).map((field) => (
          <QuestionFieldInput
            key={field}
            field={field}
            setPage={setPage}
            hellosignBoxes={hellosignBoxes.filter((c) =>
              Boolean(c.field === field),
            )}
            changeBox={replaceHellosignBox}
            deleteBox={deleteHellosignBox}
            dragBox={setDragSelected}
            addBox={handleAddHellosignBox}
            highlightBox={highlightBox}
            setHighlightBoxId={setHighlightBoxId}
            questions={questions}
            handleClickItem={handleClickItem}
            questionId={id}
            answerType={answerType}
            selectDocument={selectDocument}
          />
        ))}

        <InputDiv>
          <QueryBuilder
            questionIds={[id]}
            value={deps}
            onChange={(d) => {
              setDeps(d);
            }}
            questions={questions}
            handleClickItem={handleClickItem}
            hideAddCondition={false}
          />
        </InputDiv>

        <CheckboxNotesInput
          hasCustomLogic={hasCustomLogic}
          setHasCustomLogic={setHasCustomLogic}
          notInteroperable={notInteroperable}
          setNotInteroperable={setNotInteroperable}
        />

        <InputDiv>
          <Button
            variant="primary"
            disabled={!isValid() || !hasUnsavedChanges}
            onClick={handleSave}
            fullWidth
            loading={isSaving}
          >
            Save
          </Button>
        </InputDiv>
      </Paper>
    </DragDropContext>
  );
};
