import React from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  itemText: {
    paddingRight: theme.spacing(4),
  },
}));

export const InteropSuggestionItem = ({ item, onApply, current }) => {
  const classes = useStyles();
  const primary = current ? (
    <>
      <small>
        <strong>Current question</strong>
      </small>
      <Typography>{item.questionText}</Typography>
    </>
  ) : (
    item.questionText
  );
  return (
    <Paper variant="outlined" className={classes.root}>
      <ListItem>
        <ListItemText
          className={classes.itemText}
          primary={primary}
          secondary={
            <Box mt={1}>
              {item.tags.length ? (
                item.tags.map((tag) => (
                  <Chip
                    key={tag}
                    variant="outlined"
                    color="default"
                    size="small"
                    icon={<LabelIcon />}
                    label={<Typography variant="body2">{tag}</Typography>}
                  />
                ))
              ) : (
                <Chip
                  variant="outlined"
                  color="default"
                  size="small"
                  icon={<LabelOffIcon />}
                  label={
                    <Typography variant="body2">
                      Reused {item.reuseCount} times
                    </Typography>
                  }
                />
              )}
            </Box>
          }
        />
        {onApply ? (
          <ListItemSecondaryAction>
            <Button onClick={onApply}>Apply</Button>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </Paper>
  );
};
