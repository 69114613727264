import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  PageHeader,
  Breadcrumbs,
  Button,
  Icons,
  HeaderSkeleton,
} from '@passthrough/uikit';
import FolderMultipleOutlineIcon from 'mdi-material-ui/FolderMultipleOutline';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import format from 'date-fns/format';

import * as urls from 'services/urls';
import { useFund, useFundEdit } from 'services/providers/fund';
import { useFeatureFlags } from 'services/providers/feature_flag';

const useStyles = makeStyles((theme) => ({
  actionBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
  },
  metadataContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    color: theme.palette.text.secondary,
    gap: theme.spacing(1, 2),
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  },
}));

function genFormattedDate(rawDate) {
  return format(new Date(rawDate), 'dd LLL yyyy');
}

export function OverviewPageHeader({
  createdAt,
  closingTypes,
  closingDate,
  fundClosingName,
  isRefreshing,
  onRefresh,
  onEditSettings,
  readOnly,
  editSettingsButtonText,
}) {
  const [fund] = useFund();
  const [fundEdit] = useFundEdit();
  const classes = useStyles();
  const { NEW_CLOSING_SETTINGS } = useFeatureFlags();
  const showEditSettingsButton = NEW_CLOSING_SETTINGS
    ? true
    : fundEdit && !readOnly;

  const skeletons = [];

  if (!fundClosingName) {
    skeletons.push(HeaderSkeleton.heading);
  }
  if (!createdAt) {
    skeletons.push(HeaderSkeleton.metadata);
  }

  function renderMetadata() {
    if (!createdAt) {
      return null;
    }
    return (
      <div className={classes.metadataContainer}>
        <Typography variant="body" size="small">
          Created {genFormattedDate(createdAt)}
        </Typography>

        <Tooltip title={<Typography variant="body">Closing type</Typography>}>
          <span>
            <FolderMultipleOutlineIcon fontSize="inherit" />

            <Typography variant="body" size="small">
              {closingTypes.join(', ')}
            </Typography>
          </span>
        </Tooltip>

        <Tooltip title={<Typography variant="body">Closing date</Typography>}>
          <span>
            <CalendarTodayOutlinedIcon fontSize="inherit" />

            <Typography variant="body" size="small">
              {closingDate ? genFormattedDate(closingDate) : 'No closing date'}
            </Typography>
          </span>
        </Tooltip>
      </div>
    );
  }

  return (
    <PageHeader
      heading={fundClosingName}
      crumbs={
        <Breadcrumbs
          crumbs={[
            {
              name: fund?.organizationName,
              to: fund?.isOrganizationAdmin
                ? urls.organizationUrl({
                    organizationId: fund.organizationId,
                  })
                : null,
              skeleton: !fund?.organizationName,
            },
            {
              name: fund?.name,
              to: fund ? urls.closingsUrl({ fundId: fund.id }) : null,
              skeleton: !fund?.name,
            },
            { name: fundClosingName, skeleton: !fundClosingName },
          ]}
        />
      }
      metadata={renderMetadata()}
      actionButtons={
        <div className={classes.actionBtnContainer}>
          <Button
            startIcon={<Icons.RefreshOutlined />}
            data-test="refresh_closing"
            variant="text"
            onClick={() => {
              onRefresh();
            }}
            loading={isRefreshing}
          >
            Refresh
          </Button>

          {showEditSettingsButton ? (
            <Button
              startIcon={<Icons.SettingsOutlined />}
              data-test="open_edit_closing"
              variant="secondary"
              onClick={() => {
                onEditSettings();
              }}
            >
              {editSettingsButtonText}
            </Button>
          ) : null}
        </div>
      }
      skeletons={skeletons}
    />
  );
}
