import React, { useState } from 'react';
import HumanGreetingIcon from 'mdi-material-ui/HumanGreeting';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Alert,
  Typography,
  Button,
  Link,
  UIKitSettingsProvider,
} from '@passthrough/uikit';
import Container from '@material-ui/core/Container';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';
import * as urls from 'services/urls';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { ExternalLogo } from 'components/logo';
import { Copyright } from 'pages/lp_doc/copyright_v2';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    alignSelf: 'stretch',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  stepContainer: {
    maxWidth: '460px',
  },
  contentHeader: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
  },
  alert: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(6),
  },
  input: {
    backgroundColor: 'white',
  },
  welcome: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: theme.spacing(10),
    textAlign: 'center',
  },
  terms: {
    paddingTop: theme.spacing(2),
  },
}));

export function SignUp({ onSignUp }) {
  const classes = useStyles();

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [privacyAndTerms, setPrivacyAndTerms] = useState(false);
  const [privacyAndTermsError, setPrivacyAndTermsError] = useState('');
  const [complete, setComplete] = useState(false);
  const isSubmitDisabled = !firstName || !lastName || !privacyAndTerms;

  function handleSubmit(e) {
    e.preventDefault();

    api
      .signup({
        firstName,
        lastName,
        hasAcceptedPrivacyAndTerms: privacyAndTerms,
      })
      .then(() => {
        setComplete(true);
        setTimeout(() => {
          onSignUp();
        }, 2000);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setFirstNameError(error.response.data.firstName);
          setLastNameError(error.response.data.lastName);
          setPrivacyAndTermsError(
            error.response.data.hasAcceptedPrivacyAndTerms,
          );
        }
      });
  }

  return (
    <>
      <Grow in={complete}>
        <Container component="main" maxWidth="md" className={classes.welcome}>
          <HumanGreetingIcon style={{ fontSize: '80px' }} />
          <Typography variant="section-heading">
            Welcome, {firstName}!
          </Typography>
          <Spinner size={100} />
        </Container>
      </Grow>

      <Fade in={!complete}>
        <div className={classes.root}>
          <div className={classes.logoContainer}>
            <ExternalLogo />
          </div>
          <div className={classes.container}>
            <div className={classes.stepContainer}>
              <div className={classes.contentHeader}>
                <Typography variant="section-heading">
                  What is your name?
                </Typography>
              </div>
              <div className={classes.alert}>
                <Alert severity="info">
                  Do not enter someone else's name even if you intend to sign on
                  their behalf.
                </Alert>
              </div>
              <form
                className={classes.form}
                noValidate
                onSubmit={(e) => handleSubmit(e)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.input}
                      size="medium"
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First name"
                      autoFocus
                      error={!!firstNameError}
                      helperText={firstNameError}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label="Last name"
                      name="lastName"
                      autoComplete="lname"
                      error={!!lastNameError}
                      helperText={lastNameError}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <FormControl
                  className={classes.terms}
                  error={!!privacyAndTermsError}
                >
                  <UIKitSettingsProvider
                    linkComponent="a"
                    redirectAttribute="href"
                  >
                    <FormControlLabel
                      label={
                        <span>
                          I have read, understood and I agree to Passthrough's{' '}
                          <Link
                            href={urls.PRIVACY_POLICY_URL}
                            inline
                            variant="external"
                            externalIcon={false}
                          >
                            Privacy Policy
                          </Link>{' '}
                          and{' '}
                          <Link
                            href={urls.TERMS_URL}
                            inline
                            variant="external"
                            externalIcon={false}
                          >
                            Terms & Conditions
                          </Link>
                          .
                        </span>
                      }
                      control={
                        <Checkbox
                          checked={privacyAndTerms}
                          onChange={(e) => {
                            setPrivacyAndTerms(e.target.checked);
                          }}
                          name="privacy-and-terms"
                        />
                      }
                    />
                  </UIKitSettingsProvider>
                  {privacyAndTermsError ? (
                    <FormHelperText>{privacyAndTermsError}</FormHelperText>
                  ) : null}
                </FormControl>
                <div className={classes.submit}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="primary"
                    disabled={isSubmitDisabled}
                    loading={complete}
                    htmlProps={{
                      style: {
                        maxWidth: '100%',
                      },
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <Copyright />
        </div>
      </Fade>
    </>
  );
}
