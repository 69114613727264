import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Button } from '@passthrough/uikit';
import Avatar from '@material-ui/core/Avatar';
import PencilIcon from 'mdi-material-ui/PencilOutline';
import LockIcon from '@material-ui/icons/LockOutlined';
import { List } from '@material-ui/core';

import { EmptyState } from 'components/empty_v2';
import { DocumentCardEntry } from './document_card_entry';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  docCards: {
    padding: theme.spacing(0, 2),
  },
  emptyBorder: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
  },
  lockIcon: {
    color: theme.palette.primary.grey,
  },
  extraFilesDisplay: {
    backgroundColor: theme.palette.background.internalNote,
  },
  extraDocsIcon: {
    width: '32px',
    height: '32px',
    backgroundColor: theme.palette.eventBackground.internalNote,
  },
}));

export function SupplementaryDocumentsDisplay({
  supplementaryFiles,
  hideEditButtons,
  handleOpenExtraDocsEditModal,
  handleOpenDocument,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <Avatar className={classes.extraDocsIcon}>
            <LockIcon className={classes.lockIcon} />
          </Avatar>

          <Typography variant="card-heading">Additional documents</Typography>
        </div>

        {hideEditButtons ? null : (
          <Button
            size="small"
            aria-label="Manage additional documents"
            tooltip="Manage"
            variant="icon"
            onClick={handleOpenExtraDocsEditModal}
          >
            <PencilIcon />
          </Button>
        )}
      </div>

      <Typography variant="label" color="text.secondary">
        Upload any documents relevant to this owner's diligence. They are not
        visible to the investor.
      </Typography>

      {!supplementaryFiles || supplementaryFiles?.length === 0 ? (
        <Paper variant="outlined">
          <EmptyState text="No documents" />
        </Paper>
      ) : (
        <Paper variant="outlined" className={classes.extraFilesDisplay}>
          <List>
            {supplementaryFiles.map((docData) => (
              <DocumentCardEntry
                key={docData.fileId}
                fileData={docData}
                handleOpenDocument={handleOpenDocument}
              />
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
}
