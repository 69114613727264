import React from 'react';
import { Typography } from '@passthrough/uikit';

import { StatusDot, getStatusText } from 'components/status';
import { STANDARD_STATUSES } from 'components/status/constants';
import { GenericInvestorTagGroup } from './generic_investor_tag_group';

export function LpClosingStatusGroup({
  statuses,
  hasSigning,
  locallySelectedFilters,
  setLocallySelected,
}) {
  function genDisplayLabel(status) {
    const isCustomStatus = !STANDARD_STATUSES.includes(status.name);
    const customStatusText = isCustomStatus ? status.name : null;
    const statusForGetStatusText = isCustomStatus ? 'SIGNED' : status.name;

    return getStatusText(statusForGetStatusText, {
      hasSigning,
      customStatusText,
    });
  }

  function genIcon(status) {
    const isCustomStatus = !STANDARD_STATUSES.includes(status.name);
    const statusForGetStatusText = isCustomStatus ? 'SIGNED' : status.name;

    return (
      <StatusDot status={statusForGetStatusText} hasSigning={hasSigning} />
    );
  }

  return (
    <>
      <Typography fontWeight={500}>Status</Typography>

      <GenericInvestorTagGroup
        tags={statuses}
        getDisplayLabel={genDisplayLabel}
        getIcon={genIcon}
        locallySelectedFilters={locallySelectedFilters}
        setLocallySelected={setLocallySelected}
      />
    </>
  );
}
