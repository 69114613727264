import React from 'react';

import { Typography } from '@passthrough/uikit';
import { Setting, SettingsGroup } from 'components/settings_group';

import { Message } from './message';
import { getClosingTypes } from './utils';

function ClosingType({ diligenceEnabled, hasSubdocQuestionnaire }) {
  return (
    <Setting label="Closing type">
      <Typography>
        {getClosingTypes(diligenceEnabled, hasSubdocQuestionnaire).join(', ')}
      </Typography>
    </Setting>
  );
}

function ClosingName({ name }) {
  return (
    <Setting label="Closing name">
      <Typography>{name}</Typography>
    </Setting>
  );
}

function ClosingDate({ closingDate }) {
  return (
    <Setting label="Closing date">
      {closingDate ? (
        <Typography>{closingDate}</Typography>
      ) : (
        <Typography color="text.secondary">No closing date</Typography>
      )}
    </Setting>
  );
}

function CompletionMessage({ subdocCompleteText }) {
  return (
    <Setting
      label="Completion message (optional)"
      description={
        <Typography variant="body" size="small" color="text.secondary">
          Include a message after investors complete the questionnaire.
        </Typography>
      }
    >
      <Message
        message={subdocCompleteText}
        missingMessageText="No completion message"
      />
    </Setting>
  );
}

export function GeneralSettings({
  name,
  closingDate,
  hasSubdocQuestionnaire,
  diligenceEnabled,
  subdocCompleteText,
  onEdit,
  readOnly,
}) {
  return (
    <SettingsGroup header="General" onEdit={onEdit} readOnly={readOnly}>
      <ClosingType
        diligenceEnabled={diligenceEnabled}
        hasSubdocQuestionnaire={hasSubdocQuestionnaire}
      />
      <ClosingName name={name} />
      <ClosingDate closingDate={closingDate} />
      <CompletionMessage subdocCompleteText={subdocCompleteText} />
    </SettingsGroup>
  );
}
