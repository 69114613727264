import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import * as api from 'services/api';
import {
  getSelectedStaffOrg,
  getAutocompleteOptionLabelByName,
} from 'services/utils';
import { useRegionalAction } from 'services/regional';
import { PageAlignment, PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { InternalChip } from '@passthrough/uikit';
import * as urls from 'services/urls';

const useStyles = makeStyles((theme) => ({
  interiorSpacing: {
    margin: theme.spacing(3, 0),
  },
  contentToHide: {
    textAlign: 'center',
    fontWeight: '500',
  },
}));

export function SelectStaffOrgPage() {
  const [allOrgs, setAllOrgs] = useState([]);
  const [areOrgsLoading, setAreOrgsLoading] = useState(false);
  const [localStaffOrgId, setLocalStaffOrgId] = useState(getSelectedStaffOrg());
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const action = useRegionalAction();

  function getOrganizations() {
    setAreOrgsLoading(true);

    api.organizations().then((response) => {
      setAllOrgs(response.data);
      setAreOrgsLoading(false);
    });
  }

  useEffect(getOrganizations, []);

  function handleSave() {
    setLoading(true);

    api
      .setSelectedStaffOrg(localStaffOrgId)
      .then(() => {
        const org = allOrgs.find((o) => o.id === localStaffOrgId);
        action(org.baseUrl, urls.DASH_URL);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (areOrgsLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <PageContainer maxWidth="sm" align={PageAlignment.center}>
      <InternalChip size="large" />

      <div className={classes.interiorSpacing}>
        <Typography>
          Select an organization below to temporarily restrict the following in
          app content:
        </Typography>

        <Typography className={classes.contentToHide}>Funds, Tasks</Typography>
      </div>

      <Autocomplete
        className={classes.interiorSpacing}
        selectOnFocus
        fullWidth
        options={allOrgs}
        value={localStaffOrgId}
        onChange={(e, v) => {
          setLocalStaffOrgId(v?.id);
        }}
        disabled={loading}
        renderOption={(option) => <>{option.name}</>}
        getOptionLabel={(option) =>
          getAutocompleteOptionLabelByName(option, allOrgs)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Organization"
            variant="outlined"
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
            }}
          />
        )}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={!localStaffOrgId}
        loading={loading}
        onClick={() => {
          handleSave();
        }}
      >
        Save
      </Button>
    </PageContainer>
  );
}
