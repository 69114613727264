import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog } from 'components/dialog/index';
import { StepInfo } from './step_info';
import { StepSwitchProfile } from './step_switch_profile';

export function ProfileInfoModal({
  open,
  handleClose,
  availableProfiles,
  activeProfile,
  setActiveProfile,
  setSetupProfileId,
  fundName,
  lpName,
  setRestarting,
}) {
  const [step, setStep] = React.useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState(activeProfile?.id);
  const theme = useTheme();
  const fullScreenMobile = useMediaQuery(theme.breakpoints.down('md'));
  const selectedProfile =
    availableProfiles.find((profile) => profile.id === selectedProfileId) ||
    'new';

  const onClose = () => {
    handleClose();
    setTimeout(() => setStep(0), 200);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreenMobile}
    >
      {step === 0 ? (
        <StepInfo
          fundName={fundName}
          lpName={lpName}
          activeProfile={activeProfile}
          availableProfiles={availableProfiles}
          selectedProfileId={selectedProfileId}
          setSelectedProfileId={setSelectedProfileId}
          setStep={setStep}
          onClose={onClose}
        />
      ) : null}
      {step === 1 ? (
        <StepSwitchProfile
          activeProfile={activeProfile}
          selectedProfile={selectedProfile}
          isNewInvestor={selectedProfileId === 'new'}
          setSetupProfileId={setSetupProfileId}
          setActiveProfile={setActiveProfile}
          setRestarting={setRestarting}
          setStep={setStep}
          onClose={onClose}
        />
      ) : null}
    </Dialog>
  );
}
