import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, Typography, UIKitSettingsProvider } from '@passthrough/uikit';
import { useDocumentsDrawerActions } from 'components/documents_drawer';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { useSubdoc } from './subdoc_provider';
import { usePdf } from './pdf_provider';

const useStyles = makeStyles((theme) => ({
  mobileChoiceLink: {
    padding: '10px',
    margin: '-10px !important',
  },
  gap: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

function countInstances(string, word) {
  return string.split(word).length - 1;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function TextWithContextLinks({
  prefixText,
  text,
  choiceId,
  allLinksForQuestion,
  allPdfBoxesForQuestion,
}) {
  let links;
  if (choiceId) {
    const linkBoxes = (allPdfBoxesForQuestion || []).filter(
      (box) => box.choiceId === choiceId && !!box.linkId,
    );
    links = (allLinksForQuestion || []).filter((l) =>
      linkBoxes.some((box) => box.linkId === l.id),
    );
  } else {
    const choiceLinkIds = (allPdfBoxesForQuestion || [])
      .filter((box) => box.linkId && box.choiceId)
      .map((box) => box.linkId);
    links = (allLinksForQuestion || []).filter(
      (link) => !choiceLinkIds.includes(link.id),
    );
  }

  const classes = useStyles();
  const onOpenPdf = usePdf();
  const drawerActions = useDocumentsDrawerActions();
  const { LP_PRO_MODE } = useFeatureFlags();
  const subdoc = useSubdoc();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let size = 'medium';
  let align = 'left';
  if (!choiceId) {
    size = isMobile ? 'large' : 'extra-large';
    const useLongTextStyle =
      text.length > 300 || countInstances(text, '\n\n') >= 1;
    align = useLongTextStyle ? 'left' : 'center';
  }

  const regexpBody = links.map((l) => escapeRegExp(l.text)).join('|');
  const regexp = RegExp(`(${regexpBody})`);
  const textParts = regexpBody ? text.split(regexp) : [text];

  function openPdf(link) {
    const doc = link.document || subdoc;
    const linkPdfBoxes = (allPdfBoxesForQuestion || []).filter(
      (box) => box.linkId === link.id,
    );
    const page = linkPdfBoxes.length ? linkPdfBoxes[0].page : link.page;

    if (!LP_PRO_MODE) {
      onOpenPdf(doc, link?.page || 1);
      return;
    }
    drawerActions.openLink({
      docId: doc?.id,
      page: page || 1,
      linkId: link.id,
    });
  }

  function renderText(textPart, index) {
    const link = links.find((l) => l.text === textPart);
    if (link === undefined) {
      return textPart;
    }

    const key = `${link.id}-${index}`;
    const linkProps = link.url
      ? { href: link.url, variant: 'external' }
      : {
          onClick: (e) => {
            e.preventDefault();
            openPdf(link);
          },
        };
    return (
      <Link
        className={choiceId && isMobile ? classes.mobileChoiceLink : ''}
        key={key}
        inline
        {...linkProps}
      >
        {textPart}
      </Link>
    );
  }

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Typography variant="body" size={size} align={align}>
        {prefixText || null}
        {textParts.map(renderText)}
      </Typography>
    </UIKitSettingsProvider>
  );
}
