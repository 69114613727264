import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography as PassTypography,
  Link,
  Icons,
  useConfirm,
} from '@passthrough/uikit';
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import LabelIcon from '@material-ui/icons/LabelOutlined';
import CheckAllIcon from 'mdi-material-ui/CheckAll';
import DatabaseExportIcon from 'mdi-material-ui/DatabaseExport';
import FileDocumentPlus from 'mdi-material-ui/FileDocumentPlus';
import FileDocumentMinus from 'mdi-material-ui/FileDocumentMinus';
import FileDocumentEdit from 'mdi-material-ui/FileDocumentEdit';

import { buildDarkTheme } from 'services/theme';
import {
  TableActions,
  TableContainer,
  TableCell,
} from 'components/table/index';
import { SmartTable } from 'components/table/smart';
import { ListBulkAction } from 'components/bulk_action';
import { DocumentUploadPage } from 'components/document_upload/index';
import { DisplayLink } from 'components/link';
import { useToast } from 'services/toast';
import { useFundEdit } from 'services/providers/fund';
import * as api from 'services/api';
import { sortInvestors } from 'services/investor_utils';
import * as urls from 'services/urls';
import { useFeatureFlags } from 'services/providers/feature_flag';
import {
  EXPOSED_SIDE_LETTER_TYPES,
  SIDE_LETTER,
} from 'components/document_upload/constants';
import { AcceptedCommitment } from 'components/accepted_commitment';
import { EmptyState } from 'components/empty_v2';
import { ApproveModal } from 'components/approve_modal_v2';
import { RiskRatingChip } from 'components/risk_rating_chip';
import {
  canSendToInvestorStatuses,
  multiDeletableStatuses,
  canRemindStatuses,
  canApproveStatus,
  canUnapproveStatuses,
  canSendToCountersignerStatuses,
  canMarkAsFullyExecutedStatuses,
  canResendClosingEmailStatuses,
  SENT_TO_COUNTERSIGNER_STATUS,
  SIGNED_STATUS,
  APPROVED_STATUS,
  DRAFT_STATUS,
} from 'components/status/constants';
import { IndividualActionsMenu } from './individual_investor_actions_menu';
import { StatusChip, getStatusText } from './status';
import { SendDialog } from './send_dialog';
import { SendReminderDialog } from './send_reminder_dialog';
import { ZipDialog } from './zip_dialog';
import { SubdocDataExportModal } from './modals/data_export/subdoc_data_export';
import { DiligenceDataExportModal } from './modals/data_export/diligence_data_export';
import { RemoveDocumentDialog } from './remove_document_dialog';
import { MoveToClosingDialog } from './move_to_closing_dialog';
import { ChangeDiligenceApproverDialog } from './change_diligence_approver_dialog';
import { LpFiltersPopOver } from './lp_filters_popover/index';
import { SendClosingEmailDialog } from './send_closing_email_dialog';
import { CannotDeleteDialog } from './cannot_delete_dialog';
import { InvestorTaggingModal } from './investor_tagging/index';
import { EditNoteModal } from './overall_investor_note/edit_modal';

import { InvestorTagsCellContainer } from './investor_tags_cell_container';
import { SendToCountersignersModal } from './send_to_countersigners_modal_v2';
import { PrepareSignaturePagesModal } from './prepare_signature_pages_modal';
import { CannotPrepareModal } from './prepare_signature_pages_modal/cannot_prepare_modal';
import {
  investorClosingStatusSort,
  getFilterStatus,
  getFilteredInvestors,
  buildClosingStatusTag,
  buildRiskRatingTag,
  canRemoveDocsFromInvestor,
  canPrepareSignaturePages,
} from './utils';

const useDrawerStyles = makeStyles(() => ({
  paperAnchorDockedRight: {
    borderLeft: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  error: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  actionsList: {
    width: '350px',
    backgroundColor: theme.palette.drawer.background,
    color: theme.palette.primary.white,
    minHeight: '100vh',
    display: 'inline-table',
  },
  listSelected: {
    marginTop: theme.spacing(3),
  },
  listTextSelected: {
    margin: theme.spacing(3, 0, 1, 0),
    fontSize: theme.typography.pxToRem(16),
    color: '#b3b3b3',
  },
  listNumberSelected: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '600',
  },
  listHeader: {
    margin: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '600',
  },
  listDivider: {
    margin: theme.spacing(3, 0),
  },
  unselectAll: {
    fontSize: theme.typography.pxToRem(14),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
    },
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
  },
  investorCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
    minWidth: 'fit-content',
  },
  individualActionsRowSelected: {
    backgroundColor: theme.palette.primary.light,
  },
  investorTagsContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    maxWidth: '300px',
  },
}));

function getUnique(array) {
  return [...new Set(array)];
}

function genClosingStatusTags(investors, customApprovalStatuses) {
  // in order to ensure consistent schema for the
  // data stored in the filters state, we need to
  // modify the structure of closing status data
  const statuses = new Set(investors.map(getFilterStatus));
  const sortedStatuses = [...statuses].sort((a, b) =>
    investorClosingStatusSort(customApprovalStatuses, a, b),
  );

  const out = sortedStatuses.map((status) => buildClosingStatusTag(status));
  return out;
}

function genRiskRatingTags(investors, diligenceEnabled) {
  if (!diligenceEnabled) {
    return [];
  }

  const riskRatings = getUnique(
    investors
      .filter((i) => i.hasDiligence && i.riskRating)
      .map((i) => i.riskRating),
  ).sort();

  return riskRatings.map((riskRating) => buildRiskRatingTag(riskRating));
}

function getWorkflowIcon(investorStatus, canMarkAsFullyExecuted) {
  if (investorStatus === SIGNED_STATUS) {
    return <Icons.ApprovalOutlined fontSize="small" color="primary" />;
  }

  if (
    canMarkAsFullyExecuted &&
    canMarkAsFullyExecutedStatuses.includes(investorStatus)
  ) {
    return <CheckAllIcon fontSize="small" color="primary" />;
  }

  return <Icons.SendOutlined fontSize="small" color="primary" />;
}

function getWorkflowStepText(
  investor,
  allowMarkingFullyExecuted,
  hasSubdoc,
  closing,
) {
  if (!investor) return null;

  if (canSendToInvestorStatuses.includes(investor.status)) {
    return 'Send to investor';
  }

  if (canRemindStatuses.includes(investor.status)) {
    return 'Send reminder';
  }

  if (
    canApproveStatus.includes(investor.status) &&
    isApprovable(investor, hasSubdoc, closing)
  ) {
    return 'Approve';
  }

  if (
    allowMarkingFullyExecuted &&
    canMarkAsFullyExecutedStatuses.includes(investor.status)
  ) {
    return 'Mark as fully executed';
  }

  if (
    !allowMarkingFullyExecuted &&
    canSendToCountersignerStatuses.includes(investor.status)
  ) {
    return 'Send to countersigner';
  }

  if (investor.status === SENT_TO_COUNTERSIGNER_STATUS) {
    // no workflow actions is exposed for this state
    return null;
  }

  if (canResendClosingEmailStatuses.includes(investor.status)) {
    return 'Send closing email';
  }

  return null;
}

const INVESTOR_COL_KEY = 'investor';
const LEGAL_NAME_COL_KEY = 'legalName';
const STATUS_COL_KEY = 'status';
const COMMITMENT_COL_KEY = 'commitment';
const TAGS_KEY = 'tags';
const INDIVIDUAL_ACTIONS_COL_KEY = 'individualActions';
const RISK_RATING_COL_KEY = 'riskRating';

const sortableColumns = [
  INVESTOR_COL_KEY,
  LEGAL_NAME_COL_KEY,
  STATUS_COL_KEY,
  COMMITMENT_COL_KEY,
  RISK_RATING_COL_KEY,
];

function getColumnIds(
  hasSubdoc,
  showLegalName,
  diligenceEnabled,
  hasRiskRatings,
  hasTaggedInvestors,
) {
  let columnIds = [
    INVESTOR_COL_KEY,
    STATUS_COL_KEY,
    INDIVIDUAL_ACTIONS_COL_KEY,
  ];
  if (hasSubdoc) {
    columnIds = [
      INVESTOR_COL_KEY,
      COMMITMENT_COL_KEY,
      STATUS_COL_KEY,
      INDIVIDUAL_ACTIONS_COL_KEY,
    ];
  }

  if (showLegalName) {
    columnIds.splice(1, 0, LEGAL_NAME_COL_KEY);
  }

  if (diligenceEnabled && hasRiskRatings) {
    columnIds = [RISK_RATING_COL_KEY, ...columnIds];
  }

  if (hasTaggedInvestors) {
    columnIds.splice(columnIds.length - 3, 0, TAGS_KEY);
  }

  return columnIds;
}

const SEND_CLOSING_MODAL = 'SEND_CLOSING_MODAL';
const SEND_REMINDER_MODAL = 'SEND_REMINDER_MODAL';
const APPROVE_MODAL = 'APPROVE_MODAL';
const SEND_CLOSING_EMAIL_MODAL = 'SEND_CLOSING_EMAIL_MODAL';
const EXPORT_MODAL = 'EXPORT_MODAL';
const DILIGENCE_EXPORT_MODAL = 'DILIGENCE_EXPORT_MODAL';
const DOWNLOAD_ZIP_MODAL = 'DOWNLOAD_ZIP_MODAL';
const ADD_SIDE_LETTERS_MODAL = 'ADD_SIDE_LETTERS_MODAL';
const REMOVE_SIDE_LETTERS_MODAL = 'REMOVE_SIDE_LETTERS_MODAL';
const MOVE_CLOSING_MODAL = 'MOVE_CLOSING_MODAL';
const DILIGENCE_APPROVED_MODAL = 'DILIGENCE_APPROVED_MODAL';
const CANNOTE_DELETE_INVESTOR_MODAL = 'CANNOTE_DELETE_INVESTOR_MODAL';
const INVESTOR_TAGGING_MODAL = 'INVESTOR_TAGGING_MODAL';
const SEND_TO_COUNTERSIGNERS_MODAL = 'SEND_TO_COUNTERSIGNERS_MODAL';
const PREPARE_SIGNATURE_PAGES_MODAL = 'PREPARE_SIGNATURE_PAGES_MODAL';
const CANNOT_PREPARE_MODAL = 'CANNOT_PREPARE_MODAL';
const EDIT_NOTE_MODAL = 'EDIT_NOTE_MODAL';
const EDIT_NOTE_MODAL_VIEW_MODE = 'EDIT_NOTE_MODAL_VIEW_MODE';

function isApprovable(investor, hasSubdoc, closing) {
  if (!investor) return false;
  let approvable =
    investor.status === SIGNED_STATUS && !investor.hasLpRequestedReview;

  if (investor.hasDiligence) {
    if (hasSubdoc && !closing.hasCustomApprovals) {
      approvable =
        approvable &&
        investor.questionnaireApproved &&
        investor.diligenceApproved;
    } else {
      approvable = approvable && investor.diligenceApproved;
    }
  }

  return approvable;
}

export function LpDocumentList({
  closing,
  lpClosings,
  investorTagGroups,
  hasSigning,
  hasSubdoc,
  members,
  subdoc,
  onChange,
  handleAddInvestor,
  currency,
}) {
  const classes = useStyles();
  const drawerClasses = useDrawerStyles();
  const confirm = useConfirm();
  const { toast, successToast } = useToast();
  const [anchorEl, setAnchorEl] = useState(null);
  const [individualActionsUser, setIndividualActionsUser] = useState(null);

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [order, setOrder] = useState(['status', 'desc']);
  const [investors, setInvestors] = useState(() =>
    sortInvestors(order, lpClosings),
  );
  const [filters, setFilters] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [approvalErrorMsgs, setApprovalErrorMsgs] = useState(null);
  const { fundId, closingId } = useParams();
  const [fundEdit] = useFundEdit();
  const canEdit = fundEdit && !closing.readOnly;

  const [modal, setModal] = useState(null);
  const individualActionsMenuOpen = Boolean(anchorEl);

  const theme = useTheme();
  const darkTheme = useMemo(() => buildDarkTheme(theme), [theme]);

  const implicitStatusTags = useMemo(
    () =>
      genClosingStatusTags(lpClosings, closing?.customApprovalStatuses || []),
    [lpClosings, closing?.customApprovalStatuses],
  );

  const implicitRiskRatingTags = useMemo(
    () => genRiskRatingTags(lpClosings, closing?.diligenceEnabled),
    [lpClosings, closing?.diligenceEnabled],
  );

  const { OVERALL_INVESTOR_NOTE } = useFeatureFlags();

  const closeModal = () => {
    setModal(null);
    setAnchorEl(null);
  };

  const hasRiskRatings = investors.some((i) => i.riskRating);
  const hasTaggedInvestors = investors.some((i) => i.tags.length > 0);
  const columnIds = getColumnIds(
    hasSubdoc,
    closing.hasLegalNameQuestion,
    closing.diligenceEnabled,
    hasRiskRatings,
    hasTaggedInvestors,
  );

  const filteredInvestors = getFilteredInvestors(investors, filters);

  const allowMarkingFullyExecuted = !closing.hasCountersigning;

  function onSort() {
    const sortedInvestors = sortInvestors(order, investors);
    setInvestors(sortedInvestors);
  }

  function onChangeLocalStateReset() {
    setSelectedKeys([]);
    onSort();
  }

  function onChangeStateResetAndRequery() {
    onChangeLocalStateReset();
    onChange();
  }

  function handleActionChange() {
    onChangeStateResetAndRequery();
    setAnchorEl(null);
  }

  function getKey(investor) {
    return investor.id;
  }

  function getStatus(investor) {
    return getStatusText(investor.status, {
      hasSigning,
      hasLpRequestedReview: investor.hasLpRequestedReview,
      questionnaireApproved: investor.questionnaireApproved,
      diligenceApproved: investor.diligenceApproved,
      isDiligenceOnly: !closing.subscriptionDocumentName,
      customStatusText: investor.customState?.statusText,
    });
  }

  function isSelected(investor) {
    return selectedKeys.includes(getKey(investor));
  }

  const selected = investors.filter(isSelected);
  const usersToOperateOn = individualActionsUser
    ? [individualActionsUser]
    : selected;

  const showSkippingApprovalsWarning = usersToOperateOn.some(
    (investor) => closing.hasCustomApprovals && !investor.onFinalState,
  );

  const unsentSelectedKeys = usersToOperateOn
    .filter((investor) => investor.status === DRAFT_STATUS)
    .map(getKey);

  const approvableSelectedKeys = usersToOperateOn
    .filter((investor) => isApprovable(investor, hasSubdoc, closing))
    .map(getKey);

  // cspell:words remindable
  const remindableSelectedKeys = usersToOperateOn
    .filter((investor) => canRemindStatuses.includes(investor.status))
    .map(getKey);

  const resendableClosingEmailSelectedKeys = usersToOperateOn
    .filter((investor) =>
      canResendClosingEmailStatuses.includes(investor.status),
    )
    .map(getKey);

  const sendableToCountersignerSelectedKeys = usersToOperateOn
    .filter(
      (investor) =>
        canSendToCountersignerStatuses.includes(investor.status) &&
        !investor.pendingPreparation,
    )
    .map(getKey);

  const canMarkAsFullyExecutedSelectedKeys = usersToOperateOn
    .filter((investor) =>
      canMarkAsFullyExecutedStatuses.includes(investor.status),
    )
    .map(getKey);

  const canExportDiligenceDataKeys = usersToOperateOn
    .filter((investor) => investor.hasDiligence)
    .map(getKey);

  const canAddSideletterSelectedKeys = usersToOperateOn.map(getKey);

  const canRemoveSideletterSelectedKeys = usersToOperateOn
    .filter((investor) => canRemoveDocsFromInvestor(investor))
    .map(getKey);

  const multiDeletableSelected = usersToOperateOn.filter((investor) =>
    multiDeletableStatuses.includes(investor.status),
  );

  const singleDeletableSelectedStatuses = getUnique(
    usersToOperateOn
      .filter((investor) => !multiDeletableStatuses.includes(investor.status))
      .map(getStatus),
  );

  const unapprovableSelectedKeys = usersToOperateOn
    .filter((investor) => canUnapproveStatuses.includes(investor.status))
    .map(getKey);

  const canPrepareSignaturePagesSelectedKeys = usersToOperateOn
    .filter((investor) => canPrepareSignaturePages(investor))
    .map(getKey);

  // workflow actions
  function handleSend() {
    setModal(SEND_CLOSING_MODAL);
  }

  function handleSendClose() {
    closeModal();
  }

  function handleSendReminder() {
    setModal(SEND_REMINDER_MODAL);
  }

  function handleSendReminderClose() {
    closeModal();
  }

  function handleApprove() {
    setModal(APPROVE_MODAL);
  }

  function handleApproveClose() {
    closeModal();
    setApprovalErrorMsgs(null);
  }

  function handleSendToCountersigners() {
    setModal(SEND_TO_COUNTERSIGNERS_MODAL);
  }

  function handleSendToCountersignersClose() {
    closeModal();
  }

  function handleResendClosingEmail() {
    setModal(SEND_CLOSING_EMAIL_MODAL);
  }

  function handleResendClosingEmailClose() {
    closeModal();
  }

  // exports
  function handleExport() {
    setModal(EXPORT_MODAL);
  }

  function handleExportClose() {
    closeModal();
  }

  function handleDiligenceDataExport() {
    setModal(DILIGENCE_EXPORT_MODAL);
  }

  function handleDiligenceDataExportClose() {
    closeModal();
  }

  function handleZip() {
    setModal(DOWNLOAD_ZIP_MODAL);
  }

  function handleZipClose() {
    closeModal();
  }

  // modifications
  function handleEditNote() {
    setModal(EDIT_NOTE_MODAL);
  }

  function handleViewNote() {
    setModal(EDIT_NOTE_MODAL_VIEW_MODE);
  }

  function handleTagInvestors() {
    setModal(INVESTOR_TAGGING_MODAL);
  }

  function handleAddDoc() {
    setModal(ADD_SIDE_LETTERS_MODAL);
  }

  function handleAddDocClose() {
    closeModal();
  }

  function handleRemoveDoc() {
    setModal(REMOVE_SIDE_LETTERS_MODAL);
  }

  function handleRemoveDocClose() {
    closeModal();
  }

  function handlePrepareSignaturePages() {
    const count = usersToOperateOn.length;
    if (count === 1) {
      setModal(PREPARE_SIGNATURE_PAGES_MODAL);
    } else {
      setModal(CANNOT_PREPARE_MODAL);
    }
  }

  function handleDocumentPreparationClose() {
    closeModal();
  }

  function handleCannotPrepareClose() {
    closeModal();
  }

  function handleUnapprove() {
    const count = usersToOperateOn.length || 1;
    const plural = count > 1 ? 's' : '';
    const lpClosingIds = usersToOperateOn.map((i) => i.id);

    confirm({
      description: `Undo approval for ${count} investor${plural}? Be careful
      when unapproving investors that have already been countersigned as this
      will not send any notifications. This action can't be undone.`,
    })
      .then(() => {
        api
          .unapproveClosingDocs({ fundId, closingId, lpClosingIds })
          .then(() => {
            toast(`Unapproved ${count} investor${plural}.`);
            onChangeStateResetAndRequery();
            setAnchorEl(null);
          });
      })
      .catch(() => {});
  }

  function handleMove() {
    setModal(MOVE_CLOSING_MODAL);
  }

  function handleMoveClose() {
    closeModal();
  }

  function handleDiligenceApprover() {
    setModal(DILIGENCE_APPROVED_MODAL);
  }

  function handleDiligenceApproverClose() {
    closeModal();
  }

  function handleDelete() {
    const count = usersToOperateOn.length;
    if (count === multiDeletableSelected.length || count === 1) {
      const plural = count > 1 ? 's' : '';
      const lpClosingIds = usersToOperateOn.map((i) => i.id);

      confirm({
        description:
          `Delete ${count} investor${plural}? ` +
          "Deleted investors will no longer have access to their documents. This action can't be undone.",
        destructive: true,
      })
        .then(() => {
          api
            .deleteClosingDocs({ fundId, closingId, lpClosingIds })
            .then(() => {
              toast(`Deleted ${count} investor${plural}.`);
              onChangeStateResetAndRequery();
              setAnchorEl(null);
            });
        })
        .catch(() => {});
    } else {
      setModal(CANNOTE_DELETE_INVESTOR_MODAL);
    }
  }

  function handleMarkAsFullyExecuted() {
    const count = usersToOperateOn.length;
    const plural = count > 1 ? 's' : '';
    const lpClosingIds = usersToOperateOn.map((i) => i.id);
    const markDescription = `Mark ${count} investor${plural} as fully executed?`;
    const emailWarning =
      'This will email the investors a copy of their completed documents.';

    confirm({
      description: closing.disableFullyExecutedEmail
        ? markDescription
        : `${markDescription} ${emailWarning}`,
      confirmationText: 'Mark as fully executed',
    })
      .then(() => {
        api
          .finalizeClosingDocs({ fundId, closingId, lpClosingIds })
          .then(() => {
            successToast(
              `Marked ${count} investor${plural} as fully executed.`,
            );
            onChangeStateResetAndRequery();
            setAnchorEl(null);
          });
      })
      .catch(() => {});
  }

  function saveApprove() {
    const lpClosingIds = usersToOperateOn.map((i) => i.id);
    const plural = lpClosingIds.length > 1 ? 's' : '';
    setApproveLoading(true);
    setApprovalErrorMsgs(null);

    api
      .approveClosingDocs({ fundId, closingId, lpClosingIds })
      .then(() => {
        successToast(`Approved ${lpClosingIds.length} investor${plural}.`);
        onChangeStateResetAndRequery();
        handleApproveClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setApprovalErrorMsgs(error.response.data);
        }
      })
      .finally(() => {
        setAnchorEl(null);
        setApproveLoading(false);
      });
  }

  function unselectAll() {
    setSelectedKeys([]);
  }

  function unselectConflictingSend() {
    setSelectedKeys(unsentSelectedKeys);
  }

  function unselectConflictingSendReminder() {
    setSelectedKeys(remindableSelectedKeys);
  }

  function unselectConflictingResendClosingEmail() {
    setSelectedKeys(resendableClosingEmailSelectedKeys);
  }

  function unselectConflictingApprove() {
    setSelectedKeys(approvableSelectedKeys);
  }

  function unselectConflictingSendToCountersigner() {
    setSelectedKeys(sendableToCountersignerSelectedKeys);
  }

  function unselectConflictingMarkAsFullyExecuted() {
    setSelectedKeys(canMarkAsFullyExecutedSelectedKeys);
  }

  function unselectConflictingAddSideletter() {
    setSelectedKeys(canAddSideletterSelectedKeys);
  }

  function unselectConflictingRemoveSideletter() {
    setSelectedKeys(canRemoveSideletterSelectedKeys);
  }

  function unselectConflictingUnapprove() {
    setSelectedKeys(unapprovableSelectedKeys);
  }

  function unselectConflictingPrepareSignaturePages() {
    setSelectedKeys(canPrepareSignaturePagesSelectedKeys);
  }

  function SendButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        data-test="send"
        numSelected={usersToOperateOn.length}
        numActionable={unsentSelectedKeys.length}
        onClick={handleSend}
        onUnselect={unselectConflictingSend}
        icon={<Icons.Send />}
        actionPhrase="can be sent"
        unselectLinkProps={{ 'data-test': 'send-investor-unselect' }}
      >
        Send to investor
      </ListBulkAction>
    );
  }

  function SendReminderButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        data-test="send-reminder"
        numSelected={usersToOperateOn.length}
        numActionable={remindableSelectedKeys.length}
        onClick={handleSendReminder}
        onUnselect={unselectConflictingSendReminder}
        icon={<Icons.Send />}
        actionPhrase="can be re-sent"
      >
        Send reminder
      </ListBulkAction>
    );
  }

  function ApproveButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        data-test="approve"
        numSelected={usersToOperateOn.length}
        numActionable={approvableSelectedKeys.length}
        onClick={handleApprove}
        onUnselect={unselectConflictingApprove}
        icon={<Icons.Approval />}
        actionPhrase="can be approved"
      >
        Approve
      </ListBulkAction>
    );
  }

  function SendToCountersignerButton() {
    if (!canEdit) {
      return null;
    }
    if (!closing.hasCountersigning) {
      return null;
    }

    return (
      <ListBulkAction
        data-test="send-to-countersigner"
        numSelected={usersToOperateOn.length}
        numActionable={sendableToCountersignerSelectedKeys.length}
        onClick={handleSendToCountersigners}
        onUnselect={unselectConflictingSendToCountersigner}
        icon={<Icons.Send />}
        actionPhrase="can be sent for countersignature"
        unselectLinkProps={{
          'data-test': 'unselect-others-send-to-countersigner',
        }}
      >
        Send to countersigner
      </ListBulkAction>
    );
  }

  function ResendClosingEmail() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        data-test="resend-closing-email"
        numSelected={usersToOperateOn.length}
        numActionable={resendableClosingEmailSelectedKeys.length}
        onClick={handleResendClosingEmail}
        onUnselect={unselectConflictingResendClosingEmail}
        icon={<Icons.Send />}
        actionPhrase="can be sent"
      >
        Send closing email
      </ListBulkAction>
    );
  }

  function MarkAsFullyExecutedButton() {
    if (!canEdit) {
      return null;
    }
    if (!allowMarkingFullyExecuted) {
      return null;
    }

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={canMarkAsFullyExecutedSelectedKeys.length}
        onClick={handleMarkAsFullyExecuted}
        onUnselect={unselectConflictingMarkAsFullyExecuted}
        icon={<CheckAllIcon />}
        actionPhrase="can be marked as fully executed"
      >
        Mark as fully executed
      </ListBulkAction>
    );
  }

  function ExportButton() {
    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={usersToOperateOn.length}
        onClick={handleExport}
        onUnselect={() => {}}
        icon={<DatabaseExportIcon />}
        actionPhrase="can be exported"
      >
        Export data
      </ListBulkAction>
    );
  }

  function ExportDiligenceDataButton() {
    if (!closing.diligenceEnabled) {
      return null;
    }

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={canExportDiligenceDataKeys.length}
        onClick={handleDiligenceDataExport}
        onUnselect={() => {
          setSelectedKeys(canExportDiligenceDataKeys);
        }}
        icon={<DatabaseExportIcon />}
        actionPhrase="can be exported"
      >
        Export diligence data
      </ListBulkAction>
    );
  }

  function ZipButton() {
    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={usersToOperateOn.length}
        onClick={handleZip}
        onUnselect={() => {}}
        icon={<Icons.FileDownloadOutlined />}
        actionPhrase="can be zipped"
      >
        Download documents (.zip)
      </ListBulkAction>
    );
  }

  function AddOrEditNoteButton() {
    if (!OVERALL_INVESTOR_NOTE || !canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={usersToOperateOn.length}
        onClick={handleEditNote}
        onUnselect={() => {}}
        icon={<Icons.StickyNote2Outlined />}
      >
        Add or edit note
      </ListBulkAction>
    );
  }

  function TagInvestorsButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={usersToOperateOn.length}
        onClick={handleTagInvestors}
        onUnselect={() => {}}
        icon={<LabelIcon />}
      >
        Tag investors
      </ListBulkAction>
    );
  }

  function AttachSideletterButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        variant="contained"
        numSelected={usersToOperateOn.length}
        numActionable={canAddSideletterSelectedKeys.length}
        onClick={handleAddDoc}
        onUnselect={unselectConflictingAddSideletter}
        icon={<FileDocumentPlus />}
        actionPhrase="can be given a document"
      >
        Add document
      </ListBulkAction>
    );
  }

  function RemoveSideletterButton() {
    if (!canEdit) {
      return null;
    }

    const isPlural = canRemoveSideletterSelectedKeys.length > 1;
    const actionPhrase = `${isPlural ? 'have' : 'has a'} removable document${
      isPlural ? 's' : ''
    }`;

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={canRemoveSideletterSelectedKeys.length}
        onClick={handleRemoveDoc}
        onUnselect={unselectConflictingRemoveSideletter}
        icon={<FileDocumentMinus />}
        actionPhrase={actionPhrase}
      >
        Remove document
      </ListBulkAction>
    );
  }

  function PrepareSignaturePagesButton() {
    if (!canEdit) {
      return null;
    }

    const isPlural = canPrepareSignaturePagesSelectedKeys.length > 1;
    const actionPhrase = `can have their document${
      isPlural ? 's' : ''
    } prepared`;

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={canPrepareSignaturePagesSelectedKeys.length}
        onClick={handlePrepareSignaturePages}
        onUnselect={unselectConflictingPrepareSignaturePages}
        icon={<FileDocumentEdit />}
        actionPhrase={actionPhrase}
      >
        Prepare signature pages
      </ListBulkAction>
    );
  }

  function UnapproveButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={unapprovableSelectedKeys.length}
        onClick={handleUnapprove}
        onUnselect={unselectConflictingUnapprove}
        icon={<Icons.Undo />}
        actionPhrase="can be unapproved"
      >
        Undo approval
      </ListBulkAction>
    );
  }

  function MoveClosingButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={selectedKeys.length}
        onClick={handleMove}
        onUnselect={() => {}}
        icon={<Icons.DriveFileMove />}
        actionPhrase="can be moved"
      >
        Move to new closing
      </ListBulkAction>
    );
  }

  function ChangeDiligenceApproverButton() {
    if (!canEdit || !closing.diligenceEnabled) {
      return null;
    }

    // Because we never restrict when we can change a diligence
    // approver, we don't need to supply an onUnselect handler
    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={usersToOperateOn.length}
        onClick={handleDiligenceApprover}
        onUnselect={() => {}}
        icon={<Icons.Edit />}
        actionPhrase="can be altered"
      >
        Change diligence reviewer
      </ListBulkAction>
    );
  }

  function DeleteButton() {
    if (!canEdit) {
      return null;
    }

    return (
      <ListBulkAction
        numSelected={usersToOperateOn.length}
        numActionable={usersToOperateOn.length}
        onClick={handleDelete}
        onUnselect={() => {}}
        icon={<Icons.Delete />}
        actionPhrase="can be deleted"
      >
        Delete
      </ListBulkAction>
    );
  }

  const NoInvestorsFromFilter = () => (
    <EmptyState
      title="No matching investors"
      text="Adjust or clear all filters to continue."
      ctaPermission
      ctaText="Clear all filters"
      ctaOnClick={() => {
        setFilters([]);
        setSelectedKeys([]);
      }}
    />
  );

  useEffect(() => {
    setInvestors(sortInvestors(order, lpClosings));
  }, [lpClosings, ...order]);

  const commitmentColumnHeadLabel = `Commitment (${currency.code})`;
  let investorCountText = `${investors.length} investor${
    investors.length > 1 ? 's' : ''
  }`;
  if (filteredInvestors.length < investors.length) {
    investorCountText = `Showing ${filteredInvestors.length} of ${investorCountText}`;
  }

  const currentlySentToCountersigner =
    individualActionsUser?.status === SENT_TO_COUNTERSIGNER_STATUS;
  const pendingPreparation =
    individualActionsUser?.status === APPROVED_STATUS &&
    individualActionsUser?.pendingPreparation;
  const notApprovable =
    canApproveStatus.includes(individualActionsUser?.status) &&
    !isApprovable(individualActionsUser, hasSubdoc, closing);

  return (
    <div>
      <IndividualActionsMenu
        keepOpen={Boolean(modal)}
        canEdit={canEdit}
        menuAnchor={anchorEl}
        investor={individualActionsUser}
        hasSubdoc={hasSubdoc}
        diligenceEnabled={closing.diligenceEnabled}
        menuOpen={individualActionsMenuOpen}
        hideWorkflowAction={
          currentlySentToCountersigner || pendingPreparation || notApprovable
        }
        handleMenuClose={() => {
          setAnchorEl(null);
        }}
        handleExited={() => {
          // avoids issue of workflow step ui changing as menu closes
          setIndividualActionsUser(null);
        }}
        workflowStepText={getWorkflowStepText(
          individualActionsUser,
          allowMarkingFullyExecuted,
          hasSubdoc,
          closing,
        )}
        WorkflowStepIcon={getWorkflowIcon(
          individualActionsUser?.status,
          allowMarkingFullyExecuted,
        )}
        handleWorkflowOperation={() => {
          const { status } = individualActionsUser;

          if (canSendToInvestorStatuses.includes(status)) {
            handleSend();
          } else if (canRemindStatuses.includes(status)) {
            handleSendReminder();
          } else if (
            canApproveStatus.includes(status) &&
            isApprovable(individualActionsUser, hasSubdoc, closing)
          ) {
            handleApprove();
          } else if (
            !allowMarkingFullyExecuted &&
            canSendToCountersignerStatuses.includes(status)
          ) {
            handleSendToCountersigners();
          } else if (
            allowMarkingFullyExecuted &&
            canMarkAsFullyExecutedStatuses.includes(status)
          ) {
            handleMarkAsFullyExecuted();
          } else if (status === SENT_TO_COUNTERSIGNER_STATUS) {
            // no workflow action is exposed for this state
          } else if (canResendClosingEmailStatuses.includes(status)) {
            handleResendClosingEmail();
          }
        }}
        handleExport={handleExport}
        handleDiligenceExport={handleDiligenceDataExport}
        handleDelete={handleDelete}
        handleZip={handleZip}
        handleEditNote={handleEditNote}
        handleTagInvestor={handleTagInvestors}
        handleMove={handleMove}
        handleAddDoc={handleAddDoc}
        handleRemoveDoc={handleRemoveDoc}
        handlePrepareSignaturePages={handlePrepareSignaturePages}
        handleChangeDiligenceApprover={handleDiligenceApprover}
        handleUndoApproval={handleUnapprove}
      />

      <div className={classes.actionRow}>
        <LpFiltersPopOver
          investorTagGroups={investorTagGroups}
          statusTags={implicitStatusTags}
          riskRatingTags={implicitRiskRatingTags}
          filters={filters}
          hasSigning={hasSigning}
          onChange={(f) => {
            setFilters(f);
            setSelectedKeys([]);
          }}
        />

        <div className={classes.investorCount}>
          <PassTypography variant="body">{investorCountText}</PassTypography>

          {canEdit ? (
            <Button
              data-test="cta"
              variant="primary"
              onClick={handleAddInvestor}
            >
              Add investors
            </Button>
          ) : null}
        </div>
      </div>

      <TableContainer>
        <ThemeProvider theme={darkTheme}>
          <Drawer
            anchor="right"
            open={selectedKeys.length > 0}
            onClose={() => {}}
            variant="persistent"
            classes={drawerClasses}
          >
            <div className={classes.actionsList}>
              <div>
                <Typography align="center" className={classes.listSelected}>
                  <span className={classes.listNumberSelected}>
                    {usersToOperateOn.length}
                  </span>
                  <span className={classes.listTextSelected}>
                    {' '}
                    investor
                    {usersToOperateOn.length === 1 ? '' : 's'} selected
                  </span>
                </Typography>

                <DisplayLink
                  onClick={() => {
                    unselectAll();
                  }}
                >
                  <Typography align="center" className={classes.unselectAll}>
                    Unselect all
                  </Typography>
                </DisplayLink>
              </div>
              <List>
                {canEdit ? (
                  <>
                    <Divider className={classes.listDivider} />
                    <Typography className={classes.listHeader}>
                      Workflow
                    </Typography>
                    {SendButton()}
                    {SendReminderButton()}
                    {ApproveButton()}
                    {SendToCountersignerButton()}
                    {MarkAsFullyExecutedButton()}
                    {ResendClosingEmail()}
                  </>
                ) : null}

                <>
                  <Divider className={classes.listDivider} />
                  <Typography className={classes.listHeader}>View</Typography>
                  {hasSubdoc ? <>{ExportButton()}</> : null}
                  {ExportDiligenceDataButton()}
                  {ZipButton()}
                </>

                {canEdit ? (
                  <>
                    <Divider className={classes.listDivider} />
                    <Typography className={classes.listHeader}>
                      Modify
                    </Typography>
                    {AddOrEditNoteButton()}
                    {TagInvestorsButton()}
                    {AttachSideletterButton()}
                    {RemoveSideletterButton()}
                    {PrepareSignaturePagesButton()}
                    {UnapproveButton()}
                    {MoveClosingButton()}
                    {ChangeDiligenceApproverButton()}
                    {DeleteButton()}
                  </>
                ) : null}
                <Divider className={classes.listDivider} />
              </List>
            </div>
          </Drawer>
        </ThemeProvider>

        <TableActions fullWidth>
          <div
            style={{
              visibility: selectedKeys.length > 0 ? 'hidden' : '',
              marginTop: '-8px',
            }}
          >
            <FormHelperText>
              Select checkboxes to perform bulk actions
            </FormHelperText>
          </div>
        </TableActions>

        <SmartTable
          noTopMargin
          rows={filteredInvestors}
          getKey={getKey}
          columnIds={columnIds}
          sortable={sortableColumns}
          canEdit
          checkboxes
          selected={selectedKeys}
          order={order}
          onChangeOrder={setOrder}
          onChangeSelected={setSelectedKeys}
          onSort={onSort}
          genClickUrl={(lpClosingId) =>
            urls.reviewUrl({ fundId, closingId, lpClosingId })
          }
          CustomEmptyState={NoInvestorsFromFilter}
          getSmartTableRowClass={(investor) =>
            // to ensure that there is no flicker where a row still seems to be
            // selected as the individual action menu closes, also make sure
            // that the menu is open
            Boolean(anchorEl) && individualActionsUser?.id === investor.id
              ? classes.individualActionsRowSelected
              : null
          }
          elevation={2}
          renderHeadLabel={(columnId) => {
            switch (columnId) {
              case INVESTOR_COL_KEY:
                return 'Investor';
              case STATUS_COL_KEY:
                return 'Status';
              case COMMITMENT_COL_KEY:
                return commitmentColumnHeadLabel;
              case TAGS_KEY:
                return 'Tags';
              case RISK_RATING_COL_KEY:
                return 'Risk rating';
              case LEGAL_NAME_COL_KEY:
                return 'Legal name';
              case INDIVIDUAL_ACTIONS_COL_KEY:
                return null;
              default:
                return null;
            }
          }}
          renderBodyCell={(columnId, investor) => {
            switch (columnId) {
              case RISK_RATING_COL_KEY:
                return (
                  <TableCell key={columnId} scope="row">
                    <RiskRatingChip riskRating={investor.riskRating} />
                  </TableCell>
                );
              case INVESTOR_COL_KEY:
                return (
                  <TableCell key={columnId} scope="row">
                    <PassTypography>
                      <Link
                        href={urls.reviewQuestionnaireUrl({
                          fundId,
                          closingId,
                          lpClosingId: investor.id,
                        })}
                        htmlProps={{ 'data-test': 'view-answers' }}
                      >
                        {investor.lpName}
                      </Link>
                    </PassTypography>
                  </TableCell>
                );
              case LEGAL_NAME_COL_KEY:
                return (
                  <TableCell key={columnId} scope="row">
                    {investor.legalName}
                  </TableCell>
                );
              case STATUS_COL_KEY:
                return (
                  <TableCell key={columnId}>
                    <StatusChip
                      status={investor.status}
                      sentDate={investor.sentDate}
                      completedDate={investor.completedDate}
                      hasSigning={hasSigning}
                      lastSentEvent={investor.lastSentEvent}
                      fullyExecutedEmailSent={investor.fullyExecutedEmailSent}
                      hasLpRequestedReview={investor.hasLpRequestedReview}
                      questionnaireApproved={investor.questionnaireApproved}
                      diligenceApproved={investor.diligenceApproved}
                      isDiligenceOnly={!closing.subscriptionDocumentName}
                      customStatusText={investor.customState?.statusText}
                      waitingOnFirstSigner={investor.waitingOnFirstSigner}
                      pendingPreparation={investor.pendingPreparation}
                    />
                  </TableCell>
                );
              case COMMITMENT_COL_KEY: {
                return (
                  <TableCell key={columnId}>
                    <AcceptedCommitment
                      commitment={investor.commitment}
                      acceptedCommitment={investor.acceptedCommitment}
                      currency={currency}
                    />
                  </TableCell>
                );
              }
              case TAGS_KEY:
                return (
                  <TableCell key={columnId} scope="row">
                    <InvestorTagsCellContainer
                      investor={investor}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </TableCell>
                );
              case INDIVIDUAL_ACTIONS_COL_KEY:
                return (
                  <TableCell key={columnId} actions>
                    {OVERALL_INVESTOR_NOTE && investor.mostRecentNote ? (
                      <Button
                        variant="icon"
                        aria-label="View note"
                        size="large"
                        onClick={() => {
                          setIndividualActionsUser(investor);
                          handleViewNote();
                        }}
                      >
                        <Icons.StickyNote2Outlined />
                      </Button>
                    ) : null}
                    <Button
                      variant="icon"
                      size="large"
                      onClick={(e) => {
                        if (anchorEl) {
                          setAnchorEl(null);
                          return;
                        }
                        setAnchorEl(e.currentTarget);
                        setIndividualActionsUser(investor);
                      }}
                      aria-label="More actions"
                    >
                      <Icons.MoreVertOutlined />
                    </Button>
                  </TableCell>
                );
              default:
                return null;
            }
          }}
        />
      </TableContainer>

      {/* workflow actions */}
      <SendDialog
        open={modal === SEND_CLOSING_MODAL}
        investors={usersToOperateOn}
        diligenceEnabled={closing.diligenceEnabled}
        handleClose={handleSendClose}
        onChange={handleActionChange}
        closing={closing}
        subscriptionDoc={subdoc}
        members={members}
      />

      <SendReminderDialog
        open={modal === SEND_REMINDER_MODAL}
        investors={usersToOperateOn}
        handleClose={handleSendReminderClose}
        onChange={handleActionChange}
      />

      <ApproveModal
        open={modal === APPROVE_MODAL}
        onApproveClick={saveApprove}
        investors={usersToOperateOn}
        handleClose={handleApproveClose}
        approvalLoading={approveLoading}
        showSkippingApprovalsWarning={showSkippingApprovalsWarning}
        errorMsgs={approvalErrorMsgs}
      />

      <SendClosingEmailDialog
        open={modal === SEND_CLOSING_EMAIL_MODAL}
        investors={usersToOperateOn}
        handleClose={handleResendClosingEmailClose}
        onChange={handleActionChange}
      />

      {/* exports */}
      <SubdocDataExportModal
        open={modal === EXPORT_MODAL}
        investors={usersToOperateOn}
        handleClose={handleExportClose}
        onExport={handleActionChange}
      />

      <DiligenceDataExportModal
        open={modal === DILIGENCE_EXPORT_MODAL}
        investors={usersToOperateOn}
        handleClose={handleDiligenceDataExportClose}
        onExport={handleActionChange}
      />

      <ZipDialog
        open={modal === DOWNLOAD_ZIP_MODAL}
        investors={usersToOperateOn}
        handleClose={handleZipClose}
        onDownload={handleActionChange}
      />

      {/* modifications */}
      <DocumentUploadPage
        open={modal === ADD_SIDE_LETTERS_MODAL}
        lpClosingIds={usersToOperateOn.map((s) => s.id)}
        handleClose={handleAddDocClose}
        onChange={handleActionChange}
        hasSigning={closing.hasSigning}
        hasFirstSigner={closing.hasFirstSigner}
        hasSecondSigner={closing.hasSecondSigner}
        hasThirdSigner={closing.hasThirdSigner}
        hasFourthSigner={closing.hasFourthSigner}
        hasFifthSigner={closing.hasFifthSigner}
        hasSixthSigner={closing.hasSixthSigner}
        hasFirstCounterSigner={closing.numberOfCountersigners >= 1}
        hasSecondCounterSigner={closing.numberOfCountersigners >= 2}
        hasThirdCounterSigner={closing.numberOfCountersigners >= 3}
        lpClosings={usersToOperateOn}
        dataModelType={SIDE_LETTER}
        docTypesToDisplay={EXPOSED_SIDE_LETTER_TYPES}
        allowInvestorTypeInEditMode={closing.hasInvestorTypeQuestion}
      />

      <RemoveDocumentDialog
        open={modal === REMOVE_SIDE_LETTERS_MODAL}
        investors={usersToOperateOn}
        handleClose={handleRemoveDocClose}
        onChange={handleActionChange}
      />

      <MoveToClosingDialog
        open={modal === MOVE_CLOSING_MODAL}
        investors={usersToOperateOn}
        handleClose={handleMoveClose}
        onChange={handleActionChange}
        members={members}
      />

      <ChangeDiligenceApproverDialog
        open={modal === DILIGENCE_APPROVED_MODAL}
        defaultDiligenceApproverId={closing.defaultDiligenceApproverId}
        investors={usersToOperateOn}
        handleClose={handleDiligenceApproverClose}
        onChange={handleActionChange}
        members={members}
      />

      <CannotDeleteDialog
        open={modal === CANNOTE_DELETE_INVESTOR_MODAL}
        statusesToDisplay={singleDeletableSelectedStatuses}
        handleClose={() => {
          closeModal();
        }}
      />

      <InvestorTaggingModal
        open={modal === INVESTOR_TAGGING_MODAL}
        selectedInvestors={usersToOperateOn}
        investorTagGroups={investorTagGroups}
        handleClose={closeModal}
        refreshTaggingData={onChange}
      />

      <SendToCountersignersModal
        open={modal === SEND_TO_COUNTERSIGNERS_MODAL}
        handleClose={handleSendToCountersignersClose}
        investors={usersToOperateOn}
        onChange={handleActionChange}
        disableFullyExecutedEmail={closing.disableFullyExecutedEmail}
        subdoc={subdoc}
        members={members}
      />

      <PrepareSignaturePagesModal
        open={modal === PREPARE_SIGNATURE_PAGES_MODAL}
        investor={usersToOperateOn[0]}
        handleClose={handleDocumentPreparationClose}
        onChange={handleActionChange}
        hasFirstCounterSigner={closing.numberOfCountersigners >= 1}
        hasSecondCounterSigner={closing.numberOfCountersigners >= 2}
        hasThirdCounterSigner={closing.numberOfCountersigners >= 3}
      />

      <CannotPrepareModal
        open={modal === CANNOT_PREPARE_MODAL}
        handleClose={handleCannotPrepareClose}
      />

      {OVERALL_INVESTOR_NOTE ? (
        <EditNoteModal
          showNumInvestors
          open={
            modal === EDIT_NOTE_MODAL || modal === EDIT_NOTE_MODAL_VIEW_MODE
          }
          handleClose={closeModal}
          onChange={handleActionChange}
          investors={usersToOperateOn}
          onExited={() => setIndividualActionsUser(null)}
          startInViewMode={modal === EDIT_NOTE_MODAL_VIEW_MODE}
        />
      ) : null}
    </div>
  );
}
