import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  Link,
  Menu,
  MenuItem,
  Icons,
  UIKitSettingsProvider,
  Chip,
} from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { PoweredBy } from 'components/powered_by';
import { useMe } from 'services/providers/me';
import { usePassthroughSpecificRedirect } from 'services/passthrough_specific_redirect';
import { useHotkeys } from 'react-hotkeys-hook';
import { getIsAppleDevice } from 'services/utils';
import { SupportPINModal } from './SupportPINModal';

const useStyles = makeStyles((theme) => ({
  menu: {
    zIndex: theme.zIndex.appBar + 1,
  },
  header: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    gap: theme.spacing(0.25),
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2, 2, 1.5, 2),
  },
  supportPinFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export function AccountNavButton({ useMobileUI }) {
  const [me] = useMe();
  const { firstName, lastName, ownsProfiles, email } = me;
  const classes = useStyles();
  const history = useHistory();
  const whiteLabelConfig = useWhiteLabelConfig();
  const [open, setOpen] = React.useState(false);
  const [supportPINModalOpen, setSupportPINModalOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = React.useState(null);
  const redirectTo = usePassthroughSpecificRedirect();

  useHotkeys(
    'mod+s',
    () => {
      setSupportPINModalOpen(true);
    },
    { preventDefault: true }, // Prevent builtin browser mod + s behavior
  );

  const fullName = `${firstName || ''} ${lastName || ''}`.trim();

  const openMenu = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function redirect() {
    history.push(urls.signinUrl({}));
  }

  function resetSupport() {
    if (window.Kustomer) {
      window.Kustomer.logout();
    }
  }

  function logout() {
    api.logout().then(() => {
      resetSupport();
      redirect();
    });
  }

  const showManageProfilesButton = ownsProfiles;
  const showPoweredBy = !!whiteLabelConfig?.logos?.app;
  const showUserAccountItems = !redirectTo;

  const header = (
    <div className={classes.header}>
      <Typography size="small">{email}</Typography>
      {redirectTo ? (
        <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
          <Typography size="small">
            <Link href={redirectTo} variant="external">
              Manage your account in Passthrough
            </Link>
          </Typography>
        </UIKitSettingsProvider>
      ) : null}
    </div>
  );

  const isAppleDevice = getIsAppleDevice();

  return (
    <>
      {useMobileUI ? (
        <Button
          id="account-nav-button"
          variant="icon"
          onClick={openMenu}
          ref={setAnchorRef}
          htmlProps={{ 'data-test': 'account' }}
          aria-label="Account menu"
        >
          <Icons.PersonOutline />
        </Button>
      ) : (
        <Button
          id="account-nav-button"
          variant="text"
          startIcon={<Icons.PersonOutline />}
          onClick={openMenu}
          ref={setAnchorRef}
          htmlProps={{ 'data-test': 'account' }}
        >
          {fullName}
        </Button>
      )}

      <Menu
        id="menu-appbar"
        header={header}
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        {showUserAccountItems
          ? [
              showManageProfilesButton ? (
                <MenuItem
                  key="Manage investor profiles"
                  href={urls.MANAGE_PROFILES_URL}
                  icon={<Icons.ManageAccountsOutlined fontSize="small" />}
                  text="Manage investor profiles"
                />
              ) : null,
              <MenuItem
                key="Account settings"
                href={urls.USER_SETTINGS_URL}
                icon={<Icons.SettingsOutlined fontSize="small" />}
                text="Account settings"
              />,
              <MenuItem
                key="Support PIN"
                onClick={() => setSupportPINModalOpen(true)}
                icon={<Icons.SupportAgent fontSize="small" />}
                text={
                  <span className={classes.supportPinFlex}>
                    Support PIN
                    <Chip
                      variant="neutral"
                      label={isAppleDevice ? '⌘S' : 'Ctrl+S'}
                      size="small"
                    />
                  </span>
                }
              />,
            ]
          : null}
        <MenuItem
          onClick={logout}
          icon={<Icons.ExitToApp fontSize="small" />}
          text="Sign out"
          htmlProps={{ 'data-test': 'logout' }}
        />

        <Divider className={classes.divider} />

        <div className={classes.links}>
          <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
            <Typography size="small">
              <Link
                href="https://passthrough.com/privacy"
                variant="external"
                externalIcon={false}
              >
                Privacy
              </Link>
              {' • '}
              <Link
                href="https://passthrough.com/terms"
                variant="external"
                externalIcon={false}
              >
                Terms
              </Link>
              {' • '}
              <Link
                href="https://passthrough.com/cookie-policy"
                variant="external"
                externalIcon={false}
              >
                Cookie Policy
              </Link>
            </Typography>
          </UIKitSettingsProvider>
          {showPoweredBy ? <PoweredBy /> : null}
        </div>
      </Menu>

      <SupportPINModal
        open={supportPINModalOpen}
        onClose={() => {
          setSupportPINModalOpen(false);
        }}
      />
    </>
  );
}
