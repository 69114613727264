import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Prompt } from 'react-router-dom';

import { Dialog } from 'components/dialog/index';
import { UploadPageContent } from './content';

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export function DocumentUploadPage({
  open,
  onChange,
  createFile,
  handleClose,
  hasSigning,
  hasFirstSigner,
  hasSecondSigner,
  hasThirdSigner,
  hasFourthSigner,
  hasFifthSigner,
  hasSixthSigner,
  hasFirstCounterSigner,
  hasSecondCounterSigner,
  hasThirdCounterSigner,
  dataModelType,
  allowInvestorTypeInEditMode,
  isFundDocumentUpload = false,
  docTypesToDisplay = [],
  lpClosings = [],
}) {
  const dialogClasses = useDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      classes={dialogClasses}
    >
      <Prompt message="You have unsaved progress. Are you sure you want to leave?" />
      <UploadPageContent
        handleClose={handleClose}
        onChange={onChange}
        createFile={createFile}
        hasSigning={hasSigning}
        hasFirstSigner={hasFirstSigner}
        hasSecondSigner={hasSecondSigner}
        hasThirdSigner={hasThirdSigner}
        hasFourthSigner={hasFourthSigner}
        hasFifthSigner={hasFifthSigner}
        hasSixthSigner={hasSixthSigner}
        hasFirstCounterSigner={hasFirstCounterSigner}
        hasSecondCounterSigner={hasSecondCounterSigner}
        hasThirdCounterSigner={hasThirdCounterSigner}
        dataModelType={dataModelType}
        isFundDocumentUpload={isFundDocumentUpload}
        lpClosings={lpClosings}
        docTypesToDisplay={docTypesToDisplay}
        allowInvestorTypeInEditMode={allowInvestorTypeInEditMode}
      />
    </Dialog>
  );
}
