import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons } from '@passthrough/uikit';
import * as api from 'services/api';
import { EmailChip } from './email_chip';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    backgroundColor: 'white',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

export function SignInEnterPasswordStep({
  email,
  errorComponent,
  onConfirm,
  onSwitch,
}) {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    api
      .loginWithPassword({ email, password })
      .then(() => {
        setLoading(false);
        onConfirm();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          setPasswordError(error.response.data.password || error.response.data);
        } else if (error.response?.status === 401) {
          setPasswordError('Incorrect email or password');
        } else if (error.response?.status === 429) {
          setPasswordError(
            'Too many sign in attempts. Please wait a few minutes before trying again.',
          );
        }
      });
  }

  return (
    <>
      {errorComponent}
      <form
        className={classes.form}
        onSubmit={(e) => handleSubmit(e)}
        noValidate
      >
        <div className={classes.top}>
          <EmailChip email={email} onClick={onSwitch} />
          <TextField
            InputProps={{
              className: classes.input,
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="Password"
            name="password"
            autoComplete="password"
            autoFocus
            error={!!passwordError}
            helperText={passwordError}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className={classes.submit}>
          <Button
            type="submit"
            fullWidth
            variant="primary"
            endIcon={<Icons.ArrowForward />}
            loading={loading}
            disabled={loading}
            htmlProps={{ style: { maxWidth: '100%' } }}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
}
