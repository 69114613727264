import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    margin: theme.spacing(1),
  },
}));

export const ProfileSwitchRadioForm = ({
  profileDisplayName,
  existingAnswersChoice,
  setExistingAnswersChoice,
}) => {
  const classes = useStyles();
  const restartDescription = profileDisplayName
    ? `Clear all my answers and use ${profileDisplayName}'s profile data.`
    : 'Clear all my answers and start from scratch.';
  return (
    <div className={classes.radioContainer}>
      <RadioCardContainer
        selectedChoice={existingAnswersChoice}
        setSelectedChoice={setExistingAnswersChoice}
        flexGrowContainer={false}
      >
        <RadioCardChoice
          choiceKey="restart"
          choiceValue="restart"
          choiceTitle="Start over"
          choiceSubtitle={restartDescription}
          choiceBackground="white"
        />
        <RadioCardChoice
          choiceKey="keep"
          choiceValue="keep"
          choiceTitle="Keep everything"
          choiceSubtitle="Keep all my answers and only change profile."
          choiceBackground="white"
        />
      </RadioCardContainer>
    </div>
  );
};
