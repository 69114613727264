import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  error: {
    color: theme.palette.error.main,
  },
  weak: {
    backgroundColor: theme.palette.error.main,
  },
  strong: {
    backgroundColor: theme.palette.success.main,
  },
}));

export function StrengthIndicator({ score, warning, suggestions }) {
  const classes = useStyles();

  if (score === null) {
    return null;
  }

  return (
    <div className={classes.container}>
      <LinearProgress
        classes={{
          bar: clsx({
            [classes.weak]: score <= 2,
            [classes.strong]: score > 2,
          }),
        }}
        variant="determinate"
        value={score * 25}
      />

      {warning ? (
        <Typography variant="caption" className={classes.error}>
          {warning}
        </Typography>
      ) : null}

      {suggestions.length ? (
        <ul className={classes.error}>
          {suggestions.map((s) => (
            <li key={s}>
              <Typography variant="caption">{s}</Typography>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
