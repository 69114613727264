import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Button } from 'components/button';
import { Link } from 'components/link';
import { DemoModeButton } from 'components/demo_mode_button';
import { AccountNavButton } from 'components/AccountNavButton';
import * as urls from 'services/urls';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { DiligenceTreeNav } from 'components/lp_doc/diligence/tree_nav_v2';
import { DiligenceErrorProvider } from 'components/lp_doc/diligence/error_context';
import { QuestionCard } from 'components/lp_doc/card';
import { SaveIndicator } from 'components/lp_doc/saved';
import { ReconciliationProvider } from 'components/lp_doc/reconciliation/provider';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { ViewDocumentsButton } from 'components/documents_drawer';
import { AddCollaboratorsBtn } from './add_collaborators_btn';
import { ProfileInfoButton } from '../profile/profile_info/profile_info_button';
import { StepNavigation } from '../step_navigation';
import { Download } from '../download';
import { MobileMenuBar } from './mobile_menu_bar';
import { AccessDropdownButton } from '../access_dropdown_button';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  stepperContainer: {
    width: '100%',
    transition: 'transform 0.5s ease',
    display: 'flex',
    flexDirection: 'row',
  },
  leftVisible: {
    transform: 'translateX(-100%)',
  },
  rightVisible: {
    transform: 'translateX(0%)',
  },
  rightSide: {
    minHeight: 'calc(100vh - 64px)',
    overflowY: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rightContent: {
    flexGrow: 1,
    maxWidth: '1400px',
    padding: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  leftSide: {
    padding: '0 !important',
    minHeight: 'calc(100vh - 64px)',
    minWidth: '400px',
    maxWidth: '400px',
    display: 'flex',
    margin: theme.spacing(9, 0),
    flexDirection: 'column',
    color: theme.palette.primary.black,
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
    overflowX: 'hidden',
  },
  content: {
    position: 'relative',
    flex: '1 1 0px',
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.appbar.background,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  grow: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1),
  },
  logo: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  verticalStackFlexStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  verticalStackFlexEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  fundName: {
    fontWeight: 500,
    padding: theme.spacing(0, 1),
  },
  backIcon: {
    fontSize: '2rem',
  },
  iconGroup: {
    whiteSpace: 'nowrap',
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  navContainer: {
    flexShrink: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  accessButton: {
    margin: theme.spacing(0, 2),
  },
  actionsToolbar: {
    marginBottom: theme.spacing(1),
  },
}));

export function LpDocPageWithTopBar({
  lpDoc,
  lpDiligence,
  numCollaborators,
  showNavWarning,
  activeProfile,
  availableProfiles,
  setActiveProfile,
  setSetupProfileId,
  setLpAccessDialogOpen,
  enableLpRequestedReview,
  setHasLpRequestedReview,
  saving,
  setSaving,
  question,
  currentStep,
  steps,
  nav,
  navRef,
  onAnswer,
  updateLpDoc,
  QuestionStepperComponent,
  DiligenceQuestionStepperComponent,
  SectionView,
  setShowTopBar,
  setRestarting,
  selectedQuestionId,
  comments,
  sendLPComment,
  missingDiligenceDataErrors,
  resetDiligenceQuestionValidation,
  updateSingleDiligenceQuestionValidation,
}) {
  const { LP_PRO_MODE } = useFeatureFlags();
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const showMobileMenu = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { showDiligenceNav } = nav;

  const showLogo = lpDoc.fundLogo && lpDoc.showLogo && !showMobileMenu;
  const diligenceQuestions = steps.find(
    (s) => s.type === 'DILIGENCE',
  )?.questions;

  useEffect(() => {
    setShowTopBar(false);
    return () => {
      setShowTopBar(true);
    };
  }, []);

  const docsCount = lpDoc.isCompleted ? 0 : lpDoc.docs?.length || 0;

  return (
    <>
      <AppBar
        className={classes.appBar}
        elevation={0}
        position={LP_PRO_MODE ? 'fixed' : 'relative'}
      >
        <Toolbar className={LP_PRO_MODE ? '' : classes.actionsToolbar}>
          <div className={classes.backButton}>
            <Link to={urls.DASH_URL}>
              <Button aria-label="Back" variant="icon" size="large">
                <KeyboardArrowLeftIcon />
              </Button>
            </Link>
          </div>
          {showLogo ? (
            <img
              className={classes.logo}
              src={lpDoc.fundLogo}
              height="50px"
              alt="Fund logo"
            />
          ) : null}
          <div>
            <DemoModeButton />
          </div>
          <div className={classes.verticalStackFlexStart}>
            <Typography className={classes.fundName}>
              {lpDoc.fundName}
            </Typography>
            {!LP_PRO_MODE ? (
              <>
                {lpDoc.docs.length > 0 ? <Download docs={lpDoc.docs} /> : null}
              </>
            ) : null}
          </div>
          <span className={classes.grow} />
          {showMobileMenu ? (
            <IconButton
              data-test="menu"
              aria-label="Menu button"
              aria-controls="menu-button"
              aria-haspopup="true"
              onClick={() => {
                setOpenMenu((val) => !val);
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          {!showMobileMenu ? (
            <>
              <div className={classes.verticalStackFlexEnd}>
                <ProfileInfoButton
                  activeProfile={activeProfile}
                  availableProfiles={availableProfiles}
                  setActiveProfile={setActiveProfile}
                  setSetupProfileId={setSetupProfileId}
                  fundName={lpDoc.fundName}
                  lpName={lpDoc.lpName}
                  hasProfileQuestions={lpDoc.hasProfileQuestions}
                  setRestarting={setRestarting}
                />
                <AccessDropdownButton
                  numCollaborators={numCollaborators}
                  lpDoc={lpDoc}
                />
              </div>
              <div className={classes.iconGroup}>
                <AddCollaboratorsBtn
                  handleClick={() => {
                    setLpAccessDialogOpen(true);
                  }}
                  className={classes.accessButton}
                />
                {LP_PRO_MODE && docsCount > 0 ? (
                  <ViewDocumentsButton count={docsCount} />
                ) : null}
                <SaveIndicator saving={saving} />
                <AccountNavButton />
              </div>
            </>
          ) : null}
        </Toolbar>
        {showMobileMenu ? (
          <MobileMenuBar
            isOpen={openMenu}
            lpName={lpDoc.lpName}
            activeProfile={activeProfile}
            availableProfiles={availableProfiles}
            setActiveProfile={setActiveProfile}
            setSetupProfileId={setSetupProfileId}
            lpDoc={lpDoc}
            setLpAccessDialogOpen={setLpAccessDialogOpen}
            numCollaborators={numCollaborators}
            hasProfileQuestions={lpDoc.hasProfileQuestions}
            setRestarting={setRestarting}
          />
        ) : null}
      </AppBar>
      {/* hidden toolbar for spacing */}
      <Toolbar />
      <div className={classes.container}>
        <DiligenceErrorProvider>
          <div className={classes.leftSide}>
            <div
              className={clsx(classes.stepperContainer, {
                [classes.leftVisible]: showDiligenceNav && nav.showAnimation,
                [classes.rightVisible]: !showDiligenceNav && nav.showAnimation,
              })}
            >
              <div className={classes.navContainer}>
                <StepNavigation
                  nav={nav}
                  steps={steps}
                  showNavWarning={showNavWarning}
                  currentStep={currentStep}
                />
              </div>
              <div className={classes.navContainer}>
                <DiligenceTreeNav
                  nav={nav}
                  jurisdiction={lpDiligence.diligenceJurisdiction}
                  diligenceQuestions={diligenceQuestions}
                  selectedQuestionId={selectedQuestionId}
                  showNavWarning={showNavWarning}
                  missingDiligenceDataErrors={missingDiligenceDataErrors}
                />
              </div>
            </div>
          </div>
          <div className={classes.rightSide}>
            <div className={classes.rightContent}>
              {nav.showOverview() ? (
                SectionView
              ) : (
                <Fade in key={question?.label}>
                  <div>
                    <ReconciliationProvider question={question}>
                      <QuestionCard
                        activeProfile={activeProfile}
                        lpDoc={lpDoc}
                        lpDiligence={lpDiligence}
                        question={question}
                        comments={comments}
                        sendLPComment={sendLPComment}
                        onAnswer={onAnswer}
                        saving={saving}
                        setSaving={setSaving}
                        updateLpDoc={updateLpDoc}
                        QuestionStepperComponent={QuestionStepperComponent}
                        DiligenceQuestionStepperComponent={
                          DiligenceQuestionStepperComponent
                        }
                        nav={nav}
                        navRef={navRef}
                        enableLpRequestedReview={enableLpRequestedReview}
                        setHasLpRequestedReview={setHasLpRequestedReview}
                        missingDiligenceDataErrors={missingDiligenceDataErrors}
                        resetDiligenceQuestionValidation={
                          resetDiligenceQuestionValidation
                        }
                        updateSingleDiligenceQuestionValidation={
                          updateSingleDiligenceQuestionValidation
                        }
                      />
                    </ReconciliationProvider>
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </DiligenceErrorProvider>
      </div>
    </>
  );
}
