/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Container from '@material-ui/core/Container';
import { objectEquals } from 'services/utils';
import { useConfirm } from '@passthrough/uikit';

import { UNSAVED } from '../saved';
import { DiligenceUI } from './diligence_ui';
import { TYPE_INDIVIDUAL, jurisdictions } from './constants';
import {
  isOwnerType,
  mapLabelToType,
  annotateOwnersWithTypes,
  buildInitialForm,
  parseRelevantProps,
  genOwnerErrors,
  hasAdditionalDataBeenProvided,
  nodeReusesNameInItsOwners,
} from './utils';
import { useErrorContext, useErrorDispatchContext } from './error_context';

export function RootDiligenceQuestion({
  lpDiligence,
  updateLpDoc,
  setSaving,
  answer: rawAnswer,
  label,
  isNested,
  QuestionPromptComponent,
  DiligenceQuestionStepper,
  nav,
  missingDiligenceDataErrors,
  resetDiligenceQuestionValidation,
  updateSingleDiligenceQuestionValidation,
}) {
  const diligenceQuestions = annotateOwnersWithTypes(
    lpDiligence.sections.find((s) => s.type === 'DILIGENCE')?.questions || [],
  );
  const labelsToTypes = mapLabelToType(diligenceQuestions);
  const rootHasChildren = diligenceQuestions.length > 1;

  const answer = { ...rawAnswer };
  const initialForm = buildInitialForm(
    answer,
    lpDiligence.diligenceJurisdiction,
  );
  const [form, setForm] = useState(initialForm);
  const allFormErrors = useErrorContext();
  const errorDispatch = useErrorDispatchContext();

  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [documentsExpanded, setDocumentsExpanded] = useState(false);
  const [ownersExpanded, setOwnersExpanded] = useState(false);

  const [shouldAutosaveDueToTypeChange, setShouldAutosaveDueToTypeChange] =
    useState(false);

  const confirm = useConfirm();

  const hasAnswerChanged = !objectEquals(form, initialForm);
  const missingDataErrors = missingDiligenceDataErrors?.[label];
  const formErrors = allFormErrors?.[label];
  const nodeRepeatsNameInOwners = nodeReusesNameInItsOwners(
    form,
    lpDiligence.diligenceJurisdiction,
  );

  function goToDetails() {
    setDetailsExpanded(true);
    setDocumentsExpanded(false);
    setOwnersExpanded(false);
  }

  function goToDocuments() {
    setDetailsExpanded(false);
    setDocumentsExpanded(true);
    setOwnersExpanded(false);
  }

  function goToOwners() {
    setDetailsExpanded(false);
    setDocumentsExpanded(false);
    setOwnersExpanded(true);
  }

  function setFormErrors(errors) {
    errorDispatch({ type: 'set', label, errors });
  }

  function clearErrors() {
    errorDispatch({ type: 'clear', label });
  }

  function handleSubmit(e, causedByTypeChange = false) {
    e?.preventDefault();

    clearErrors();

    const props = parseRelevantProps(
      form,
      !isNested,
      lpDiligence.diligenceJurisdiction,
    );
    // NOTE: perform non-empty name validation here so that we can
    // allow the relevant backend serializer for owners to have a
    // not required owner name field
    const nameErrors = genOwnerErrors(props);
    if (Object.keys(nameErrors).length > 0) {
      setFormErrors(nameErrors);
      return Promise.resolve(new Error('Not all owners are valid.'));
    }

    // have to determine if the types of any children have changed
    // so that we can clear the missing data errors associated with
    // them since that question has not been started with its new type
    const IdsToClear = causedByTypeChange ? [label] : [];
    const answersToSubmit = Object.entries(props);

    for (let i = 0; i < answersToSubmit.length; i += 1) {
      const [key, value] = answersToSubmit[i];

      if (!isOwnerType(key, value)) {
        /* eslint-disable no-continue */
        continue;
      }

      for (let j = 0; j < value.length; j += 1) {
        const ownerRef = value[j];

        if (labelsToTypes[ownerRef.id] !== ownerRef.type) {
          IdsToClear.push(ownerRef.id);
        }
      }
    }

    return updateLpDoc({
      label,
      answer: props,
      missingDataErrorLabelsToClear: IdsToClear,
      lpClosingId: lpDiligence.id,
    })
      .then(() => {
        // if the current question is not in the overall missing
        // data errors collection, then it is the first time that
        // a user is working with it or the user has just changed its type
        // and it is the first time they're working on the node with this new type
        const isFirstTimeSeeingQuestion =
          missingDataErrors === undefined || missingDataErrors === null;

        if (causedByTypeChange || isFirstTimeSeeingQuestion) {
          return null;
        }

        // should only query for missing data errors for a question where
        // the user has already seen the question with its current type
        return updateSingleDiligenceQuestionValidation(label);
      })
      .catch((error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setFormErrors(error.response.data.answer);
        }
        return error;
      });
  }

  function handleSubmitOnTypeChange() {
    return handleSubmit(null, true);
  }

  function handleClick(e, goToFunc) {
    if (!hasAnswerChanged) {
      goToFunc();
      return;
    }

    handleSubmit(e).then((returnVal) => {
      if (!(returnVal instanceof Error)) {
        goToFunc();
      }
    });
  }

  const clickOnSection = (e, goToFunc, sectionName) => {
    const alreadyOnSection =
      (sectionName === 'details' && detailsExpanded) ||
      (sectionName === 'documents' && documentsExpanded) ||
      (sectionName === 'owners' && ownersExpanded);
    if (alreadyOnSection) {
      return;
    }

    const showRepeatedNameWarningModal =
      nodeRepeatsNameInOwners && ownersExpanded;
    if (!showRepeatedNameWarningModal) {
      handleClick(e, goToFunc);
      return;
    }

    confirm({
      title: 'Are you sure?',
      description: `You have entered ${form.name} as an owner of ${form.name}. Are you sure that this is correct?`,
      confirmationText: 'Yes',
    })
      .then(() => {
        handleClick(e, goToFunc);
      })
      .catch(() => {});
  };

  function handleChange(formKey, newVal) {
    setSaving(UNSAVED);

    setForm((f) => ({ ...f, [formKey]: newVal }));
  }

  function changeType(e) {
    const answerKeysToIgnore = ['name', 'type', 'useSsn'];
    const shouldDisplayTypeChangeConfirmation = hasAdditionalDataBeenProvided(
      form,
      answerKeysToIgnore,
    );

    const emptyForm = buildInitialForm({}, lpDiligence.diligenceJurisdiction);

    function returnNewForm(oldForm) {
      const dataToRetain = {
        name: oldForm.name,
      };

      if (lpDiligence.diligenceJurisdiction === jurisdictions.CAYMAN) {
        dataToRetain.sourceOfWealth = oldForm.sourceOfWealth;
        dataToRetain.sourceOfWealthDetails = oldForm.sourceOfWealthDetails;
        dataToRetain.proofSourceOfFunds = oldForm.proofSourceOfFunds;
      }

      return { ...emptyForm, type: e.target.value, ...dataToRetain };
    }

    if (shouldDisplayTypeChangeConfirmation) {
      confirm({
        title: 'Change type?',
        description:
          'Changing the type will discard all existing information, documents, and owners for this individual or entity.',
        destructive: true,
        confirmationText: 'Change type',
      })
        .then(() => {
          setForm(returnNewForm);
          setShouldAutosaveDueToTypeChange(true);
        })
        .catch(() => {});
    } else {
      setForm(returnNewForm);
      setShouldAutosaveDueToTypeChange(true);
    }
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setForm(initialForm);
    clearErrors();
  }, [detailsExpanded, documentsExpanded, ownersExpanded]);

  // in order to avoid react rendering issues due to how label otherwise
  // updates 1 render before form does, immediately update the form
  // (and other relevant state) on any label change
  useLayoutEffect(() => {
    setForm(initialForm);
    clearErrors();
  }, [label]);

  // Reset the errors when we change types.
  useEffect(() => {
    clearErrors();
  }, [form.type]);

  useEffect(() => {
    if (shouldAutosaveDueToTypeChange) {
      handleSubmitOnTypeChange().then((returnVal) => {
        if (!(returnVal instanceof Error)) {
          const isRootIndividual = form.type === TYPE_INDIVIDUAL && !isNested;
          if (isRootIndividual) {
            nav.toRegularNav();
          }
        }
      });
      setShouldAutosaveDueToTypeChange(false);
    }
  }, [shouldAutosaveDueToTypeChange]);

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm">
        <DiligenceUI
          lpClosingId={lpDiligence.id}
          form={form}
          formErrors={{ ...missingDataErrors, ...formErrors }}
          jurisdiction={lpDiligence.diligenceJurisdiction}
          hideOptOut={lpDiligence.shouldHideDiligenceOptOut}
          hideIndividualRootNodeOptOutOverride={
            lpDiligence.hideIndividualRootNodeOptOut
          }
          label={label}
          fundName={lpDiligence.fundName}
          fullAnswerData={answer}
          isNested={isNested}
          handleChange={handleChange}
          QuestionPromptComponent={QuestionPromptComponent}
          diligenceQuestions={diligenceQuestions}
          detailsExpanded={detailsExpanded}
          documentsExpanded={documentsExpanded}
          ownersExpanded={ownersExpanded}
          goToDetails={goToDetails}
          goToDocuments={goToDocuments}
          goToOwners={goToOwners}
          clickDetails={(e) => {
            clickOnSection(e, goToDetails, 'details');
          }}
          clickDocuments={(e) => {
            clickOnSection(e, goToDocuments, 'documents');
          }}
          clickOwners={(e) => {
            clickOnSection(e, goToOwners, 'owners');
          }}
          changeType={changeType}
        />

        <DiligenceQuestionStepper
          jurisdiction={lpDiligence.diligenceJurisdiction}
          documentsOptedOut={form.optOut}
          currNodeName={form.name}
          handleSubmit={handleSubmit}
          resetMissingDataErrors={resetDiligenceQuestionValidation}
          hasAnswerChanged={hasAnswerChanged}
          detailsExpanded={detailsExpanded}
          documentsExpanded={documentsExpanded}
          ownersExpanded={ownersExpanded}
          goToDetails={goToDetails}
          goToDocuments={goToDocuments}
          goToOwners={goToOwners}
          isNested={isNested}
          rootHasChildren={rootHasChildren}
          nodeRepeatsNameInChild={nodeRepeatsNameInOwners}
        />
      </Container>
    </form>
  );
}

export function ChildDiligenceQuestion(props) {
  return <RootDiligenceQuestion {...props} isNested />;
}
