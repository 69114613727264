import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LabelIcon from '@material-ui/icons/LabelOutlined';

import { InvestorTagChip } from 'components/investor_tagging_chip';
import { StatusDot, getStatusText } from 'components/status';
import { STANDARD_STATUSES } from 'components/status/constants';
import { IMPLICIT_TAG_GROUPS } from '../../constants';

function getRiskTagLabel(ratingTag) {
  switch (ratingTag.name) {
    case 'HIGH_RISK':
      return 'High risk';
    case 'LOW_RISK':
      return 'Low risk';
    case 'NO_RISK_RATING':
      return 'Unable to assess risk';
    default:
      return '';
  }
}

export function getFilterChipFunction(filter) {
  switch (filter.groupName) {
    case IMPLICIT_TAG_GROUPS.RISK_RATING:
      return RiskRatingFilterChip;
    case IMPLICIT_TAG_GROUPS.CLOSING_STATUS:
      return ClosingStatusFilterChip;
    default:
      return CustomTagFilterChip;
  }
}

function GenericFilterChip({
  displayLabel,
  startIcon = <LabelIcon />,
  onDelete,
}) {
  return (
    <InvestorTagChip
      labelStr={displayLabel}
      size="medium"
      startIcon={startIcon}
      deleteIcon={<CloseIcon />}
      onDelete={onDelete}
    />
  );
}

function CustomTagFilterChip({ filter, onDelete }) {
  return <GenericFilterChip displayLabel={filter.name} onDelete={onDelete} />;
}

function ClosingStatusFilterChip({ filter, hasSigning, onDelete }) {
  const status = filter.name;
  const isCustomStatus = !STANDARD_STATUSES.includes(status);
  const customStatusText = isCustomStatus ? status : null;
  const statusForGetStatusText = isCustomStatus ? 'SIGNED' : status;

  const displayLabel = getStatusText(statusForGetStatusText, {
    hasSigning,
    customStatusText,
  });

  const startIcon = (
    <div>
      <StatusDot status={statusForGetStatusText} hasSigning={hasSigning} />
    </div>
  );

  return (
    <GenericFilterChip
      displayLabel={displayLabel}
      startIcon={startIcon}
      onDelete={onDelete}
    />
  );
}

function RiskRatingFilterChip({ filter, onDelete }) {
  const displayLabel = getRiskTagLabel(filter);
  const startIcon = null;

  return (
    <GenericFilterChip
      displayLabel={displayLabel}
      startIcon={startIcon}
      onDelete={onDelete}
    />
  );
}
