import React from 'react';
import {
  Typography,
  Chip,
  UIKitSettingsProvider,
  Button,
  Icons,
} from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as constants from 'components/gp_selection_input/constants';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { Callout } from 'components/Callout';
import { getDefaultCountersignerLabel } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  recommendedSettingHeader: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
  },
}));

export function SubdocQuestionnaireSettings({
  subdoc,
  subdocName,
  subdocUrl,
  numberOfCountersigners,
  members,
  countersignerId,
  setCountersignerId,
  countersignerIdError,
  countersigner2Id,
  setCountersigner2Id,
  countersigner2IdError,
  countersigner3Id,
  setCountersigner3Id,
  countersigner3IdError,
  fundId,
  downloadable,
  downloadableError,
  setDownloadable,
  disableOfflineSigning,
  disableOfflineSigningError,
  setDisableOfflineSigning,
}) {
  const classes = useStyles();
  const canSelectDefaultcountersigner1 = numberOfCountersigners > 0;
  const canSelectDefaultcountersigner2 = numberOfCountersigners > 1;
  const canSelectDefaultcountersigner3 = numberOfCountersigners > 2;

  const countersigner1Label = getDefaultCountersignerLabel(
    subdoc?.countersigner1DisplayName || 'Countersigner',
  );
  const countersigner2Label = getDefaultCountersignerLabel(
    subdoc?.countersigner2DisplayName || 'Second countersigner',
  );
  const countersigner3Label = getDefaultCountersignerLabel(
    subdoc?.countersigner2DisplayName || 'Third countersigner',
  );
  return (
    <>
      <Typography variant="card-heading">Questionnaire settings</Typography>

      <Callout
        header="Subscription document"
        message={subdocName}
        action={
          <UIKitSettingsProvider redirectAttribute="href">
            <Button
              variant="secondary"
              href={subdocUrl}
              endIcon={<Icons.OpenInNew color="primary" />}
              external
            >
              View
            </Button>
          </UIKitSettingsProvider>
        }
      />

      {canSelectDefaultcountersigner1 ? (
        <GPSelectionInput
          label={countersigner1Label}
          errorMsg={countersignerIdError}
          value={countersignerId}
          setValue={setCountersignerId}
          roleLabelNum={constants.DEFAULT_COUNTERSIGNER_1_LABEL_NUM}
          members={members}
          fundId={fundId}
          includeBlankOption
        />
      ) : null}

      {canSelectDefaultcountersigner2 ? (
        <GPSelectionInput
          label={countersigner2Label}
          errorMsg={countersigner2IdError}
          value={countersigner2Id}
          setValue={setCountersigner2Id}
          roleLabelNum={constants.DEFAULT_COUNTERSIGNER_2_LABEL_NUM}
          members={members}
          fundId={fundId}
          includeBlankOption
        />
      ) : null}

      {canSelectDefaultcountersigner3 ? (
        <GPSelectionInput
          label={countersigner3Label}
          errorMsg={countersigner3IdError}
          value={countersigner3Id}
          setValue={setCountersigner3Id}
          roleLabelNum={constants.DEFAULT_COUNTERSIGNER_3_LABEL_NUM}
          members={members}
          fundId={fundId}
          includeBlankOption
        />
      ) : null}

      <FormControl error={!!downloadableError}>
        <FormControlLabel
          className={classes.label}
          control={
            <Checkbox
              checked={downloadable}
              onChange={(e) => {
                setDownloadable(e.target.checked);
              }}
              name="downloadable"
            />
          }
          label={
            <>
              <div className={classes.recommendedSettingHeader}>
                <Typography variant="body" size="medium" fontWeight={500}>
                  Allow downloads
                </Typography>

                <Chip variant="info" size="small" label="Recommended" />
              </div>

              <Typography variant="body" size="small">
                Investors can download their documents for offline reading.
              </Typography>

              <Typography variant="body" size="small">
                {downloadableError}
              </Typography>
            </>
          }
        />
      </FormControl>

      <FormControl error={!!disableOfflineSigningError}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!disableOfflineSigning}
              onChange={(e) => {
                setDisableOfflineSigning(!e.target.checked);
              }}
              name="disableOfflineSigning"
            />
          }
          label={
            <>
              <div className={classes.recommendedSettingHeader}>
                <Typography variant="body" size="medium" fontWeight={500}>
                  Allow offline signing
                </Typography>

                <Chip variant="info" size="small" label="Recommended" />
              </div>

              <Typography variant="body" size="small">
                Investors can download the subscription document to print and
                sign offline at the end of the questionnaire.
              </Typography>

              <Typography variant="body" size="small">
                {disableOfflineSigningError}
              </Typography>
            </>
          }
        />
      </FormControl>
    </>
  );
}
