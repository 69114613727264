import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
}));

export function ProfileInfoSelect({
  activeProfile,
  availableProfiles,
  selectedProfileId,
  setSelectedProfileId,
}) {
  const classes = useStyles();

  const activeProfileInAvailableList = !!availableProfiles.find(
    (profile) => profile.id === activeProfile.id,
  );
  const profilesForSelect = activeProfileInAvailableList
    ? availableProfiles
    : [activeProfile, ...availableProfiles];

  return (
    <>
      <FormControl variant="outlined" required className={classes.input}>
        <InputLabel id="profile">Profile</InputLabel>
        <Select
          label="Profile"
          labelId="profile"
          value={selectedProfileId}
          onChange={(e) => {
            setSelectedProfileId(e.target.value);
          }}
        >
          {profilesForSelect.map((profile) => (
            <MenuItem
              key={`${profile.id}-${profile.displayName}`}
              value={profile.id}
            >
              {profile.displayName}
            </MenuItem>
          ))}
          <MenuItem key="new" value="new">
            + New profile
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
