import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/button';
import LabelIcon from '@material-ui/icons/Label';
import { Dialog, DialogContent } from 'components/dialog/index';
import { InteropSuggestionItem } from './interop_suggestion_item';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
}));

export const InteropSuggestion = ({
  question,
  questionReuse,
  suggestions,
  onApply,
}) => {
  const classes = useStyles();
  const [showSuggestions, setShowSuggestions] = React.useState(false);

  const handleApply = (suggestion) => {
    onApply(suggestion);
    setShowSuggestions(false);
  };

  if (!questionReuse) {
    return null;
  }

  return (
    <div className={classes.root}>
      {questionReuse.tags.map((tag) => (
        <Chip
          key={tag}
          variant="outlined"
          color="default"
          icon={<LabelIcon />}
          label={<Typography variant="body2">{tag}</Typography>}
        />
      ))}

      {suggestions?.length && !questionReuse.tags.length ? (
        <Typography variant="body2">
          <Button variant="text" onClick={() => setShowSuggestions(true)}>
            {suggestions?.length} Suggestion
            {(suggestions?.length || 0) > 1 ? 's' : ''}
          </Button>
        </Typography>
      ) : null}

      <Dialog
        open={showSuggestions}
        onClose={() => setShowSuggestions(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent className={classes.content}>
          <Typography variant="h6">Suggestions</Typography>
          <Typography>
            Tagged questions take precedence over non tagged questions.
          </Typography>

          <List>
            <InteropSuggestionItem
              current
              item={{ questionText: question, ...questionReuse }}
            />
            {(suggestions || []).map((item) => (
              <InteropSuggestionItem
                key={item.questionText}
                item={item}
                onApply={() => handleApply(item.questionText)}
              />
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};
