import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
// TODO: Replace CashMultipleIcon with PaymentsOutlinedIcon
// import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CashMultipleIcon from 'mdi-material-ui/CashMultiple';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { PDFNameChecker } from 'components/document_upload/pdf_name_checker';

import {
  InternalChip,
  Link,
  Modal,
  Typography,
  Alert,
} from '@passthrough/uikit';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { CurrencyTextField } from 'components/currency_text_field/index';
import { Spinner } from 'components/spinner';
import { FileUpload, getEmptyFile } from 'components/file_upload';
import { useToast } from 'services/toast';
import { formatCurrency } from 'services/utils';
import * as api from 'services/api';
import { useMe } from 'services/providers/me';
import { useCurrency } from 'services/providers/currency';
import { Collapse } from '@material-ui/core';
import { useWhiteLabelConfig } from 'services/providers/theme';

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginBottom: theme.spacing(2),
  },
  input: {
    margin: theme.spacing(1, 0),
  },
  checkboxes: {
    margin: theme.spacing(2, 0),
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const STATUSES = {
  DRAFT: { number: 0, name: 'Draft' },
  UNVIEWED: { number: 1, name: 'Sent to Investor' },
  VIEWED: { number: 2, name: 'Sent to Investor' },
  IN_PROGRESS: { number: 3, name: 'Sent to Investor' },
  REQUESTED_CHANGES: { number: 4, name: 'Sent to Investor' },
  PARTIALLY_SIGNED: { number: 5, name: 'Sent to Investor' },
  SIGNED: { number: 6, name: 'Investor Signed' },
  APPROVED: { number: 7, name: 'Approved' },
  SENT_TO_COUNTERSIGNER: { number: 10, name: 'Sent to Countersigner' },
  COUNTERSIGNED: { number: 11, name: 'Fully executed' },
};

function formatCurrencyOrDash(value, currency) {
  if (Number.isNaN(value) || value === null) return '-';
  return formatCurrency(value, currency);
}

function isValidCommitment(number) {
  return (
    typeof number === 'number' && !Number.isNaN(number) && Number(number) >= 0
  );
}

export function UploadDocumentDialog({
  open,
  handleClose,
  onChange,
  lpDoc,
  lpClosing,
  closing,
  onExited,
}) {
  const classes = useStyles();
  const { fundId, closingId } = useParams();
  const [file, setFile] = useState(getEmptyFile());
  const [fileToCheck, setFileToCheck] = useState(getEmptyFile());
  const [fileError, setFileError] = useState(null);
  const [showPageDimensionsWarning, setShowPageDimensionsWarning] =
    useState(false);
  const [commitment, setCommitment] = useState(null);
  const [commitmentHasAnswerTypeDollars, setCommitmentHasAnswerTypeDollars] =
    useState(false);
  const [commitmentError, setCommitmentError] = useState('');
  const [acceptedCommitment, setAcceptedCommitment] = useState(null);
  const [acceptedCommitmentError, setAcceptedCommitmentError] = useState('');
  const [hasAcceptedCommitment, setHasAcceptedCommitment] = useState(false);
  const [isAcceptedCommitmentRequired, setIsAcceptedCommitmentRequired] =
    useState(false);
  const [countersigned, setCounterSigned] = useState(false);
  const [requiresSignatures, setRequiresSignatures] = useState(false);
  const [requireCounterSignature, setRequireCounterSignature] = useState(false);
  const [status, setStatus] = useState(null);
  const [otherDocsSigned, setOtherDocsSigned] = useState(null);
  const [otherDocsCountersigned, setOtherDocsCountersigned] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(0);
  const [alreadyCheckedPdf, setAlreadyCheckedPdf] = useState(false);
  const { successToast } = useToast();
  const [me] = useMe();
  const currency = useCurrency();
  const { productName } = useWhiteLabelConfig();

  const currencyDisplay = `${currency.code} ${currency.symbol}`;

  const showCommitment =
    lpDoc && lpDoc.isSubdoc && lpDoc.isSubdocWithCommitment;
  const showAcceptedCommitment =
    showCommitment && hasAcceptedCommitment && countersigned;

  const investorName = lpClosing.lpName;
  const investorLegalName = lpClosing.legalName;

  const otherDocs =
    lpClosing?.docs?.filter((doc) => doc.id !== lpDoc?.id) || [];
  const areAllOtherDocsSigned = otherDocs.every(
    (doc) => !doc.requireSignature || doc.signed,
  );
  const showPotentialDiligenceIssueWarning =
    lpClosing.hasDiligence &&
    areAllOtherDocsSigned &&
    lpDoc?.requireSignature &&
    !lpDoc?.signed;

  const handleFileUpload = (f) => {
    if (me.isStaff && f?.fileName && investorName) {
      setFileToCheck(f);
      setFileError(null);
    } else {
      setFile(f);
      setFileError(null);
    }
  };

  function getSigners() {
    if (lpDoc === null) return;

    setIsLoading(true);

    // Reset fields when switching documents
    setCommitment(null);
    setCounterSigned(false);
    setStep(0);
    setFile(getEmptyFile());
    setFileError(null);

    api
      .lpDocSigners({ fundId, closingId, lpDocId: lpDoc.id })
      .then((response) => {
        setCounterSigned(response.data.countersigned);
        setRequireCounterSignature(response.data.requiresCountersigning);
        setRequiresSignatures(response.data.requiresSigning);
        setCommitment(Number(response.data.commitment));
        setCommitmentHasAnswerTypeDollars(
          response.data.commitmentHasAnswerTypeDollars,
        );
        let initialAcceptedCommitment = response.data.acceptedCommitment;
        if (initialAcceptedCommitment === null) {
          initialAcceptedCommitment = response.data.commitment;
        }
        setAcceptedCommitment(Number(initialAcceptedCommitment));
        setHasAcceptedCommitment(response.data.hasAcceptedCommitment);
        setIsAcceptedCommitmentRequired(
          response.data.isAcceptedCommitmentRequired,
        );
        setStatus(response.data.status);
        setOtherDocsSigned(response.data.otherDocsSigned);
        setOtherDocsCountersigned(response.data.otherDocsCountersigned);
        setIsLoading(false);
      });
  }

  useEffect(getSigners, [lpDoc ? lpDoc.id : null]);

  function checkPageDimensions() {
    setShowPageDimensionsWarning(false);
    if (!file?.fileId) return;
    api
      .filePageDimensionsCheck({ fundId, fileId: file.fileId })
      .then((response) => {
        if (response.data.hasLargeDimensions) {
          setShowPageDimensionsWarning(true);
        }
      });
  }
  useEffect(checkPageDimensions, [file?.fileId]);

  const changeHandler = (setter) => (e, v) => {
    // autocomplete will modify the target directly, bypassing v.
    // so we parse the value of the target as a backup.
    const targetValue = parseFloat(e.target.value.replace(/,/g, ''));
    const value = v || targetValue;
    setter(value);
  };

  const handleChangeCommitment = changeHandler(setCommitment);
  const handleChangeAcceptedCommitment = changeHandler(setAcceptedCommitment);

  function handleChangeSignature(e) {
    setCounterSigned(e.target.value === 'countersigned');
  }

  function onSubmit(e) {
    e.preventDefault();

    setIsUploading(true);

    const params = {
      fundId,
      closingId,
      lpDocId: lpDoc.id,
      countersigned,
      fileId: file.fileId,
    };

    if (showCommitment) {
      params.commitment = commitment;
    }
    if (showAcceptedCommitment) {
      params.acceptedCommitment = acceptedCommitment;
    }

    api
      .lpDocUpload(params)
      .then(() => {
        setIsUploading(false);
        successToast('Uploaded a document');
        onChange();
        handleClose();
        setAlreadyCheckedPdf(false);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          if (
            error.response?.data?.commitment ||
            error.response?.data?.acceptedCommitment
          ) {
            setCommitmentError(error.response.data.commitment);
            setAcceptedCommitmentError(error.response.data.acceptedCommitment);
            setAlreadyCheckedPdf(true);
            setStep(0); // Go to step with the commitment input
          }
          setIsUploading(false);
        }
      });
  }

  function onNext(e) {
    setCommitmentError('');
    setAcceptedCommitmentError('');
    e.preventDefault();
    if (showCommitment) {
      if (!isValidCommitment(commitment)) {
        setCommitmentError('This field is required.');
        return;
      }
      if (showAcceptedCommitment) {
        if (!isValidCommitment(acceptedCommitment)) {
          if (isAcceptedCommitmentRequired) {
            setAcceptedCommitmentError('This field is required.');
            return;
          }
        }
      }
    }
    setStep(1);
  }

  function getNewStatus() {
    /* Returns the new status, or null when status is unchanged. */
    if (!otherDocsSigned) return null;

    if (otherDocsSigned && !otherDocsCountersigned) {
      return 'SIGNED';
    }

    if (otherDocsCountersigned && countersigned) {
      return 'COUNTERSIGNED';
    }

    if (otherDocsCountersigned && !countersigned) {
      return 'SIGNED';
    }

    return null;
  }

  function statusText() {
    const newStatus = getNewStatus();
    if (!newStatus || status === newStatus) {
      return 'Status unchanged';
    }

    if (newStatus === 'SIGNED' || newStatus === 'COUNTERSIGNED') {
      return (
        <>
          The investor's status will change to{' '}
          <strong>{STATUSES[newStatus].name}</strong>
        </>
      );
    }

    return 'Status unchanged';
  }

  function notificationText() {
    // If the status is moving forward, then they will be notified.
    const newStatus = getNewStatus();

    const isAdvancement =
      newStatus !== null &&
      STATUSES[newStatus].number > STATUSES[status].number;

    if (isAdvancement && newStatus === 'SIGNED') {
      return (
        <>
          The investor <strong>will be notified</strong> that their documents
          have been completed.
        </>
      );
    }

    if (
      isAdvancement &&
      newStatus === 'COUNTERSIGNED' &&
      !closing?.disableFullyExecutedEmail
    ) {
      return (
        <>
          The investor <strong>will be notified</strong> that their documents
          have been fully executed.
        </>
      );
    }

    return (
      <>
        The investor <strong>will not</strong> be notified.
      </>
    );
  }

  function modalContent() {
    if (isLoading) return <Spinner fullScreen />;

    const statusChanged = statusText() !== 'Status unchanged';

    return (
      <form onSubmit={onSubmit} autoComplete="off">
        {step === 0 ? (
          <>
            <FileUpload
              file={file}
              onChange={handleFileUpload}
              disabled={!!fileToCheck?.fileId}
            />
            <PDFNameChecker
              investorName={investorName}
              investorLegalName={investorLegalName}
              file={file}
              setFile={setFile}
              fileToCheck={fileToCheck}
              setFileToCheck={setFileToCheck}
              fileError={fileError}
              setFileError={setFileError}
              isStaff={me.isStaff}
              alreadyCheckedPdf={alreadyCheckedPdf}
            />
            {fileError ? (
              <FormHelperText error className={classes.helperText}>
                {fileError.isStaff ? <InternalChip /> : null}{' '}
                {fileError.message}
              </FormHelperText>
            ) : (
              <FormHelperText className={classes.helperText}>
                This will replace any existing signed file and will be visible
                to the investor.
              </FormHelperText>
            )}

            <RadioGroup
              className={classes.checkboxes}
              aria-label="Signatures"
              name="signature"
              value={countersigned ? 'countersigned' : 'signed'}
              onChange={handleChangeSignature}
            >
              {requiresSignatures ? (
                <FormControlLabel
                  value="signed"
                  control={<Radio />}
                  label="Signed"
                />
              ) : null}
              {requireCounterSignature ? (
                <FormControlLabel
                  value="countersigned"
                  control={<Radio />}
                  label={
                    requiresSignatures
                      ? 'Signed & countersigned'
                      : 'Countersigned'
                  }
                />
              ) : null}
            </RadioGroup>

            {showCommitment ? (
              <>
                <CurrencyTextField
                  id="dollars"
                  currencySymbol={currencyDisplay}
                  minimumValue="0"
                  label="Commitment"
                  variant="outlined"
                  value={commitment}
                  error={!!commitmentError}
                  helperText={commitmentError}
                  onChange={handleChangeCommitment}
                  fixedDecimalScale={commitmentHasAnswerTypeDollars}
                  fullWidth
                />
                <FormHelperText className={classes.helperText}>
                  This will replace the amount entered by the investor (if any)
                  and may be visible to them.
                </FormHelperText>
              </>
            ) : null}

            <Collapse in={showAcceptedCommitment} appear={false}>
              <CurrencyTextField
                id="dollars"
                currencySymbol={currencyDisplay}
                minimumValue="0"
                label="Accepted commitment"
                variant="outlined"
                value={acceptedCommitment}
                error={!!acceptedCommitmentError}
                helperText={acceptedCommitmentError}
                onChange={handleChangeAcceptedCommitment}
                fixedDecimalScale={commitmentHasAnswerTypeDollars}
                fullWidth
              />
            </Collapse>
          </>
        ) : null}

        {step === 1 ? (
          <>
            {showPageDimensionsWarning ? (
              <Alert severity="warning" className={classes.alert}>
                The pages exceed the recommended size (612x792px) for
                countersigning fields to appear properly. If you plan on
                countersigning in {productName}, we recommend resizing the pages
                and uploading the document again.
              </Alert>
            ) : null}
            {showPotentialDiligenceIssueWarning ? (
              <Alert severity="warning">
                Uploading a signed offline document may prevent the user from
                submitting diligence information.
              </Alert>
            ) : null}
            <KeyValuePairs>
              <KeyValuePair icon={<DescriptionOutlinedIcon />}>
                <Link href={file.fileUrl} variant="external">
                  {file.fileName}
                </Link>
              </KeyValuePair>
              <KeyValuePair icon={<BorderColorOutlinedIcon />}>
                {countersigned ? 'Signed & countersigned' : 'Signed'}
              </KeyValuePair>
              {showCommitment ? (
                <>
                  <KeyValuePair
                    icon={<CashMultipleIcon />}
                    label={
                      showAcceptedCommitment ? (
                        <Typography size="small" color="text.secondary">
                          Commitment
                        </Typography>
                      ) : null
                    }
                  >
                    {formatCurrency(commitment, currency)}
                  </KeyValuePair>
                </>
              ) : null}
              {showAcceptedCommitment ? (
                <>
                  <KeyValuePair
                    icon={<CashMultipleIcon />}
                    label={
                      <Typography size="small" color="text.secondary">
                        Accepted commitment
                      </Typography>
                    }
                  >
                    {formatCurrencyOrDash(acceptedCommitment, currency)}
                  </KeyValuePair>
                </>
              ) : null}
              <KeyValuePair icon={<EmailIcon />}>
                {notificationText()}
              </KeyValuePair>
              <KeyValuePair
                icon={
                  statusChanged ? <ReportProblemOutlinedIcon /> : <CheckIcon />
                }
              >
                {statusText()}
              </KeyValuePair>
            </KeyValuePairs>
          </>
        ) : null}
      </form>
    );
  }

  const primaryButtonProps =
    step === 0
      ? {
          primaryButtonText: 'Next',
          primaryButtonDisabled: !file.fileId,
          primaryButtonEndIcon: <ArrowForwardIcon />,
          onSubmit: onNext,
        }
      : {
          primaryButtonText: 'Upload',
          primaryButtonLoading: isUploading,
          onSubmit,
        };

  return (
    <Modal
      headerLabel="Upload document"
      open={open}
      onClose={handleClose}
      showCancelButton
      {...primaryButtonProps}
      onBack={
        step === 1
          ? () => {
              setAlreadyCheckedPdf(false);
              setStep(0);
            }
          : null
      }
      onExited={() => {
        setCommitmentError('');
        onExited();
      }}
    >
      {modalContent()}
    </Modal>
  );
}
