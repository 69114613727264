import React from 'react';
import { Chip } from '@passthrough/uikit';

import * as riskConstants from 'pages/review_v2/diligence_v2/risk_rating/constants';

function getDisplayLabel(riskRating) {
  switch (riskRating) {
    case riskConstants.NO_RATING:
      return 'Unable to assess';
    case riskConstants.HIGH_RISK:
      return 'High';
    case riskConstants.LOW_RISK:
      return 'Low';
    default:
      return null;
  }
}

function getVariant(riskRating) {
  switch (riskRating) {
    case riskConstants.NO_RATING:
      return 'warning';
    case riskConstants.HIGH_RISK:
      return 'error';
    case riskConstants.LOW_RISK:
      return 'success';
    default:
      return null;
  }
}

export function RiskRatingChip({ riskRating }) {
  if (!riskRating) {
    return null;
  }

  return (
    <Chip
      variant={getVariant(riskRating)}
      label={getDisplayLabel(riskRating)}
      size="small"
    />
  );
}
