import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  getDocumentFields,
  getTypeDetailKeysToDisplay,
} from 'components/lp_doc/diligence/utils';
import { OptOutAlert } from 'pages/review_v2/diligence_v2/components/opt_out_alert';
import { DocumentsDisplay } from './documents_display/index';
import { SupplementaryDocumentsDisplay } from './documents_display/extra_documents_display';
import { SubmittedDataDisplay } from './submitted_data_display';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
}));

export function DataAndDocsDisplay({
  isRootNode,
  jurisdiction,
  supplementaryFiles,
  answerData,
  handleOpenDocument,
  nodeIsReused,
  hideEditButtons,
  handleOpenDocsEditModal,
  handleOpenExtraDocsEditModal,
  handleOpenDetailsEditModal,
  lastSubmittedAnswer,
  changedType,
}) {
  const classes = useStyles();

  const isMissingData = answerData?.optOut;

  const nonFileKeys = getTypeDetailKeysToDisplay(
    answerData?.type,
    jurisdiction,
    isRootNode,
  );
  const allFileConfigData = getDocumentFields(
    answerData?.type,
    jurisdiction,
    isRootNode,
  );

  return (
    <div className={classes.root}>
      {isMissingData ? <OptOutAlert /> : null}

      <SubmittedDataDisplay
        jurisdiction={jurisdiction}
        answerKeys={nonFileKeys}
        answerData={answerData}
        lastSubmittedAnswer={lastSubmittedAnswer}
        changedType={changedType}
        hideEditButtons={hideEditButtons}
        nodeIsReused={nodeIsReused}
        handleOpenDetailsEditModal={handleOpenDetailsEditModal}
      />

      {allFileConfigData.length > 0 ? (
        <DocumentsDisplay
          allFileConfigData={allFileConfigData}
          answerData={answerData}
          lastSubmittedAnswer={changedType ? null : lastSubmittedAnswer}
          nodeIsReused={nodeIsReused}
          hideEditButtons={hideEditButtons}
          handleOpenDocument={handleOpenDocument}
          handleOpenDocsEditModal={handleOpenDocsEditModal}
        />
      ) : null}

      <SupplementaryDocumentsDisplay
        supplementaryFiles={supplementaryFiles}
        hideEditButtons={hideEditButtons}
        handleOpenExtraDocsEditModal={handleOpenExtraDocsEditModal}
        handleOpenDocument={handleOpenDocument}
      />
    </div>
  );
}
