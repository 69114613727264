/* eslint-disable react/jsx-no-duplicate-props, no-alert */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { useConfirm, Alert, Typography } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { DocumentUploadPage } from 'components/document_upload/index';
import { Spinner } from 'components/spinner';
import { PageContainer } from 'components/page_container';
import {
  EXPOSED_OFFERING_DOC_TYPES,
  OFFERING_DOCUMENT,
} from 'components/document_upload/constants';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';

import { DocumentList } from './document_list';
import { OfferingDocEditDialog } from './offering_doc_edit_dialog';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
}));

export function OfferingPage() {
  const classes = useStyles();
  const { fundId } = useParams();
  const confirm = useConfirm();

  const [subscriptionDocs, setSubscriptionDocs] = useState(null);
  const [offeringDocs, setOfferingDocs] = useState(null);
  const [documentError, setDocumentError] = useState('');

  const [change, setChange] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editOfferingDocument, setEditOfferingDocument] = React.useState(null);
  const { toast } = useToast();

  function getOfferingDocs() {
    api.offeringDocs({ fundId }).then((response) => {
      setOfferingDocs(response.data);
    });
  }

  function getSubscriptionDocs() {
    api.subscriptionDocs({ fundId }).then((response) => {
      setSubscriptionDocs(response.data);
    });
  }

  useEffect(getOfferingDocs, [change]);
  useEffect(getSubscriptionDocs, [change]);

  const maxNumCountersigners =
    subscriptionDocs != null
      ? Math.max(...subscriptionDocs.map((d) => d.numberOfCountersigners))
      : 0;
  const couldHaveFirstSigner = Boolean(
    subscriptionDocs?.find((d) => d.hasFirstSigner),
  );
  const couldHaveSecondSigner = Boolean(
    subscriptionDocs?.find((d) => d.hasSecondSigner),
  );
  const couldHaveThirdSigner = Boolean(
    subscriptionDocs?.find((d) => d.hasThirdSigner),
  );
  const couldHaveFourthSigner = Boolean(
    subscriptionDocs?.find((d) => d.hasFourthSigner),
  );
  const couldHaveFifthSigner = Boolean(
    subscriptionDocs?.find((d) => d.hasFifthSigner),
  );
  const couldHaveSixthSigner = Boolean(
    subscriptionDocs?.find((d) => d.hasSixthSigner),
  );

  if (offeringDocs === null || subscriptionDocs === null) {
    return <Spinner fullScreen />;
  }

  function handleOfferingDocDelete(offeringDoc) {
    function performDelete() {
      api
        .offeringDocDelete({ fundId, offeringDocId: offeringDoc.id })
        .then(() => {
          setDocumentError('');
          setChange((c) => c + 1);
          toast(`Deleted document: ${offeringDoc.name}`);
        })
        .catch((error) => {
          setDocumentError(getSingleErrorFromResponse(error.response));
        });
    }

    confirm({
      description: `You are about to delete document: ${offeringDoc.name}`,
      destructive: true,
    })
      .then(performDelete)
      .catch(() => {});
  }

  function handleOfferingDocArchive(offeringDoc) {
    function performArchive() {
      api
        .offeringDocArchive({ fundId, offeringDocId: offeringDoc.id })
        .then(() => {
          setDocumentError('');
          setChange((c) => c + 1);
          toast(`Archived ${offeringDoc.name}`);
        })
        .catch((error) => {
          setDocumentError(getSingleErrorFromResponse(error.response));
        });
    }

    confirm({
      title: 'Archive document?',
      confirmationText: 'Archive document',
      description: (
        <>
          <Typography>
            You are about to archive {offeringDoc.name}. New investors will no
            longer receive the document, but investors who have already received
            it will maintain access.
          </Typography>
          <br />
          <Typography>You may restore this document at any time.</Typography>
        </>
      ),
    })
      .then(performArchive)
      .catch(() => {});
  }

  function handleOfferingDocUnarchive(offeringDoc) {
    function performUnarchive() {
      api
        .offeringDocUnarchive({ fundId, offeringDocId: offeringDoc.id })
        .then(() => {
          setDocumentError('');
          setChange((c) => c + 1);
          toast(`Restored ${offeringDoc.name}`);
        })
        .catch((error) => {
          setDocumentError(getSingleErrorFromResponse(error.response));
        });
    }

    confirm({
      title: 'Restore document?',
      confirmationText: 'Restore document',
      description: `New investors will receive ${offeringDoc.name} once again.`,
    })
      .then(performUnarchive)
      .catch(() => {});
  }

  function handleSubscriptionDocArchive(subscriptionDoc) {
    function performArchive() {
      api
        .subscriptionDocArchive({
          fundId,
          subscriptionDocId: subscriptionDoc.id,
        })
        .then(() => {
          setDocumentError('');
          setChange((c) => c + 1);
          toast(`Archived ${subscriptionDoc.name}.`);
        })
        .catch((error) => {
          setDocumentError(getSingleErrorFromResponse(error.response));
        });
    }
    confirm({
      title: 'Archive subscription document?',
      confirmationText: 'Archive document',
      description: (
        <>
          <Typography>
            You are about to archive {subscriptionDoc.name}. This will prevent
            anyone from using it in any new closings or adding new investors to
            closings using this document.
          </Typography>
          <br />
          <Typography>
            You may restore this subscription document at any time.
          </Typography>
        </>
      ),
    })
      .then(performArchive)
      .catch(() => {});
  }

  function handleSubscriptionDocUnarchive(subscriptionDoc) {
    function performUnarchive() {
      api
        .subscriptionDocUnarchive({
          fundId,
          subscriptionDocId: subscriptionDoc.id,
        })
        .then(() => {
          setDocumentError('');
          setChange((c) => c + 1);
          toast(`Restored ${subscriptionDoc.name}`);
        })
        .catch((error) => {
          setDocumentError(getSingleErrorFromResponse(error.response));
        });
    }

    confirm({
      title: 'Restore subscription document?',
      confirmationText: 'Restore document',
      description: `Anyone will be able to use ${subscriptionDoc.name} for any new closings,
      or to add new investors to existing closings using this document again.`,
    })
      .then(performUnarchive)
      .catch(() => {});
  }

  return (
    <PageContainer maxWidth="lg">
      <div className={classes.container}>
        {documentError ? <Alert severity="error">{documentError}</Alert> : null}
        <DocumentList
          subscriptionDocs={subscriptionDocs}
          documents={offeringDocs}
          openNewDialog={() => {
            setDialogOpen(true);
          }}
          onOfferingDocDelete={handleOfferingDocDelete}
          onOfferingDocArchive={handleOfferingDocArchive}
          onOfferingDocUnarchive={handleOfferingDocUnarchive}
          onOfferingDocEdit={(doc) => setEditOfferingDocument(doc)}
          onSubscriptionDocArchive={handleSubscriptionDocArchive}
          onSubscriptionDocUnarchive={handleSubscriptionDocUnarchive}
        />
      </div>

      <OfferingDocEditDialog
        document={editOfferingDocument}
        fundId={fundId}
        onClose={() => setEditOfferingDocument(null)}
        onUpdate={() => setChange((c) => c + 1)}
      />

      <DocumentUploadPage
        open={dialogOpen}
        onChange={() => {
          setChange((c) => c + 1);
        }}
        handleClose={() => {
          setDialogOpen(false);
        }}
        isFundDocumentUpload
        dataModelType={OFFERING_DOCUMENT}
        enableDocTypeSelection
        docTypesToDisplay={EXPOSED_OFFERING_DOC_TYPES}
        hasFirstSigner={couldHaveFirstSigner}
        hasSecondSigner={couldHaveSecondSigner}
        hasThirdSigner={couldHaveThirdSigner}
        hasFourthSigner={couldHaveFourthSigner}
        hasFifthSigner={couldHaveFifthSigner}
        hasSixthSigner={couldHaveSixthSigner}
        hasFirstCounterSigner={maxNumCountersigners >= 1}
        hasSecondCounterSigner={maxNumCountersigners >= 2}
        hasThirdCounterSigner={maxNumCountersigners >= 3}
        allowInvestorTypeInEditMode
      />
    </PageContainer>
  );
}
