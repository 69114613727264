import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Alert } from 'components/alert';

import * as api from 'services/api';
import { useConfirm } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { objectEquals, getSingleErrorFromResponse } from 'services/utils';
import { EmptyState } from 'components/empty_v2';
import { Button } from 'components/button';
import { Dialog } from 'components/dialog/index';
import { Spinner } from 'components/spinner';

import * as utils from './utils';
import { ReviewChanges } from './review_changes';
import { WarningAlert } from './warning_alert';
import * as constants from './question_tag/constants';
import * as tagUtils from './question_tag/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.appbar.background,
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  toolBarLeftSide: {
    flexDirection: 'column',
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: 500,
  },
  revertButton: {
    marginRight: theme.spacing(2),
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.error.background,
    },
  },
  publishButton: {
    marginRight: theme.spacing(2),
  },
  errorAlert: {
    marginBottom: theme.spacing(2),
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const PublishModal = ({
  fundId,
  questionnaireId,
  onClose,
  draftQuestionnaireUpdateTime,
  onClosePostPublish,
}) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const confirm = useConfirm();
  const { MULTIPLE_DOCUMENTS } = useFeatureFlags();
  const { errorToast } = useToast();

  const [isLoading, setLoading] = React.useState(false);
  const [questionnaire, setQuestionnaire] = React.useState(null);
  const [diff, setDiff] = React.useState(null);
  const [fileChanged, setFileChanged] = React.useState({});
  const [additionalFiles, setAdditionalFiles] = React.useState({});
  const [keepTagsConfirmed, setKeepTagsConfirmed] = React.useState({});

  const [errors, setErrors] = React.useState([]);
  const [warnings, setWarnings] = React.useState([]);

  const hasChanged =
    !objectEquals(questionnaire?.draftData || {}, questionnaire?.data || {}) ||
    fileChanged.hasChanged ||
    additionalFiles.hasChanged;
  const canRevert = !isLoading && hasChanged;

  useEffect(() => {
    if (questionnaire != null) {
      const { questionToTagsAndType } = questionnaire;
      Object.keys(questionToTagsAndType).forEach((qid) => {
        const origQuestion = questionnaire.data.find((d) => d.id === qid);
        const newQuestion = questionnaire.draftData.find((d) => d.id === qid);
        const origQuestionType = questionToTagsAndType[qid].type;
        const questionTags = questionToTagsAndType[qid].tags;
        if (
          tagUtils.questionValidForKeepTagValid(
            origQuestionType,
            origQuestion,
            newQuestion,
            questionTags,
          )
        ) {
          setKeepTagsConfirmed((map) => {
            const newMap = map;
            newMap[qid] = {};
            const fieldsOrChoices = tagUtils.getFieldsOrChoices(
              origQuestionType,
              questionTags,
            );
            fieldsOrChoices.forEach((f) => {
              newMap[qid][f] = constants.NOT_CONFIRMED;
            });
            return newMap;
          });
        }
      });
    }
  }, [questionnaire]);

  const tagNotConfirmed = Object.keys(keepTagsConfirmed).some((qid) =>
    Object.keys(keepTagsConfirmed[qid]).some(
      (f) => keepTagsConfirmed[qid][f] === constants.NOT_CONFIRMED,
    ),
  );

  const canPublish =
    !isLoading &&
    !warnings.length &&
    !errors.length &&
    hasChanged &&
    !tagNotConfirmed;

  const removeWarning = (warning) => {
    setWarnings(warnings.filter((msg) => msg !== warning));
  };

  const publish = async () => {
    setLoading(true);
    const draftTime = questionnaire?.updatedAt;
    try {
      const questionsToAddTags = Object.keys(keepTagsConfirmed)
        .map((qid) =>
          Object.keys(keepTagsConfirmed[qid]).map((f) => {
            if (
              keepTagsConfirmed[qid][f] === constants.CONFIRMED_YES ||
              keepTagsConfirmed[qid][f] === constants.CONFIRMED_NO
            ) {
              return {
                qid,
                field_or_choice: f,
                keep_tag: keepTagsConfirmed[qid][f],
              };
            }
            return null;
          }),
        )
        .flat()
        .filter((q) => q);
      await api.publishQuestionnaire({
        fundId,
        questionnaireId,
        draftTime,
        questionsToAddTags,
      });
      onClosePostPublish();
    } catch (err) {
      const error = getSingleErrorFromResponse(err.response);
      errorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const revert = () =>
    confirm({
      description:
        'You are about to undo all changes and revert it back to the previous published state.',
      destructive: true,
    })
      .then(async () => {
        setLoading(true);

        try {
          await api.revertQuestionnaire({
            fundId,
            questionnaireId,
            draftTime: questionnaire?.updatedAt,
          });
          onClosePostPublish();
        } catch (e) {
          const error = getSingleErrorFromResponse(e.response);
          errorToast(error);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {});

  const fetch = async () => {
    setLoading(true);
    const response = await api.getQuestionnaireDraft({
      fundId,
      questionnaireId,
    });
    setQuestionnaire(response.data);
    const dataDiff = utils.getDiff({
      data: response.data.data || [],
      draftData: response.data.draftData || [],
    });
    setDiff(dataDiff);

    const fileResponse = await api.checkQuestionnaireFileChanged({
      fundId,
      questionnaireId,
    });
    setFileChanged(fileResponse.data);

    if (MULTIPLE_DOCUMENTS) {
      const additionalResponse = await api.getQuestionnaireDocumentChanges({
        fundId,
        questionnaireId,
      });
      setAdditionalFiles(additionalResponse.data);
    }

    const newErrors = utils.getErrors(
      response.data,
      draftQuestionnaireUpdateTime,
    );
    const newWarnings = utils.getWarnings(response.data);

    try {
      await api.validateQuestionnaire({
        fundId,
        questionnaireId,
        diff: dataDiff,
      });
    } catch (e) {
      if (e.response?.status === 400) {
        if (e.response?.data?.detail) {
          newErrors.push(e.response.data.detail);
        } else if (e.response?.data) {
          newErrors.push(...e.response.data);
        } else {
          newErrors.push(
            'An unexpected error occurred when trying to validate the questionnaire.',
          );
        }
      } else {
        newErrors.push(
          'An unexpected error occurred when trying to validate the questionnaire.',
        );
      }
    }

    if (fileResponse.data.hasChanged) {
      newWarnings.push('You have changed the subscription agreement PDF.');
    }

    if (fileResponse.data.doPdfNumPagesDiffer) {
      const questionsWithLinks = utils.getQuestionsWithLinks(response.data);
      if (questionsWithLinks.length > 0) {
        newWarnings.push(
          `You have changed the length of the subscription agreement PDF. Check the following
          questions to make sure that their links are still accurate:
          ${questionsWithLinks.join(', ')}`,
        );
      }
    }

    if (!newErrors.length) {
      // If there are errors, there's no need to hit the warnings endpoint
      const backendWarningsResponse = await api.getBackendWarnings({
        fundId,
        questionnaireId,
      });

      const hasCustomExcel =
        backendWarningsResponse.data.subdocsWithExcel.length > 0;
      if (hasCustomExcel) {
        newWarnings.push(
          `A custom export is associated with this onboarding.
            Contact a CSM to update the custom excel for the following sub docs:
            ${backendWarningsResponse.data.subdocsWithExcel.join(', ')}.`,
        );
      }

      if (backendWarningsResponse.data.legalNameWarning) {
        newWarnings.push(backendWarningsResponse.data.legalNameWarning);
      }

      if (backendWarningsResponse.data.isLargeFile) {
        newWarnings.push('The subdoc pdf file is larger than 15MB');
      }

      if (backendWarningsResponse.data.dateSignedConditions.length > 0) {
        newWarnings.push(
          `The date signed value for signatures ${backendWarningsResponse.data.signatureLabels.join(
            ', ',
          )} will not be added to the document if the following answer sets are provided: ${JSON.stringify(
            backendWarningsResponse.data.dateSignedConditions,
          )}`,
        );
      }

      const { offlineSignedDocumentPrep } = backendWarningsResponse.data;
      if (offlineSignedDocumentPrep.length > 0) {
        newWarnings.push(
          `Offline signed document preparation will need to be done again for
          the following LPs: ${offlineSignedDocumentPrep.join(', ')}`,
        );
      }

      if (backendWarningsResponse.data.lpDocumentsWarning) {
        newWarnings.push(backendWarningsResponse.data.lpDocumentsWarning);
      }
    }

    setErrors(newErrors);
    setWarnings(newWarnings);
    setLoading(false);
  };

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <Dialog open fullScreen onClose={onClose} classes={dialogClasses}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.toolBarLeftSide}>
            <Typography className={classes.title}>
              Review and publish
            </Typography>
          </div>
          <div>
            <Button
              size="large"
              loading={isLoading}
              className={classes.revertButton}
              disabled={!canRevert}
              onClick={revert}
            >
              Revert
            </Button>
            <Button
              size="large"
              loading={isLoading}
              className={classes.publishButton}
              disabled={!canPublish}
              onClick={publish}
            >
              Publish
            </Button>
            <Button
              variant="text"
              size="large"
              color="default"
              onClick={onClose}
              loading={isLoading}
              className={classes.closeButton}
            >
              Close
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        {errors.map((error) => (
          <Alert severity="error" className={classes.errorAlert}>
            {error}
          </Alert>
        ))}

        {warnings.map((warning) => (
          <WarningAlert
            severity="warning"
            onDismiss={() => removeWarning(warning)}
          >
            {warning}
          </WarningAlert>
        ))}

        {isLoading ? <Spinner fullScreen /> : null}

        {!isLoading && hasChanged && diff ? (
          <ReviewChanges
            questionnaire={questionnaire}
            diff={diff}
            fileChanged={fileChanged}
            additionalFiles={additionalFiles}
            keepTagsConfirmed={keepTagsConfirmed}
            setKeepTagsConfirmed={setKeepTagsConfirmed}
          />
        ) : null}

        {!isLoading && !hasChanged ? (
          <EmptyState title="There are no changes to be published." />
        ) : null}
      </div>
    </Dialog>
  );
};
