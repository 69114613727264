import { createTheme } from '@material-ui/core/styles';
import {
  breakpoints,
  buildShadows,
  colors,
  buildTypography,
  Icons,
} from '@passthrough/uikit';

export const signerPalette = {
  investor1: colors.GREEN[700],
  investor2: colors.BLUE[500],
  investor3: colors.YELLOW[500],
  investor4: colors.RED[400],
  investor5: colors.YELLOW[900],
  investor6: colors.GRAY[500],
  countersigner1: colors.ORANGE[500],
  countersigner2: colors.GRAY[900],
  countersigner3: colors.RED[600],
};

// MUI v4 theme palette
const palette = {
  type: 'light',
  common: {
    white: colors.WHITE,
    black: colors.BLACK,
  },
  primary: {
    main: colors.GREEN[700],
    dark: colors.GREEN[800],
    light: colors.GREEN[50],
    white: colors.WHITE,
    black: colors.BLACK,
    lightGrey: colors.GRAY[300],
    grey: colors.GRAY[600],

    fill: colors.GREEN[100],
    border: colors.GREEN[300],
  },
  drawer: {
    background: colors.GRAY[900],
    contrastText: colors.GRAY[400],
    link: colors.GREEN[500],
  },
  appbar: {
    background: colors.WHITE,
  },
  waves: {
    background: colors.BLUE[50],
  },
  secondary: {
    main: colors.GREEN[700],
  },
  error: {
    light: colors.RED[50],
    fill: colors.RED[100],
    border: colors.RED[300],
    icon: colors.RED[400],
    text: colors.RED[700],
    main: colors.RED[700],
    background: colors.RED[50],
    inactive: colors.RED[300],
  },
  warning: {
    light: colors.ORANGE[50],
    fill: colors.ORANGE[100],
    border: colors.ORANGE[300],
    main: colors.ORANGE[500],
    background: colors.ORANGE[50],
    text: colors.ORANGE[800],
    icon: colors.ORANGE[400],
  },
  info: {
    main: colors.BLUE[700],
    text: colors.BLUE[500],
    background: colors.BLUE[50],

    light: colors.BLUE[50],
    fill: colors.BLUE[100],
    border: colors.BLUE[300],
    icon: colors.BLUE[400],
  },
  demoMode: {
    0: colors.BRIGHT_PURPLE,
    1: colors.LIME_GREEN,
  },
  success: {
    main: colors.GREEN[700],
    background: colors.GREEN[50],
    text: colors.GREEN[800],

    fill: colors.GREEN[100],
    border: colors.GREEN[300],
    icon: colors.GREEN[400],
  },
  progressBar: {
    pending: colors.ORANGE[400],
    fullyExecuted: colors.GREEN[500],
    filler: colors.GRAY[200],
  },
  signers: signerPalette,
  divider: colors.GRAY[300],
  background: {
    default: colors.GRAY[50],
    alternative: colors.GRAY[50],
    top_bar: colors.GRAY[50],
    warning: colors.YELLOW[50],
    undo: colors.GREEN[50],
    snackbar: colors.GRAY[900],
    internalNote: colors.YELLOW[50],
  },
  table: {
    header: colors.GRAY[100],
    divider: colors.GRAY[100],
    highlight: colors.YELLOW[50],
  },
  greyHover: colors.GRAY[100],
  status: {
    signed: colors.BRIGHT_PURPLE,
    waiting: colors.ORANGE[400],
  },
  pepClasses: {
    highRisk: colors.RED[700],
    mediumRisk: colors.ORANGE[400],
    lowRisk: colors.BLUE[500],
    minimalRisk: colors.GRAY[600],
  },
  changeRequest: {
    main: colors.RED[500],
    text: colors.RED[700],
    background: colors.RED[50],
  },
  eventBackground: {
    gp: colors.GREEN[100],
    lp: colors.BLUE[100],
    internalNote: colors.YELLOW[100],
  },
  chipColors: {
    lightGreen: colors.GREEN[50],
    internalNote: colors.YELLOW[100],
    internalNoteBorder: colors.YELLOW[300],
  },

  // text colors
  text: {
    primary: colors.GRAY[900],
    secondary: colors.GRAY[600],
    link: colors.GREEN[700],
  },

  investorClosingStatus: {
    unsent: colors.GRAY[300],
    notStarted: colors.ORANGE[300],
    sent: colors.ORANGE[400],
    signed: colors.BLUE[300],
    approved: colors.BLUE[500],
    executed: colors.GREEN[500],
    closingEmailSent: colors.GREEN[700],
  },

  // semantic colors from uikit
  neutral: {
    light: colors.GRAY[50],
    fill: colors.GRAY[100],
    hover: colors.GRAY[200],
    border: colors.GRAY[300],
    icon: colors.GRAY[400],
    main: colors.GRAY[700],
    dark: colors.GRAY[800],
  },

  emphasis: {
    border: colors.YELLOW[300],
    fill: colors.YELLOW[50],
    icon: colors.YELLOW[500],
    main: colors.YELLOW[600],
  },
};

// passthrough's uikit palette
export const uikitPalette = {
  mode: 'light',
  common: {
    black: colors.BLACK,
    white: colors.WHITE,
  },
  divider: colors.GRAY[300],

  // semantic colors
  // TODO: fill out all color types
  info: {
    light: colors.BLUE[50],
    fill: colors.BLUE[100],
    border: colors.BLUE[300],
    icon: colors.BLUE[400],
    main: colors.BLUE[700],
  },
  neutral: {
    light: colors.GRAY[50],
    fill: colors.GRAY[100],
    hover: colors.GRAY[200],
    border: colors.GRAY[300],
    icon: colors.GRAY[400],
    main: colors.GRAY[700],
    dark: colors.GRAY[800],
  },
  success: {
    fill: colors.GREEN[100],
    border: colors.GREEN[300],
    icon: colors.GREEN[400],
    main: colors.GREEN[700],
  },
  primary: {
    light: colors.GREEN[50],
    fill: colors.GREEN[100],
    border: colors.GREEN[300],
    main: colors.GREEN[700],
  },
  error: {
    light: colors.RED[50],
    fill: colors.RED[100],
    border: colors.RED[300],
    icon: colors.RED[400],
    text: colors.RED[700],
    main: colors.RED[700],
    background: colors.RED[50],
    inactive: colors.RED[300],
  },
  warning: {
    light: colors.ORANGE[50],
    fill: colors.ORANGE[100],
    border: colors.ORANGE[300],
    main: colors.ORANGE[500],
    background: colors.ORANGE[50],
    text: colors.ORANGE[800],
    icon: colors.ORANGE[400],
  },

  // text colors
  text: {
    primary: colors.GRAY[900],
    secondary: colors.GRAY[600],
    link: colors.GREEN[700],
  },

  // background colors
  background: {
    default: colors.GRAY[50],
    paper: colors.WHITE,
  },

  button: {
    background: colors.WHITE,
    hover: colors.GRAY[50],
    disabled: colors.GRAY[200],
  },
};

export function buildOverrides(overridePalette) {
  return {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: overridePalette.common.white,
        color: overridePalette.text.primary,
      },
    },
    MuiStepLabel: {
      label: {
        fontWeight: 600,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: overridePalette.background.snackbar,
      },
    },
    MuiTypography: {
      h4: {
        letterSpacing: '-0.02em',
      },
      h5: {
        letterSpacing: '-0.01em',
        fontWeight: '600',
      },
      body1: {
        letterSpacing: '-0.01em',
      },
      colorTextSecondary: {
        color: overridePalette.text.primary,
      },
    },
    MuiStepConnector: {
      lineVertical: {
        borderColor: overridePalette.text.primary,
      },
      vertical: {
        padding: '8px 0 8px',
        zIndex: '5',
      },
    },
    MuiBreadcrumbs: {
      separator: {
        marginLeft: '6px',
        marginRight: '6px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        letterSpacing: 'inherit',
        borderRadius: 4,
      },
      outlinedSecondary: {
        border: `1px solid ${overridePalette.primary.main}`,
        '&:hover': {
          border: `1px solid ${overridePalette.primary.main}`,
        },
      },
      outlinedPrimary: {
        border: `1px solid ${overridePalette.primary.main}`,
        '&:hover': {
          border: `1px solid ${overridePalette.primary.main}`,
        },
      },
      outlined: {
        borderWidth: '1px',
        '&:hover': {
          borderWidth: '1px',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiFormLabel: {
      root: {
        textOverflow: 'ellipsis',
        overflowX: 'clip',
        maxWidth: '90%',
        whiteSpace: 'nowrap',
      },
    },
    MuiFormHelperText: {
      root: {
        color: overridePalette.text.secondary,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: overridePalette.common.white,
      },
    },
    MuiTimelineDot: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'inherit',
        letterSpacing: 'inherit',
      },
      textColorInherit: {
        '&.Mui-selected': {
          fontWeight: 700,
          color: overridePalette.primary.main,
        },
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          top: 0,
        },
        '&.Mui-expanded': {
          border: `1px solid ${overridePalette.divider} !important`,
        },
        '&.Mui-expanded + &': {
          borderTop: `1px solid ${overridePalette.divider}`,
        },
        '&:has(+ &.Mui-expanded)': {
          borderBottom: `1px solid ${overridePalette.divider}`,
        },
      },
      rounded: {
        border: 'none',
        borderLeft: `1px solid ${overridePalette.divider}`,
        borderRight: `1px solid ${overridePalette.divider}`,
        '&:first-child': {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          borderTop: `1px solid ${overridePalette.divider}`,
        },
        '&:last-child': {
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          borderBottom: `1px solid ${overridePalette.divider}`,
        },
      },
    },
    MuiChip: {
      root: {
        cursor: 'inherit',
      },
    },
    MuiStepButton: {
      root: {
        borderRadius: '4px',
      },
    },
  };
}

const overrides = buildOverrides(palette);

const themeName = 'Outer Space Lochmara Oryx V2';

// default MUI V4 props
const props = {
  MuiSelect: {
    IconComponent: Icons.KeyboardArrowDown,
  },
  MuiRadio: {
    color: 'primary',
  },
  MuiCheckbox: {
    color: 'primary',
  },
  MuiSwitch: {
    color: 'primary',
  },
};

export function buildTheme(themeProps) {
  const newTheme = createTheme({
    palette: themeProps.palette,
    overrides: themeProps.overrides,
    themeName: themeProps.themeName,
    typography: themeProps.typography,
    breakpoints,
    props,
  });

  if (themeProps.shadows) {
    newTheme.shadows = themeProps.shadows;
  }

  return newTheme;
}

export function buildDarkTheme(baseTheme) {
  // dark MUI palette for bulk action drawer
  const darkPalette = {
    type: 'dark',
    text: {
      link: baseTheme.palette.drawer.link,
    },
    drawer: {
      contrastText: baseTheme.palette.drawer.contrastText,
    },
  };

  return buildTheme({
    palette: darkPalette,
    overrides: baseTheme.overrides,
    themeName: `Dark ${baseTheme.themeName}`,
    typography: baseTheme.typography,
    shadows: baseTheme.shadows,
  });
}

export const theme = buildTheme({
  palette,
  overrides,
  themeName,
  typography: buildTypography(),
});

theme.shadows = buildShadows(theme);
