import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Spinner } from 'components/spinner';
import { PageContainer } from 'components/page_container';
import { DisplayLink } from 'components/link';
import { useSupport } from 'components/support';

import * as urls from 'services/urls';
import * as api from 'services/api';

import { LeftSidebar } from './left_sidebar';
import { RightSidebar } from './right_sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  leftCard: {
    marginTop: theme.spacing(4),
  },
  rightCard: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  viewSavedData: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const Profile = () => {
  const profileId = urls.useProfileId();
  const classes = useStyles();
  const { showSupport } = useSupport();
  const [profile, setProfile] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    api.profile({ id: profileId }).then((response) => {
      setProfile(response.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <PageContainer maxWidth="lg" className={classes.root}>
      <Typography variant="h5">Profile</Typography>
      <div className={classes.subtitleContainer}>
        <Typography>
          To update your profile,{' '}
          <DisplayLink onClick={showSupport}>contact support.</DisplayLink>
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={12} sm={3} className={classes.leftCard}>
          <LeftSidebar profile={profile} />
        </Grid>
        <Grid item xs={12} sm={9} className={classes.rightCard}>
          <RightSidebar profile={profile} />
        </Grid>
      </Grid>
    </PageContainer>
  );
};
