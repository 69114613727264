import React from 'react';
import { Button } from 'components/button';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContentText } from 'components/dialog/index';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { ProfileInfoSelect } from './profile_info_select';
import { DialogStep } from '../dialog_step';

const useStyles = makeStyles((theme) => ({
  continueButton: {
    margin: theme.spacing(2, 0),
  },
  description: {
    margin: theme.spacing(2, 0),
  },
}));

export function StepInfo({
  fundName,
  lpName,
  activeProfile,
  availableProfiles,
  selectedProfileId,
  setSelectedProfileId,
  setStep,
  onClose,
}) {
  const classes = useStyles();
  const changedProfiles = selectedProfileId !== activeProfile.id;

  const goToProfileSwitcherStep = () => {
    setStep(1);
  };

  return (
    <DialogStep title="Profile information" onClose={onClose}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow key="fund">
              <TableCell scope="row">Fund name</TableCell>
              <TableCell align="right">{fundName}</TableCell>
            </TableRow>
            <TableRow key="lp">
              <TableCell scope="row">Invited investor name</TableCell>
              <TableCell align="right">{lpName}</TableCell>
            </TableRow>
            <TableRow key="profile">
              <TableCell scope="row">Profile name</TableCell>
              <TableCell align="right">{activeProfile.displayName}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogContentText className={classes.description}>
        You are currently using the profile named {activeProfile.displayName} to
        fill out the subscription document for the investor {lpName}. To change
        the profile being used, choose an option below.
      </DialogContentText>
      <ProfileInfoSelect
        activeProfile={activeProfile}
        availableProfiles={availableProfiles}
        selectedProfileId={selectedProfileId}
        setSelectedProfileId={setSelectedProfileId}
      />
      <Button
        type="submit"
        onClick={goToProfileSwitcherStep}
        variant="contained"
        color="primary"
        className={classes.continueButton}
        fullWidth
        disabled={!changedProfiles}
      >
        Continue
      </Button>
    </DialogStep>
  );
}
