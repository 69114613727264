import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Button, LinkButton } from 'components/button';

const useStyles = makeStyles((theme) => ({
  reviewButton: {
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(6, 0),
    width: '100%',
  },
}));

export function QesInstructions({
  onRequestReview,
  enableRequestReview,
  fundName,
}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" align="center">
        You've submitted all relevant information. The next step is to submit
        for review.
      </Typography>

      <Button
        variant="contained"
        size="xl"
        className={classes.reviewButton}
        onClick={onRequestReview}
        disabled={!enableRequestReview}
      >
        Submit for review
      </Button>

      <Divider className={classes.divider} />

      <Typography variant="h5" align="center" gutterBottom>
        Or, you may confirm and sign now.
      </Typography>

      <Typography align="center">
        {fundName} is using Qualified Electronic Signatures (QES). You will be
        required to provide identification documents to a live agent. It is
        highly recommended that you request a review before signing to avoid
        needing to repeat the authentication process.
        <LinkButton
          color="primary"
          variant="text"
          url="https://support.passthrough.com/en_us/how-to-sign-subscriptions-with-a-qualified-electronic-signature-BJNB0ALjs"
        >
          Learn more
        </LinkButton>
      </Typography>
    </>
  );
}
