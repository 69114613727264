import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons } from '@passthrough/uikit';
import { useFundEdit } from 'services/providers/fund';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import DatabaseExportIcon from 'mdi-material-ui/DatabaseExportOutline';
import { ClosingCard } from './closing_card';
import { ClosingsExport } from './modals/closings_export';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  list: {
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
}));

export function ClosingList({ closings, openNewDialog, currency }) {
  const classes = useStyles();
  const [fundEdit] = useFundEdit();
  const [exportModalOpen, setExportModalOpen] = useState(false);

  return (
    <div className={classes.container}>
      {fundEdit ? (
        <div className={classes.actionButtons}>
          <Button
            variant="secondary"
            startIcon={<DatabaseExportIcon />}
            onClick={() => {
              setExportModalOpen(true);
            }}
          >
            Export
          </Button>
          <Button
            onClick={openNewDialog}
            variant="primary"
            startIcon={<Icons.Add />}
          >
            Create closing
          </Button>
        </div>
      ) : null}
      <Paper
        component={List}
        elevation={2}
        disablePadding
        className={classes.list}
        aria-label="Closing list"
      >
        {closings.map((closing) => (
          <ClosingCard key={closing.id} closing={closing} currency={currency} />
        ))}
      </Paper>
      <ClosingsExport
        open={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
        closings={closings}
      />
    </div>
  );
}
