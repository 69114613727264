import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import { reducerActionTypes } from 'pages/review_v2/diligence_v2/review_modal/constants';
import {
  DiligenceChip,
  DiligenceChipVariants,
} from 'pages/review_v2/diligence_v2/components/diligence_chip';
import { ThreadCountChip } from 'components/thread_count_chip';
import { InternalNoteChip } from 'components/internal_note_chip';
import {
  UnsentChip,
  ResolvedThreadChip,
  UpdatedChip,
} from 'components/text_chip';
import { ApprovalButtons } from '../approval_buttons';
import { NavKeys } from './nav_keys';
import { ApproveConfirmationModal } from './approve_confirmation_modal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(3),
  },
  approvalActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    textAlign: 'center',
  },
  largeName: {
    fontSize: '1.5rem',
  },
  greyText: {
    color: theme.palette.primary.grey,
  },
  errorText: {
    textAlign: 'center',
  },
  noteChipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

export function DisplayHeader({
  questionId,
  name,
  hasUnsentNote,
  isApproved,
  currOwnerIndex,
  commentThreadsResolved,
  hasUnresolvedInternalNote,
  numOwners,
  numCommentEvents,
  atLeastOneTrueMatch,
  atLeastOneUnsureMatch,
  dispatch,
  shouldDisableApproval,
  shouldHideApproval,
  onLeftNavClick,
  onRightNavClick,
  setLocalNumApproved,
  updated,
  nodeDataSettled,
  loadingUnresolvedThreads,
  docCollectionMode,
}) {
  const classes = useStyles();
  const { toast } = useToast();
  const { fundId, lpClosingId } = useParams();
  const [approvalModalNote, setApproveModalNote] = useState('');
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const showThreadCountChip =
    !loadingUnresolvedThreads &&
    !hasUnsentNote &&
    !isApproved &&
    numCommentEvents > 0 &&
    !commentThreadsResolved;

  const showResolvedThreadChip =
    !loadingUnresolvedThreads &&
    !hasUnsentNote &&
    !isApproved &&
    numCommentEvents > 0 &&
    commentThreadsResolved;

  const showAChip =
    updated ||
    hasUnsentNote ||
    showThreadCountChip ||
    showResolvedThreadChip ||
    hasUnresolvedInternalNote ||
    isApproved;

  useEffect(() => {
    if (!atLeastOneTrueMatch && !atLeastOneUnsureMatch) {
      setApproveModalNote('');
    }
  }, [atLeastOneTrueMatch, atLeastOneUnsureMatch]);

  const approveDiligenceNode = () => {
    setIsLoading(true);
    setErrorMsg('');

    api
      .approveDiligenceNode({
        fundId,
        lpClosingId,
        questionId,
        note: approvalModalNote,
      })
      .then(() => {
        dispatch({
          type: reducerActionTypes.APPROVE_NODE,
        });

        toast('Owner marked as reviewed');
        setIsLoading(false);
        setShowApproveModal(false);
        setApproveModalNote('');
        setLocalNumApproved((num) => num + 1);
      })
      .catch((e) => {
        setErrorMsg(
          e?.response?.data?.nonFieldErrors ||
            'There was an error reviewing this owner.',
        );
        setIsLoading(false);
      });
  };

  const approveDiligenceNodeWithModal = () => {
    if (atLeastOneTrueMatch || atLeastOneUnsureMatch) {
      setShowApproveModal(true);
    } else {
      approveDiligenceNode();
    }
  };

  const unapproveDiligenceNode = () => {
    setIsLoading(true);
    setErrorMsg('');

    api
      .unapproveDiligenceNode({ fundId, lpClosingId, questionId })
      .then(() => {
        dispatch({
          type: reducerActionTypes.UNAPPROVE_NODE,
        });

        toast('Undid review of owner');
        setIsLoading(false);
        setLocalNumApproved((num) => num - 1);
      })
      .catch(() => {
        // no error message defined on the backend
        setErrorMsg('There was an error undoing the review of this owner.');
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <ApproveConfirmationModal
        name={name}
        showModal={showApproveModal}
        setShowModal={setShowApproveModal}
        internalNote={approvalModalNote}
        setInternalNote={setApproveModalNote}
        approveDiligenceNode={approveDiligenceNode}
      />

      <NavKeys
        onLeftNavClick={onLeftNavClick}
        onRightNavClick={onRightNavClick}
        currOwnerIndex={currOwnerIndex}
        numOwners={numOwners}
      />

      {!loadingUnresolvedThreads && nodeDataSettled && showAChip ? (
        <div className={classes.noteChipContainer}>
          {updated ? <UpdatedChip /> : null}
          {hasUnsentNote ? <UnsentChip /> : null}
          {hasUnresolvedInternalNote ? <InternalNoteChip /> : null}
          {showThreadCountChip ? (
            <ThreadCountChip threadCount={numCommentEvents} />
          ) : null}
          {showResolvedThreadChip ? <ResolvedThreadChip /> : null}
          {isApproved ? (
            <DiligenceChip
              label="Reviewed"
              variant={DiligenceChipVariants.APPROVED}
            />
          ) : null}
        </div>
      ) : null}

      <Typography variant="h6" className={classes.largeName}>
        {name}
      </Typography>

      {shouldHideApproval ? null : (
        <div className={classes.approvalActionsContainer}>
          <ApprovalButtons
            isApproved={isApproved}
            isLoading={isLoading}
            shouldDisableApproval={
              loadingUnresolvedThreads ||
              !nodeDataSettled ||
              shouldDisableApproval
            }
            approveDiligenceNode={approveDiligenceNodeWithModal}
            unapproveDiligenceNode={unapproveDiligenceNode}
          />

          {errorMsg ? (
            <FormHelperText error className={classes.errorText}>
              {errorMsg}
            </FormHelperText>
          ) : null}

          {nodeDataSettled &&
          !loadingUnresolvedThreads &&
          !isApproved &&
          shouldDisableApproval ? (
            <Typography variant="body2" className={classes.greyText}>
              {docCollectionMode ? (
                <>You must resolve all comment threads to review this owner.</>
              ) : (
                <>
                  You must evaluate all potential matches and resolve all
                  comment threads to review this owner.
                </>
              )}
            </Typography>
          ) : null}
        </div>
      )}
    </div>
  );
}
