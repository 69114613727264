import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { Button } from 'components/button';
import { LPFileUpload, getEmptyFile } from 'components/file_upload';
import { SignedText } from './signed_text';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(1, 2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  docName: {
    fontWeight: 500,
  },
  printSpacer: {
    flexGrow: 1,
  },
  primary: {
    fontSize: '1rem',
  },
  secondary: {
    color: theme.palette.primary.grey,
    fontSize: '0.8rem',
  },
  uploadedContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

const TRUNCATE_CHARS = 40;

function openFile(url) {
  window.open(url, '_blank');
}

// Truncate a long filename with ellipses, but leave the extension
function truncate(filename, length) {
  if (!filename) {
    return undefined;
  }

  if (filename.length <= length) {
    return filename;
  }

  const extension = filename.split('.').pop();
  const truncated = filename.slice(0, length - extension.length - 1);
  return `${truncated}….${extension}`;
}

export function OnlineRow({ doc, buttonText, loading, disabled, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <Typography className={classes.docName}>{doc.name}</Typography>
      {doc.signed ? (
        <SignedText text={buttonText} />
      ) : (
        <Button
          data-test="sign"
          color="primary"
          variant="contained"
          loading={loading}
          disabled={disabled}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export function PrintRow({
  lpClosingId,
  doc,
  onSigned,
  buttonText,
  popperChange,
  uploadSingleFile,
}) {
  const classes = useStyles();

  const initialFile = doc.file || getEmptyFile();
  const [file, setFile] = useState(initialFile);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { getPdf, lpClosingUploadSignedDoc } = useQuestionnaire();

  const popperAnchorRef = useRef(null);
  const popperAnchorEl = popperAnchorRef.current;
  const popperId = `popper-${doc.id}`;

  useEffect(() => {
    setFile(initialFile);
  }, [initialFile.fileId]);

  useEffect(() => {
    // Update loading state after the lp is re-fetched
    setLoading(false);
  }, [doc.file?.fileId]);

  useEffect(() => {
    setMenuOpen(false);
  }, [popperChange]);

  function handleOpenOriginal() {
    setIsDownloading(true);

    getPdf(doc).then((res) => {
      const { url, filledUrl } = res.data;
      const filledUrlForSigning = `${filledUrl}?include_all=false`;
      const urlToOpen = doc.fillable ? filledUrlForSigning : url;
      setIsDownloading(false);
      openFile(urlToOpen);
    });
  }

  function handleOpenSignedCopy() {
    openFile(file.fileUrl);
  }

  function handleFileChange(newFile) {
    setErrorMsg('');
    setFile(newFile);
    setLoading(true);

    lpClosingUploadSignedDoc(doc.id, lpClosingId, newFile.fileId)
      .then(() => {
        // We're not updating the loading state here because
        // we want to wait for the lp to be re-fetched to avoid
        // a flash of the "Signed electronically" text when deleting
        if (newFile.fileId) {
          onSigned({ ...doc, signed: true, file: newFile });
        } else {
          onSigned({ ...doc, signed: false, file: null });
        }
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          const { fileId } = error.response.data;
          setErrorMsg(
            `Failed to upload file with id ${fileId}. ` +
              'Contact support at support@passthrough.com',
          );
        }
        setLoading(false);
      });
  }

  function handleDelete() {
    handleFileChange(getEmptyFile());
  }

  function MenuItem({ action, primary, secondary, Icon }) {
    return (
      <ListItem
        button
        onClick={(event) => {
          event.stopPropagation();
          setMenuOpen(false);
          action();
        }}
      >
        <ListItemIcon>
          <Icon color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={primary}
          secondary={truncate(secondary, TRUNCATE_CHARS)}
          classes={{
            primary: classes.primary,
            secondary: classes.secondary,
          }}
        />
      </ListItem>
    );
  }

  const signedElectronically = !file.fileId && doc.signed;
  const showAfterUpload = (
    <div className={classes.uploadedContainer}>
      <SignedText text="Uploaded" />
      <IconButton
        ref={popperAnchorRef}
        aria-describedby={popperId}
        onClick={(event) => {
          event.stopPropagation();
          setMenuOpen(!menuOpen);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        id={popperId}
        open={menuOpen}
        anchorEl={popperAnchorEl}
        placement="bottom-end"
      >
        <Paper onClick={(event) => event.stopPropagation()} elevation={3}>
          <List dense>
            <MenuItem
              action={handleOpenOriginal}
              primary="Open unsigned copy"
              Icon={OpenIcon}
            />
            <MenuItem
              action={handleOpenSignedCopy}
              primary="Open signed copy"
              secondary={file.fileName}
              Icon={OpenIcon}
            />
            <MenuItem
              action={handleDelete}
              primary="Remove signed copy"
              secondary={file.fileName}
              Icon={DeleteIcon}
            />
          </List>
        </Paper>
      </Popper>
    </div>
  );

  return (
    <div className={classes.row}>
      <Typography className={classes.docName}>{doc.name}</Typography>
      <span className={classes.printSpacer} />
      {signedElectronically || file.fileId ? null : (
        <Button
          variant="text"
          data-test="sign"
          onClick={() => {
            handleOpenOriginal(doc);
          }}
          loading={isDownloading}
        >
          Download
        </Button>
      )}

      {!loading && signedElectronically ? (
        <SignedText text="Signed electronically" />
      ) : (
        <div className={file.fileId ? undefined : classes.rowMargin}>
          <LPFileUpload
            file={file}
            className={classes.uploadButton}
            onChange={handleFileChange}
            text={buttonText}
            variant="contained"
            loading={loading}
            showAfterUpload={showAfterUpload}
            uploadSingleFile={uploadSingleFile}
            accept={doc.isSubdoc ? 'application/pdf' : undefined}
          />
        </div>
      )}
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
    </div>
  );
}
