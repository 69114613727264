import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Alert,
  Typography,
  UIKitSettingsProvider,
  Link,
} from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';

import { KeyValuePair } from 'components/key_value';
import { TypographyWithLineBreaks } from 'components/typography_with_line_breaks';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { useDiscardConfirmation } from 'services/discard';
import { SUBMIT_STAGE } from 'pages/review_v2/undo_requested_changes_dialog/constants';
import { SIGNED_STATUSES } from './constants';

const DRAFT_COMMENT_STAGE = 0;
const CONFIRM_COMMENT_STAGE = 1;

const useStyles = makeStyles((theme) => ({
  errorText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  messageDisplay: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.neutral.fill,
  },
}));

function genHeaderLabel(sendEmail, numNotes, numDocs) {
  if (!numNotes) {
    return `Send ${numDocs} document${
      numDocs > 1 ? 's' : ''
    } for investor signature`;
  }
  if (!sendEmail) {
    return `Submit ${numNotes} comment${numNotes > 1 ? 's' : ''}`;
  }

  return `Send ${numNotes} comment${numNotes > 1 ? 's' : ''} to investor`;
}

function genPrimaryButtonText(currentStage, sendEmail) {
  if (currentStage === DRAFT_COMMENT_STAGE) {
    return 'Next';
  }

  if (sendEmail) {
    return 'Send';
  }

  return 'Submit';
}

function genToastText(isInCommentMode, sendEmail, lpName, numNotes, numDocs) {
  if (!numNotes) {
    return `Sent ${numDocs} document${numDocs > 1 ? 's' : ''} for signature`;
  }

  if (isInCommentMode) {
    if (sendEmail) {
      return `Comments sent to ${lpName}`;
    }

    return `Comments submitted for ${lpName}`;
  }

  if (sendEmail) {
    return `Changes requested and notified ${lpName}`;
  }

  return `Changes requested for ${lpName}`;
}

function DraftStageContent({
  sendEmail,
  setSendEmail,
  message,
  setMessage,
  numCollaborators,
}) {
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        className={classes.bottomSpacing}
        control={
          <Checkbox
            checked={sendEmail}
            onChange={(e) => {
              const checkedValue = e.target.checked;
              if (!checkedValue) {
                setMessage('');
              }

              setSendEmail(checkedValue);
            }}
            name="send-email"
          />
        }
        label={`Notify ${numCollaborators} collaborator${
          numCollaborators > 1 ? 's' : ''
        } by email`}
      />

      <Collapse in={sendEmail}>
        <KeyValuePair input icon={<MessageIcon />} disableGutters dense>
          <TextField
            id="message-to-investor"
            label="Include a message (optional)"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            variant="outlined"
            multiline
            minRows="4"
            fullWidth
            helperText="This message will appear in the email to the investor."
          />
        </KeyValuePair>
      </Collapse>
    </>
  );
}

function SubmitStageContent({
  numCollaborators,
  message,
  sendToInvestor,
  changeClosingStatus,
  docsToSend,
  newStatus,
  fundId,
  closingId,
}) {
  const classes = useStyles();
  return (
    <>
      <KeyValuePair icon={<EmailIcon />}>
        {sendToInvestor
          ? `${numCollaborators} collaborator${
              numCollaborators > 1 ? 's' : ''
            } will be
          notified by email.`
          : 'No collaborators will be notified.'}
      </KeyValuePair>

      {sendToInvestor ? (
        <KeyValuePair icon={<MessageIcon />}>
          {message ? (
            <Paper className={classes.messageDisplay} elevation={0}>
              <TypographyWithLineBreaks variant="body" size="small">
                {message}
              </TypographyWithLineBreaks>
            </Paper>
          ) : (
            'No message'
          )}
        </KeyValuePair>
      ) : null}

      {docsToSend?.length > 0 ? (
        <KeyValuePair icon={<DescriptionOutlinedIcon />}>
          <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
            <List dense disablePadding>
              {docsToSend.map((doc) => (
                <ListItem disableGutters>
                  <Link
                    key={doc.id}
                    variant="external"
                    href={api.lpDocFinalPdfUri({
                      fundId,
                      closingId,
                      lpDocId: doc.id,
                    })}
                  >
                    {doc.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </UIKitSettingsProvider>
        </KeyValuePair>
      ) : null}

      <KeyValuePair icon={<InfoIcon />}>
        {changeClosingStatus ? (
          <span>
            The investor's status will change to <b>{newStatus}</b>
          </span>
        ) : (
          "No change to investor's status"
        )}
      </KeyValuePair>
    </>
  );
}

export function RequestChangesModal({
  open,
  lpClosingStatus,
  isInCommentMode,
  handleChange,
  handleClose,
  numNotes,
  lpName,
  lpUsers,
  docs,
  numSignatureRequired,
}) {
  const { fundId, closingId, lpClosingId } = useParams();
  const { toast } = useToast();
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentStage, setCurrentStage] = useState(DRAFT_COMMENT_STAGE);
  const [sendEmail, setSendEmail] = useState(true);
  const classes = useStyles();

  const numCollaborators = lpUsers?.length || 0;
  const hasData =
    message || !sendEmail || currentStage === CONFIRM_COMMENT_STAGE;
  const changeClosingStatus = SIGNED_STATUSES.includes(lpClosingStatus);

  const newStatus = !numNotes
    ? 'Sent for investor signature'
    : 'Requested changes';

  const docsToSend =
    docs?.filter((doc) => doc.requireSignature || doc.isSubdoc) || [];

  const handleCloseConfirmation = useDiscardConfirmation({
    onConfirm: handleClose,
    needConfirmation: hasData,
  });

  function handleReset() {
    setMessage('');
    setMessageError('');
    setCurrentStage(DRAFT_COMMENT_STAGE);
    setSendEmail(true);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    const props = {
      fundId,
      closingId,
      lpClosingId,
      message,
      sendEmail,
    };

    api
      .rejectLpClosing(props)
      .then(() => {
        toast(
          genToastText(
            isInCommentMode,
            sendEmail,
            lpName,
            numNotes,
            docsToSend.length,
          ),
        );
        handleChange();
        handleClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setMessageError(error.response.data.message);
          setIsLoading(false);
        } else {
          // if the lp commenting feature flag is disabled but a GP hasn't refreshed their
          // UI, they'll get a 404 if they try to request changes on an lp closing that is not
          // in a signed status
          setMessageError(
            'Something went wrong. Please refresh and try again.',
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseConfirmation}
      headerLabel={genHeaderLabel(sendEmail, numNotes, numSignatureRequired)}
      showCancelButton
      primaryButtonLoading={isLoading}
      onExited={handleReset}
      onSubmit={(e) => {
        if (currentStage === DRAFT_COMMENT_STAGE) {
          setCurrentStage(CONFIRM_COMMENT_STAGE);
        } else {
          handleSubmit(e);
        }
      }}
      primaryButtonText={genPrimaryButtonText(currentStage, sendEmail)}
      primaryButtonProps={{
        'data-test':
          currentStage === DRAFT_COMMENT_STAGE
            ? 'review_requested_changes'
            : 'send_requested_changes',
      }}
      onBack={
        currentStage === CONFIRM_COMMENT_STAGE
          ? () => setCurrentStage(DRAFT_COMMENT_STAGE)
          : null
      }
      primaryButtonEndIcon={
        currentStage === DRAFT_COMMENT_STAGE ? <ArrowForwardIcon /> : null
      }
    >
      {/** this div avoids an issue whereby the modal's uniform gap between elements
       * causes hidden Collapse components to still have gaps above and below them.  * */}
      <div>
        {numNotes > 0 ? (
          <div className={classes.bottomSpacing}>
            <Alert severity="info">
              Comments cannot be removed once they are{' '}
              {!sendEmail ? 'submitted' : 'sent'}.
            </Alert>
          </div>
        ) : null}

        {currentStage === DRAFT_COMMENT_STAGE ? (
          <DraftStageContent
            sendEmail={sendEmail}
            setSendEmail={setSendEmail}
            message={message}
            setMessage={setMessage}
            numCollaborators={numCollaborators}
          />
        ) : null}

        {currentStage === SUBMIT_STAGE ? (
          <SubmitStageContent
            numCollaborators={numCollaborators}
            message={message}
            sendToInvestor={sendEmail}
            changeClosingStatus={changeClosingStatus}
            newStatus={newStatus}
            docsToSend={docsToSend}
            fundId={fundId}
            closingId={closingId}
          />
        ) : null}

        {messageError ? (
          <Typography color="error.main" className={classes.errorText}>
            {messageError}
          </Typography>
        ) : null}
      </div>
    </Modal>
  );
}
