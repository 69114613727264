import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useMe } from 'services/providers/me';
import { DiligenceFileInput } from '../../components/file';
import { SubsectionDisplay } from '../subsection_display';
import { OptOut } from '../opt_out';
import { isDocumentsSectionComplete } from '../../utils';

const useStyles = makeStyles((theme) => ({
  documentSubsectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2),
  },
  documentsContainer: {
    width: '100%',
  },
}));

export function DiligenceDocumentInputs({
  docFields,
  form,
  formErrors,
  onChange,
  fileUploadUrl,
  investorName,
  enableNameChecks,
}) {
  const classes = useStyles();
  const [{ isStaff }] = useMe();

  return (
    <div className={classes.documentsContainer}>
      {docFields.map((document) => {
        const updatedLabel = document.optional
          ? `(Optional) ${document.label}`
          : document.label;

        return (
          <DiligenceFileInput
            investorName={investorName}
            key={document.key}
            fileUploadUrl={fileUploadUrl}
            file={form?.[document.key]}
            fileError={formErrors?.[document.key]}
            onChange={(newFile) => onChange(document.key, newFile)}
            formLabel={updatedLabel}
            helpText={document.helpText}
            isStaff={isStaff}
            enableNameChecks={enableNameChecks}
          />
        );
      })}
    </div>
  );
}

export function DocumentsSubsection({
  lpClosingId,
  fundName,
  form,
  hideOptOut,
  formErrors,
  handleChange,
  docFields,
  reuseDataChecked,
  expanded,
  onClick,
  statusIcon,
}) {
  const classes = useStyles();

  if (docFields.length === 0 || reuseDataChecked) {
    return null;
  }
  const hasAllDocsData = isDocumentsSectionComplete(form, docFields);

  return (
    <SubsectionDisplay
      label="Documents"
      expanded={expanded}
      onClick={onClick}
      statusIcon={statusIcon}
    >
      <div className={classes.documentSubsectionContainer}>
        <DiligenceDocumentInputs
          docFields={docFields}
          form={form}
          formErrors={formErrors}
          onChange={handleChange}
          fileUploadUrl={`/api/lpclosing/${lpClosingId}/file/upload/`}
        />

        {hideOptOut ? null : (
          <OptOut
            nodeName={form.name}
            fundName={fundName}
            optOut={form.optOut}
            handleChange={handleChange}
            shouldDisable={hasAllDocsData}
          />
        )}
      </div>
    </SubsectionDisplay>
  );
}
