/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import axios from 'axios';

import * as api from 'services/api';
import { useCurrency } from 'services/providers/currency';
import { useSupport } from 'components/support';

export const QuestionnaireContext = React.createContext();

const uploadSingleFile = (fileToUpload, url) => {
  const formData = new FormData();
  formData.append('file', fileToUpload);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.put(url, formData, config);
};

const buildSingleFileUploadUrl = (lpDocId) =>
  `/api/lpclosing/${lpDocId}/file/upload/`;

export function useQuestionnaire() {
  return useContext(QuestionnaireContext);
}

export function QuestionnaireProvider({ values, ...props }) {
  return <QuestionnaireContext.Provider value={values} {...props} />;
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function WebAppQuestionnaireProvider({ ...props }) {
  const values = {
    pLogoUrl: window.static.p_logo_png,
    confettiUrl: window.static.confetti_json,
    artificialLoadingSvgUrl: window.static.artificial_loading_svg,
    lpCanComment: true,
    useSupport,
    getPdf: (doc) =>
      Promise.resolve({
        data: {
          url: api.templatePdfServeURL({ lpDocId: doc.id }),
          filledUrl: api.filledPdfServeURL({ lpDocId: doc.id }),
        },
      }),
    uploadSingleFile,
    buildSingleFileUploadUrl,
    lpClosingComplete: (lpClosingId) => api.lpClosingComplete({ lpClosingId }),
    getLpDoc: (lpClosingId, lpDocId) => api.lpDoc({ lpDocId, lpClosingId }),
    signaturePage: (lpClosingId, lpDocId) =>
      api.signaturePage({ lpDocId, lpClosingId }),
    lpClosingRequestReview: (lpClosingId) =>
      api.lpClosingRequestReview({ lpClosingId }),
    lpClosingUploadSignedDoc: (lpDocId, lpClosingId, fileId) =>
      api.uploadOrDropSigned({
        lpDocId,
        lpClosingId,
        fileId,
      }),
    // currently only needed to support the sdk's onFinish callback
    onQuestionnaireFinish: () => {},
    compactQuestionEnabled: false,
    useCurrency,
    getAddressAutocomplete: ({ input, country, location, sessionToken }) =>
      api.getAddressAutocomplete({ input, country, location, sessionToken }),
    getAddressDetails: ({ placeId, sessionToken }) =>
      api.getAddressDetails({ placeId, sessionToken }),
    getGeolocation: () => api.getGeolocation(),
    scrollToTop,
  };

  return <QuestionnaireProvider values={values} {...props} />;
}
