import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Paper from '@material-ui/core/Paper';
import { Alert } from '@passthrough/uikit';
import { useFundEdit, useMembers } from 'services/providers/fund';
import { useClosing } from 'services/providers/closing';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { useCurrency } from 'services/providers/currency';
import { useFeatureFlags } from 'services/providers/feature_flag';

import { EmptyState } from 'components/empty_v2';
import { Spinner } from 'components/spinner';
import { PageContainer } from 'components/page_container';
import { useTasks } from 'services/providers/tasks';
import { EditClosingDialog } from './modals/edit';
import { CountersignAlert } from './countersignature_alert';

import { LpDocumentList } from './lp_document_list';
import { AddInvestorDialog } from './add_investor_dialog';
import { OverviewPageHeader } from './overview_page_header';
import { FunnelSummary } from './funnel_summary/index';

const useStyles = makeStyles((theme) => ({
  container: {
    gap: theme.spacing(3),
  },
  emptyStateContainer: {
    marginTop: theme.spacing(2),
  },
  spinner: {
    marginTop: '100px',
  },
}));

export function FundClosingOverviewPage() {
  const { fundId, closingId } = useParams();
  const [tasks] = useTasks();
  const history = useHistory();
  const [lpClosings, setLpClosings] = useState(null);
  const [investorTagGroups, setInvestorTagGroups] = useState(null);
  const [subdoc, setSubdoc] = useState({
    numberOfCountersigners: 0,
    url: null,
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [investorClosingDialogOpen, setInvestorClosingDialogOpen] =
    useState(false);
  const [change, setChange] = useState(0);
  // used to distinguish between manual refresh and update from edit dialog
  // or number of countersign tasks changing
  const [isManualRefresh, setIsManualRefresh] = useState(false);
  const [fundEdit] = useFundEdit();
  const [members, isLoadingMembers, refreshMembers] = useMembers();
  const [closingData, refreshClosing] = useClosing();
  const { closing, countersignTasks } = closingData;

  const currency = useCurrency();
  const { NEW_CLOSING_SETTINGS } = useFeatureFlags();

  let editSettingsButtonText = 'Closing settings';
  if (NEW_CLOSING_SETTINGS) {
    if (fundEdit && !closing?.readOnly) {
      editSettingsButtonText = 'Edit settings';
    } else {
      editSettingsButtonText = 'View settings';
    }
  }

  const classes = useStyles();

  function handleEditSettings() {
    if (NEW_CLOSING_SETTINGS) {
      history.push(urls.closingSettingsUrl({ fundId, closingId }));
    } else {
      setEditDialogOpen(true);
    }
  }

  function onChange() {
    Promise.all([
      // break the above in separate promises
      api.LPClosings({ fundId, closingId }).then((response) => {
        setLpClosings(response.data);
      }),
      api.getFundTagData({ fundId }).then((response) => {
        setInvestorTagGroups(response.data);
      }),
      api
        .subscriptionDoc({ fundId, closingId })
        .then((response) => {
          setSubdoc(response.data);
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            setSubdoc({
              numberOfCountersigners: 0,
              url: null,
            });
          }
        }),
    ]).finally(() => {
      setIsManualRefresh(false);
    });
  }

  useEffect(onChange, [change]);
  useEffect(refreshClosing, [change]);
  useEffect(refreshMembers, [change]);

  const closingTypes = [];
  if (closing?.subdocsEnabled) {
    closingTypes.push('Questionnaire');
  }

  if (closing?.diligenceEnabled) {
    closingTypes.push('Diligence');
  }

  const numCountersignTasks =
    (countersignTasks?.lpDocs?.length || 0) +
    (countersignTasks?.envelopes?.length || 0);
  useEffect(() => {
    const task = tasks?.find(
      (t) => t.type === 'countersign' && t.closingId === closingId,
    );
    const count = task?.count || 0;
    if (count !== numCountersignTasks) {
      setChange((c) => c + 1);
    }
  }, [tasks]);

  const renderDocumentList = () => {
    if (lpClosings && lpClosings.length === 0) {
      return (
        <Paper variant="outlined" className={classes.emptyStateContainer}>
          <EmptyState
            Icon={DescriptionOutlinedIcon}
            title="No investors"
            ctaPermission={fundEdit}
            ctaOnClick={() => {
              setInvestorClosingDialogOpen(true);
            }}
            ctaText="Add investors"
          />
        </Paper>
      );
    }
    return (
      <>
        {numCountersignTasks > 0 ? (
          <CountersignAlert
            numCountersignTasks={numCountersignTasks}
            handleRedirect={() => {
              history.push(urls.countersignUrl({ fundId, closingId }));
            }}
          />
        ) : null}

        {closing?.readOnly ? (
          <Alert severity="warning">
            This closing is read-only and cannot be updated. Please contact
            support@passthrough.com if you need to make edits.
          </Alert>
        ) : null}

        <FunnelSummary closing={closing} currency={currency} />

        {closing === null || lpClosings === null ? (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        ) : (
          <LpDocumentList
            closing={closing}
            lpClosings={lpClosings}
            investorTagGroups={investorTagGroups}
            hasSigning={closing?.hasSigning}
            hasSubdoc={Boolean(closing?.subscriptionDocumentName)}
            members={members}
            subdoc={subdoc}
            onChange={() => {
              setChange((c) => c + 1);
            }}
            handleAddInvestor={() => {
              setInvestorClosingDialogOpen(true);
              setChange((c) => c + 1);
            }}
            currency={currency}
          />
        )}
      </>
    );
  };

  return (
    <>
      <AddInvestorDialog
        externalSignupLink={closing?.externalSignupLink}
        open={investorClosingDialogOpen}
        enableCommitmentPrefill={closing?.enableCommitmentPrefill}
        subdocIsArchived={closing?.subscriptionDocumentIsArchived}
        handleClose={() => {
          setInvestorClosingDialogOpen(false);
        }}
        onChange={() => {
          setChange((c) => c + 1);
        }}
      />

      {NEW_CLOSING_SETTINGS ? null : (
        <EditClosingDialog
          open={editDialogOpen}
          managedDiligenceEnabled={closing?.managedDiligenceEnabled}
          canDeleteClosing={lpClosings !== null && lpClosings.length === 0}
          initialName={closing?.name}
          initialClosingDate={closing?.closingDate}
          initialDownloadable={closing?.downloadable}
          initialDisableOfflineSigning={closing?.disableOfflineSigning}
          isDiligenceEnabled={closing?.diligenceEnabled}
          diligenceJurisdiction={closing?.diligenceJurisdiction}
          initialDefaultCountersignerId={closing?.defaultCountersignerId}
          initialDefaultCountersigner2Id={closing?.defaultCountersigner2Id}
          initialDefaultCountersigner3Id={closing?.defaultCountersigner3Id}
          initialDiligenceApproverId={closing?.defaultDiligenceApproverId}
          subdocName={closing?.subscriptionDocumentName || null}
          subdocUrl={closing?.subscriptionDocumentFileUrl || null}
          subdoc={subdoc}
          members={members}
          isLoadingMembers={isLoadingMembers}
          hasExternalSignupLink={Boolean(closing?.externalSignupLink)}
          onChange={() => {
            setChange((c) => c + 1);
          }}
          handleClose={() => {
            setEditDialogOpen(false);
          }}
        />
      )}

      <OverviewPageHeader
        createdAt={closing?.createdAt}
        closingTypes={closingTypes}
        closingDate={closing?.closingDate}
        fundClosingName={closing?.name}
        readOnly={closing?.readOnly}
        isRefreshing={isManualRefresh}
        onRefresh={() => {
          setIsManualRefresh(true);
          setChange((c) => c + 1);
        }}
        onEditSettings={handleEditSettings}
        editSettingsButtonText={editSettingsButtonText}
      />

      <PageContainer maxWidth={false} className={classes.container}>
        {renderDocumentList()}
      </PageContainer>
    </>
  );
}
