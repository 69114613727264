import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import formatDate from 'date-fns/format';

import { DateStartIcon } from './date_start_icon';
import { DateEndIcon } from './date_end_icon';
import { alertVariants } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
  },
  dateText: {
    display: 'flex',
    alignItems: 'center',
  },
  greyText: {
    color: theme.palette.primary.grey,
  },
  calendarIcon: {
    marginRight: theme.spacing(0.5),
    height: '1.5rem',
    width: '1.5rem',
  },
}));

function getDateText(variant) {
  switch (variant) {
    case alertVariants.WARNINGS:
    case alertVariants.SANCTIONS:
      return 'Listed';
    case alertVariants.PEP:
      return 'Started';
    case alertVariants.ADVERSE_MEDIA:
      return 'Published';
    default:
      return 'Listed';
  }
}

export function DatesContainer({
  variant,
  publishDate,
  listingStartDate,
  listingEndDate,
}) {
  const classes = useStyles();
  const startedText = getDateText(variant);

  return (
    <div className={classes.root}>
      {publishDate ? (
        <Typography className={classes.greyText} variant="body2">
          {`${startedText} ${formatDate(new Date(publishDate), 'dd MMM yyyy')}`}
        </Typography>
      ) : null}

      {listingStartDate ? (
        <div className={classes.dateText}>
          <DateStartIcon className={classes.calendarIcon} />

          <Typography className={classes.greyText} variant="body2">
            {`${startedText} on ${formatDate(
              new Date(listingStartDate),
              'dd MMM yyyy',
            )}`}
          </Typography>
        </div>
      ) : null}

      {listingStartDate && listingEndDate ? (
        <div className={classes.dateText}>
          <DateEndIcon className={classes.calendarIcon} />

          <Typography className={classes.greyText} variant="body2">
            {`Removed on ${formatDate(
              new Date(listingEndDate),
              'dd MMM yyyy',
            )}`}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}
