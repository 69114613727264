import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Modal, Button, useConfirm } from '@passthrough/uikit';

import { useToast } from 'services/toast';
import * as api from 'services/api';
import { closingsUrl } from 'services/urls';
import { formatDate } from 'services/utils';
import { Spinner } from 'components/spinner';
import { SharedSettings } from './shared_settings';
import { SubdocQuestionnaireSettings } from './subdoc_questionnaire_settings';
import { DiligenceSettings } from './diligence_settings';

const useStyles = makeStyles((theme) => ({
  deleteClosingIcon: {
    color: theme.palette.error.dark,
  },
}));

const genFormattedDate = (rawDate) => {
  try {
    return formatDate(rawDate);
  } catch (error) {
    return '';
  }
};

const QUESTIONNAIRE_CLOSING_TYPE = 'Questionnaire';
const DILIGENCE_CLOSING_TYPE = 'Diligence';

function getClosingTypes(isDiligenceEnabled, hasSubdoc) {
  if (isDiligenceEnabled && hasSubdoc) {
    return [QUESTIONNAIRE_CLOSING_TYPE, DILIGENCE_CLOSING_TYPE];
  }
  if (isDiligenceEnabled) {
    return [DILIGENCE_CLOSING_TYPE];
  }

  return [QUESTIONNAIRE_CLOSING_TYPE];
}

function hasStateChanged(initialPropVal, currVal, localDefaultVal) {
  return (
    (initialPropVal && initialPropVal !== currVal) ||
    (!initialPropVal && currVal !== localDefaultVal)
  );
}

export function EditClosingDialog({
  managedDiligenceEnabled,
  initialName,
  initialClosingDate,
  initialDownloadable,
  initialDisableOfflineSigning,
  isDiligenceEnabled,
  diligenceJurisdiction,
  initialDefaultCountersignerId,
  initialDefaultCountersigner2Id,
  initialDefaultCountersigner3Id,
  initialDiligenceApproverId,
  subdocName,
  subdocUrl,
  subdoc,
  members,
  isLoadingMembers,
  hasExternalSignupLink,
  open,
  canDeleteClosing,
  onChange,
  handleClose,
}) {
  const { fundId, closingId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const confirm = useConfirm();
  const [name, setName] = useState(initialName || '');
  const [nameError, setNameError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [closingDate, setClosingDate] = useState(initialClosingDate || null);
  const [closingDateError, setClosingDateError] = useState('');

  const numberOfCountersigners = subdoc?.numberOfCountersigners || 0;
  const [countersignerId, setCountersignerId] = useState(
    initialDefaultCountersignerId || '',
  );
  const [countersignerIdError, setCountersignerIdError] = useState('');
  const [countersigner2Id, setCountersigner2Id] = useState(
    initialDefaultCountersigner2Id || '',
  );
  const [countersigner2IdError, setCountersigner2IdError] = useState('');

  const [countersigner3Id, setCountersigner3Id] = useState(
    initialDefaultCountersigner3Id || '',
  );
  const [countersigner3IdError, setCountersigner3IdError] = useState('');

  const [diligenceApproverId, setDiligenceApproverId] = useState(
    initialDiligenceApproverId || '',
  );
  const [diligenceApproverIdError, setDiligenceApproverIdError] = useState('');

  const [downloadable, setDownloadable] = useState(
    initialDownloadable || false,
  );
  const [disableOfflineSigning, setDisableOfflineSigning] = useState(
    initialDisableOfflineSigning || false,
  );
  const [disableOfflineSigningError, setDisableOfflineSigningError] =
    useState('');
  const [downloadableError, setDownloadableError] = useState('');
  const [omitClosingDate, setOmitClosingDate] = useState(!initialClosingDate);

  const closingDateToPass = omitClosingDate ? null : closingDate;
  const missingClosingDate = !omitClosingDate && !closingDate;

  const { toast } = useToast();

  const hasSubdocQuestionnaire = Boolean(subdocName && subdocUrl);
  const hasUnsavedChanges =
    hasStateChanged(initialName, name, '') ||
    hasStateChanged(initialClosingDate, closingDateToPass, null) ||
    hasStateChanged(initialDownloadable, downloadable, false) ||
    hasStateChanged(
      initialDisableOfflineSigning,
      disableOfflineSigning,
      false,
    ) ||
    hasStateChanged(initialDefaultCountersignerId, countersignerId, '') ||
    hasStateChanged(initialDefaultCountersigner2Id, countersigner2Id, '') ||
    hasStateChanged(initialDefaultCountersigner3Id, countersigner3Id, '') ||
    hasStateChanged(initialDiligenceApproverId, diligenceApproverId, '') ||
    (!omitClosingDate && !initialClosingDate);

  const possibleDiligenceReviewers = managedDiligenceEnabled
    ? members?.filter((member) => member.canReviewManagedDiligence)
    : members;

  function onDelete() {
    setIsDeleting(true);

    api
      .deleteClosing({ fundId, closingId })
      .then(() => {
        history.replace(closingsUrl({ fundId }));
        toast(`Deleted ${name}`);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }

  function handleSubmit() {
    const params = {
      name,
      closingDate: genFormattedDate(closingDateToPass),
      downloadable,
      disableOfflineSigning,
      fundId,
      closingId,
      countersignerId: countersignerId || null,
      countersigner2Id: countersigner2Id || null,
      countersigner3Id: countersigner3Id || null,
      diligenceApproverId: diligenceApproverId || null,
    };

    setIsSubmitting(true);

    api
      .editClosing(params)
      .then(() => {
        onChange();
        handleClose();
        toast(`Updated ${name}.`);

        setNameError('');
        setClosingDateError('');
        setDownloadableError('');
        setDisableOfflineSigningError('');
        setCountersignerIdError('');
        setCountersigner2IdError('');
        setCountersigner3IdError('');
        setDiligenceApproverIdError('');
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setNameError(error.response.data.name);
          setClosingDateError(error.response.data.closingDate);
          setDownloadableError(error.response.data.downloadable);
          setDisableOfflineSigningError(
            error.response.data.disableOfflineSigning,
          );
          setCountersignerIdError(error.response.data.countersignerId);
          setCountersigner2IdError(error.response.data.countersigner2Id);
          setCountersigner3IdError(error.response.data.countersigner3Id);
          setDiligenceApproverIdError(error.response.data.diligenceApproverId);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function handleDiscardUnsavedChanges() {
    confirm({
      title: 'Discard unsaved changes?',
      confirmationText: 'Discard',
      destructive: true,
      size: 'xs',
      description: 'Your changes have not been saved',
    })
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  }

  function handleDeleteClosing() {
    confirm({
      title: 'Delete closing?',
      confirmationText: 'Delete',
      destructive: true,
      size: 'xs',
      description: `${
        hasExternalSignupLink
          ? 'By deleting this closing, investors who use the signup link will encounter an error. '
          : ''
      }This action cannot be undone.`,
    })
      .then(() => {
        onDelete();
      })
      .catch(() => {});
  }

  if (open && isLoadingMembers) {
    return <Spinner fullScreen />;
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        if (hasUnsavedChanges) {
          handleDiscardUnsavedChanges();
        } else {
          handleClose();
        }
      }}
      onEntering={() => {
        setName(initialName || '');
        setClosingDate(initialClosingDate || null);
        setDownloadable(initialDownloadable || false);
        setDisableOfflineSigning(initialDisableOfflineSigning || false);
        setCountersignerId(initialDefaultCountersignerId || '');
        setCountersigner2Id(initialDefaultCountersigner2Id || '');
        setCountersigner3Id(initialDefaultCountersigner3Id || '');
        setDiligenceApproverId(initialDiligenceApproverId || '');
        setOmitClosingDate(!initialClosingDate);
      }}
      headerLabel="Edit closing settings"
      primaryButtonText="Save"
      primaryButtonDisabled={!hasUnsavedChanges || !name || missingClosingDate}
      primaryButtonLoading={isSubmitting}
      showCancelButton
      onSubmit={handleSubmit}
      tertiaryButton={
        canDeleteClosing ? (
          <Button
            variant="text"
            destructive
            onClick={handleDeleteClosing}
            loading={isDeleting}
            startIcon={
              <DeleteOutlineIcon className={classes.deleteClosingIcon} />
            }
          >
            Delete closing
          </Button>
        ) : null
      }
    >
      <SharedSettings
        closingTypes={getClosingTypes(
          isDiligenceEnabled,
          hasSubdocQuestionnaire,
        )}
        name={name}
        setName={setName}
        nameError={nameError}
        closingDate={closingDateToPass}
        setClosingDate={setClosingDate}
        closingDateError={closingDateError}
        omitClosingDate={omitClosingDate}
        setOmitClosingDate={setOmitClosingDate}
      />

      {hasSubdocQuestionnaire ? (
        <SubdocQuestionnaireSettings
          subdoc={subdoc}
          subdocName={subdocName}
          subdocUrl={subdocUrl}
          numberOfCountersigners={numberOfCountersigners}
          members={members}
          countersignerId={countersignerId}
          setCountersignerId={setCountersignerId}
          countersignerIdError={countersignerIdError}
          countersigner2Id={countersigner2Id}
          setCountersigner2Id={setCountersigner2Id}
          countersigner2IdError={countersigner2IdError}
          countersigner3Id={countersigner3Id}
          setCountersigner3Id={setCountersigner3Id}
          countersigner3IdError={countersigner3IdError}
          fundId={fundId}
          downloadable={downloadable}
          downloadableError={downloadableError}
          setDownloadable={setDownloadable}
          disableOfflineSigning={disableOfflineSigning}
          disableOfflineSigningError={disableOfflineSigningError}
          setDisableOfflineSigning={setDisableOfflineSigning}
        />
      ) : null}

      {isDiligenceEnabled ? (
        <DiligenceSettings
          diligenceJurisdiction={diligenceJurisdiction}
          diligenceApproverId={diligenceApproverId}
          setDiligenceApproverId={setDiligenceApproverId}
          diligenceApproverIdError={diligenceApproverIdError}
          members={possibleDiligenceReviewers}
          fundId={fundId}
        />
      ) : null}
    </Modal>
  );
}
